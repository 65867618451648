<template>
    <div class="page-container">
        <div class="page-top">
            <div class="infobox">
                <div class="info-section">
                    <van-image
                        class="pic"
                        fit="cover"
                        :src="imgBaseUrl + pageData.avatar"
                    />
                    <div class="info-wrapper">
                        <div>{{pageData.username}}</div>
                        <div>{{pageData.phone}}</div>
                        <div class="level-box">
                            <img src="@/assets/mine/icon-level.png" alt="">
                            <span>{{pageData.grade}}</span>
                        </div>
                    </div>
                </div>
                <div class="status">
                    {{
                        pageData.isAuth == 1 ? "待审核" :
                        pageData.isAuth == 2 ? "认证失败" :
                        pageData.isAuth == 3 ? "已认证" : ""
                    }}
                </div>
            </div>
            <div class="menu-list">
                <div class="menu-item" @click="jumpPage('follows')">
                    <img src="@/assets/mine/top-fav.png" alt="">
                    <div>我的关注</div>
                </div>
                <div class="menu-item" @click="jumpPage('orders')">
                    <img src="@/assets/mine/top-order.png" alt="">
                    <div>我的订单</div>
                </div>
                <!-- <div class="menu-item" @click="jumpPage('goodsrecord')">
                    <img src="@/assets/mine/top-record.png" alt="">
                    <div>藏品记录</div>
                </div> -->
                <div class="menu-item" @click="jumpPage('airdrop')">
                    <img src="@/assets/mine/top-airdrop.png" alt="">
                    <div>空投</div>
                </div>
            </div>
        </div>
        <div class="page-bottom">
            <div class="menu-line">
                <div class="menu-item" @click="jumpPage('interest')">
                    <img src="@/assets/mine/bottom-qy.png" alt="">
                    <div>我的权益</div>
                </div>
                <div class="menu-item" @click="jumpPage('transfer-manage')">
                    <img src="@/assets/mine/bottom-zy.png" alt="">
                    <div>赠与管理</div>
                </div>
                <div class="menu-item" @click="jumpPage('settings')">
                    <img src="@/assets/mine/bottom-setting.png" alt="">
                    <div>个人设置</div>
                </div>
            </div>
            <div class="menu-line">
                <div class="menu-item">
                    <img src="@/assets/mine/bottom-hc.png" alt="">
                    <div>我的合成</div>
                </div>
                <div class="menu-item" @click="jumpPage('likes')">
                    <img src="@/assets/mine/bottom-fav.png" alt="">
                    <div>我的喜欢</div>
                </div>
                <div class="menu-item" @click="jumpPage('mycollect')">
                    <img src="@/assets/mine/bottom-cp.png" alt="">
                    <div>我的藏品</div>
                </div>
            </div>
            <div class="menu-line">
                <div class="menu-item" @click="showGzh = true">
                    <img src="@/assets/mine/bottom-gzh.png" alt="">
                    <div>公众号</div>
                </div>
                <div class="menu-item" @click="showShare = true">
                    <img src="@/assets/mine/bottom-share.png" alt="">
                    <div>分享</div>
                </div>
                <div class="menu-item" @click="jumpPage('exchange')">
                    <img src="@/assets/mine/bottom-exchange.png" alt="">
                    <div>能量值兑换</div>
                </div>
            </div>
        </div>

        <!-- 分享弹层 -->
        <shareLayer v-if="showShare" from="mine" @closeShare="showShare = false" />
        <!-- 公众号弹层 -->
        <gzhLayer v-if="showGzh" @closeGzh="showGzh = false" />
    </div>
</template>

<script>
import shareLayer from '@/components/shareLayer'
import gzhLayer from '@/components/gzhLayer'

import $http from '@/utils/request'
import config from '@/config'

export default {
    data(){
        return {
            showShare:false,
            showGzh:false,
            pageData:{},
        }
    },
    components:{
        shareLayer,
        gzhLayer
    },
    created(){
        this.getData();
    },
    methods:{
        jumpPage(name){
            this.$router.push({
                name
            });
        },
        getData(){
            $http({
                method:'get',
                url:config.api.mine,
            }).then(res=>{
                this.pageData = res.result;
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.page-container{
    .page-top{
        padding:30px;
        background: #2A2B2C;
        margin-bottom: 20px;
        .infobox{
            padding:30px 30px 0 60px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            .info-section{
                width:100%;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                .pic{
                    width:128px;
                    height:128px;
                    border-radius: 50%;
                    overflow: hidden;
                    margin-right: 28px;
                }
                .info-wrapper{
                    &>div{
                        &:first-child{
                            font-size: 30px;
                            font-family: Source Han Sans CN-Medium, Source Han Sans CN;
                            font-weight: 500;
                            color: #FFFFFF;
                            margin-bottom: 10px;
                        }
                        &:nth-child(2){
                            font-size: 24px;
                            font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                            font-weight: 400;
                            color: #999999;
                            margin-bottom: 10px;
                        }
                        &.level-box{
                            width: 138px;
                            height: 46px;
                            border-radius: 24px 24px 24px 24px;
                            border: 1px solid #707070;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            img{
                                width:32px;
                                margin-right: 10px;
                            }
                            span{
                                font-size: 24px;
                                font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                                font-weight: 400;
                                color: #87C38F;
                            }
                        }
                    }
                }
            }
            .status{
                width: 105px;
                height: 38px;
                line-height: 38px;
                text-align: center;
                background: #87C38F;
                border-radius: 20px 20px 20px 20px;
                font-size: 20px;
                font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                font-weight: 400;
                color: #FFFFFF;
                flex-shrink: 0;
            }
        }
        .menu-list{
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding:56px 77px;
            .menu-item{
                text-align: center;
                img{
                    display: block;
                    width:64px;
                    margin:0 auto;
                    margin-bottom: 10px;
                }
                div{
                    font-size: 24px;
                    font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                    font-weight: 400;
                    color: #999999;
                }
            }
        }
    }
    .page-bottom{
        padding:44px 80px;
        background: #2A2B2C;
        .menu-line{
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 60px;
            &:last-child{
                margin-bottom: 0;
            }
            .menu-item{
                width:130px;
                text-align: center;
                img{
                    display: block;
                    width:90px;
                    margin:0 auto;
                    margin-bottom: 10px;
                }
                div{
                    font-size: 24px;
                    font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                    font-weight: 400;
                    color: #999999;
                }
            }
        }
        

    }
}
</style>
<template>
    <div class="page-container">
        <div class="xy-wrapper" ref="imageWrapper">
            <div class="xy-box">
                <div class="name">{{pageData.name}}</div>
                <div class="auth">艺术家：{{pageData.owner}}</div>
                <div class="card">
                    <div class="imgbox">
                        <img :src="imgBaseUrl + pageData.cover" alt="">
                    </div>
                    <div class="infobox">
                        <div class="qr-box">
                            <div class="left-info">
                                <div class="sub-title">收藏者</div>
                                <div class="sub-content">{{pageData.purchasedName}}</div>
                                <div class="sub-title">藏品编号</div>
                                <div class="sub-content">{{pageData.tokenId}}#{{pageData.number}}</div>
                            </div>
                            <div class="flaunt-qrcode" ref="qrCodeUrl"></div>
                        </div>
                        <div class="sub-title"><span>生成时间</span><span>{{pageData.launchTime}}</span></div>
                    </div>
                </div>
            </div>
        </div>
        <div class="btn-grp">
            <div class="btn-item" @click="onExport">
                <img src="@/assets/mine/btn-save.png" alt="">
                <div>保存到相册</div>
            </div>
            <div class="btn-item" v-clipboard:copy="shareAddress" v-clipboard:success="copySucc" v-clipboard:error="copyFail">
                <img src="@/assets/mine/btn-link.png" alt="">
                <div>复制链接</div>
            </div>
        </div>
    </div>
</template>

<script>
import {getItem} from '@/utils/user'
import html2canvas from 'html2canvas'
import QRCode from 'qrcodejs2'

import $http from '@/utils/request'
import config from '@/config'

export default {
    data(){
        return {
            pageData:{}
        }
    },
    created(){
        // this.pageData = JSON.parse(getItem('currentCollect'));
        this.getData();
    },
    computed:{
        gid(){
            return this.$route.params.id;
        },
        pageType(){
            return this.$route.params.type;
        },
        shareAddress(){
            let code = getItem('myInvitecode');
            if(code){
                return `${window.location.protocol}//${window.location.host}/goodsdetail/${this.pageData.id}/goods?invitecode=${code}`
            }else{
                return `${window.location.protocol}//${window.location.host}/goodsdetail/${this.pageData.id}/goods`
            }
        }
    },
    mounted(){
        this.creatQrCode();
    },
    methods:{
        creatQrCode() {
            var qrcode = new QRCode(this.$refs.qrCodeUrl, {
                text: this.shareAddress, // 需要转换为二维码的内容
                width: 100,
                height: 100,
                colorDark: '#000000',
                colorLight: '#ffffff',
                correctLevel: QRCode.CorrectLevel.H
            })
        },
        getData(){
            if(this.pageType == 'collect'){
                $http({
                    method:'get',
                    url:config.api.collectDetail,
                    params:{
                        txId:this.gid,
                    }
                }).then(res=>{
                    this.pageData = res.result;
                })
            }else if(this.pageType == 'airdrop'){
                $http({
                    method:'get',
                    url:config.api.airdropDetail,
                    params:{
                        airUserId:this.gid,
                    }
                }).then(res=>{
                    this.pageData = res.result;
                })
            }
            
        },
        onExport() {
            const clientWidth = this.$refs.imageWrapper.offsetWidth * 3;
            const clientHeight = this.$refs.imageWrapper.offsetHeight * 3;
            const kh = [clientWidth, clientHeight];

            html2canvas(this.$refs.imageWrapper, { useCORS: true, logging: true }).then((canvas) => {
                const dataURL = canvas.toDataURL('image/png');
                this.download(dataURL, kh);
            });
        },
        getUrlBase64(url, kh) {
            return new Promise((resolve) => {
                let canvas = document.createElement('canvas');
                const ctx = canvas.getContext('2d');
                const img = new Image();
                img.crossOrigin = 'Anonymous'; // 允许跨域
                img.src = url;
                img.onload = () => {
                    // eslint-disable-next-line prefer-destructuring
                    canvas.height = kh[1];
                    // eslint-disable-next-line prefer-destructuring
                    canvas.width = kh[0];
                    ctx.drawImage(img, 0, 0, kh[0], kh[1]);
                    const dataURL = canvas.toDataURL('image/png');
                    canvas = null;
                    resolve(dataURL);
                };
            });
        },
        download(imgUrl, kh) {
            this.getUrlBase64(imgUrl, kh).then((base64) => {
                const link = document.createElement('a');
                link.href = base64;
                link.download = `NFTNET数字藏品.png`;
                link.click();
            });
        },
        copySucc(){
            this.$toast('复制成功');
        },
        copyFail(){
            this.$toast('复制失败');
        },
    }
}
</script>

<style lang="scss">
.flaunt-qrcode{
    flex-shrink: 0;
    padding:10px;
    border:1px solid #0C81F5;
    img{
        width:150px!important;
        height:150px!important;
    }
    canvas{
        width:150px!important;
        height:150px!important;
    }
}
</style>

<style lang="scss" scoped>
.page-container{
    padding:30px;
    .xy-wrapper{
        border-radius: 20px;
        border:1px solid #0C81F5;
        padding:30px 23px;
        margin-bottom: 30px;
        .xy-box{
            background: url('../../assets/mine/xy-bg.png') no-repeat center center;
            width:642px;
            min-height:1251px;
            box-sizing: border-box;
            padding:25px;
            border-radius: 16px;
            overflow: hidden;
            .name{
                font-size: 28px;
                font-family: Source Han Sans CN-Bold, Source Han Sans CN;
                font-weight: bold;
                color: #FFFFFF;
                margin-bottom: 20px;
            }
            .auth{
                font-size: 22px;
                font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                font-weight: 400;
                color: #FFFFFF;
                margin-bottom: 35px;
            }
            .card{
                background: url('../../assets/mine/xy-card-bg.png') no-repeat center center;
                width:590px;
                height:1088px;
                background-size: 100% 100%;
                .imgbox{
                    height:773px;
                    background:url('../../assets/goodsdetail/imgbox-bg.png') no-repeat center center;
                    background-size: 100% 100%;
                    position: relative;
                    &>img{
                        position: absolute;
                        left:50%;
                        transform: translateX(-50%);
                        bottom:220px;
                        max-width: 360px;
                        max-height: 480px;
                    }
                }
                .infobox{
                    padding:0 16px;
                    .sub-title{
                        margin-top: 20px;
                        font-size: 26px;
                        font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                        font-weight: 400;
                        color: #999999;
                        span{
                            margin-right: 20px;
                        }
                    }
                    .sub-content{
                        font-size: 30px;
                        font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                        font-weight: 400;
                        color: #FFFFFF;
                        margin-top: 20px;
                        
                    }
                    .qr-box{
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        .left-info{
                            width:390px;
                            overflow: hidden;
                            .sub-content{
                                width:100%;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                white-space: nowrap;
                            }
                        }
                    }
                }
            }
        }
    }
    .btn-grp{
        display: flex;
        justify-content: center;
        align-items: center;
        .btn-item{
            text-align: center;
            img{
                width:128px;
                margin:0 auto;
                margin-bottom: 30px;
                display: block;
            }
            div{
                font-size: 26px;
                font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                font-weight: 400;
                color: #999999;
            }
            &:first-child{
                margin-right: 190px;
            }
        }
    }
}
</style>
<template>
    <div class="page-container">
        <vue-loadmore 
            :on-loadmore="loadMore"
            :finished="finished"
            load-offset="100">
            <div class="order-list" v-if="couldShowCount > 0">
                <div class="order-item" v-for="item in collectList" :key="item.id" v-show="item.isTranfer == 1">
                    <van-image
                        class="user-pic"
                        fit="cover"
                        :src="imgBaseUrl + item.cover"
                    />
                    <div class="right-block">
                        <div class="name-status">
                            <div class="name">{{item.name}}</div>
                            <div class="status" @click="jumpTrans(item,item.id)">转赠</div>
                        </div>
                        <div class="user-name">{{item.owner}}</div>
                        <div class="price">{{item.price}}元</div>
                    </div>
                </div>
            </div>
            <empty v-else />
        </vue-loadmore>
    </div>
</template>

<script>
import $http from '@/utils/request'
import config from '@/config'
import {setItem} from '@/utils/user'

export default {
    data(){
        return {
            current:1,
            pageSize:10,
            collectList:[],
            finished:false,
            total:0,
        }
    },
    created(){
        this.getData();
    },
    computed:{
        maxPage(){
            return Math.ceil(this.total / this.pageSize);
        },
        couldShowCount(){
            let tmpArr = this.collectList.filter(item => item.isTranfer == 1);
            return tmpArr.length;
        }
    },
    methods:{
        jumpTrans(item,id){
            setItem('currentTrans',JSON.stringify(item));
            this.$router.push({
                name:'transfer-search',
                query:{
                    id:id
                }
            });
        },
        loadMore(done){
            if(this.current < this.maxPage){
                this.current=this.current + 1;
                this.getData();
            }else{
                this.finished = true;
            }
            done();
        },
        getData(){
            $http({
                method:'get',
                url:config.api.myCollect,
                params:{
                    current:this.current,
                    pageSize:this.pageSize
                }
            }).then(res=>{
                this.collectList = this.collectList.concat(res.result.records);
                this.total = res.result.total;
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.page-container{
    padding:30px;
    .order-list{
        .order-item{
            background-color: #2A2B2C;
            border-radius: 16px;
            padding:30px 26px;
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            margin-bottom: 20px;
            .user-pic{
                width:150px;
                height:150px;
                display: block;
                margin-right: 30px;
                flex-shrink: 0;
                border-radius: 8px;
                overflow: hidden;
                flex-shrink: 0;
            }
            .right-block{
                width:calc(100% - 180px);
                display: flex;
                height:150px;
                justify-content: space-between;
                align-items: flex-start;
                flex-direction: column;
                .name-status{
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    width:100%;
                    .name{
                        font-size: 30px;
                        font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                        font-weight: 400;
                        color: #FFFFFF;
                        width:calc(100% - 76px);
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                    }
                    .status{
                        width:76px;
                        flex-shrink: 0;
                        font-size: 24px;
                        font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                        font-weight: bold;
                        color: #0C81F5;
                        text-align: right;
                        &.success{
                            color:#52C41A;
                        }
                        &.fail{
                            color:#FF4D4F;
                        }
                    }
                }
                .user-name{
                    font-size: 24px;
                    font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                    font-weight: 400;
                    color: #666666;
                }
                .price{
                    font-size: 24px;
                    font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                    font-weight: 400;
                    color: #FFFFFF;
                }
            }
        }
    }
}
</style>
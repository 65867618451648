<template>
    <div class="page-container">
        <!-- 搜索栏 -->
        <div class="top-line">
            <div class="search-box">
                <img src="@/assets/icon-search.png" alt="">
                <input type="text" v-model="keyword" @keypress.enter="goSearch" placeholder="搜索" />
            </div>
            <img src="@/assets/icon-user.png" alt="" class="icon-user" @click="jumpPage('mine')">
        </div>
        <!-- lunbo -->
        <div class="banner">
            <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
                <van-swipe-item v-for="item in banners" :key="item.mobileLink">
                    <van-image
                        class="banner-pic"
                        fit="cover"
                        @click="jumpBanner(item.url)"
                        :src="imgBaseUrl + item.mobileLink"
                    />
                </van-swipe-item>
            </van-swipe>
        </div>
        <!-- 发售日历 -->
        <div class="sale-calendar" @click="jumpPage('calendar')">
            <img src="@/assets/img-rili.png" alt="" class="rili">
            <img src="@/assets/img-diqiu.gif" alt="" class="diqiu">
        </div>
        <!-- 热门藏品  盲盒 -->
        <div class="tabs">
            <div class="tab-item" :class="{active:currentTab == 1}" @click="currentTab = 1">热门藏品</div>
            <div class="tab-item" :class="{active:currentTab == 4}" @click="currentTab = 4">高校艺术藏品</div>
            <div class="tab-item" :class="{active:currentTab == 2}" @click="currentTab = 2">盲盒</div>
        </div>
        <!-- 筛选模块 -->
        <div class="filter-box">
            <div class="filter-item" :class="{active:showFilter}" @click="toggleFilter">
                <span>
                    {{
                        range == 0 ? '所有' :
                        range == 1 ? '在售' :
                        range == 2 ? '预售' :
                        range == 3 ? '售罄' : ''
                    }}
                </span>
                <img src="@/assets/icon-sj-down-blue.png" v-if="showFilter" alt="">
                <img src="@/assets/icon-sj-down.png" v-else alt="">
            </div>
            <div class="filter-item" :class="{active:showSort}" @click="toggleSort">
                <span>
                    {{
                        order == 1 ? '默认排序' :
                        order == 2 ? '价格升序' :
                        order == 3 ? '价格降序' :
                        order == 4 ? '喜爱数降序' :
                        order == 5 ? '上架时间降序' : ''
                    }}
                </span>
                <img src="@/assets/icon-sj-down-blue.png" v-if="showSort" alt="">
                <img src="@/assets/icon-sj-down.png" v-else alt="">
            </div>
            <!-- 排序弹层 -->
            <div class="filter-layer" v-if="showSort">
                <div class="filter-layer-item" :class="{active:order == 1}" @click="makeSort(1)">默认排序</div>
                <div class="filter-layer-item" :class="{active:order == 2}" @click="makeSort(2)">价格升序</div>
                <div class="filter-layer-item" :class="{active:order == 3}" @click="makeSort(3)">价格降序</div>
                <div class="filter-layer-item" :class="{active:order == 4}" @click="makeSort(4)">喜爱数降序</div>
                <div class="filter-layer-item" :class="{active:order == 5}" @click="makeSort(5)">上架时间降序</div>
            </div>
            <!-- 筛选弹层 -->
            <div class="filter-layer" v-if="showFilter">
                <div class="filter-layer-item" :class="{active:range == 0}" @click="makeFilter(0)">所有</div>
                <div class="filter-layer-item" :class="{active:range == 1}" @click="makeFilter(1)">在售</div>
                <div class="filter-layer-item" :class="{active:range == 2}" @click="makeFilter(2)">预售</div>
                <div class="filter-layer-item" :class="{active:range == 3}" @click="makeFilter(3)">售罄</div>
            </div>
        </div>
        <!-- 藏品列表 -->
        <vue-loadmore 
            :on-loadmore="loadMore"
            :finished="finished"
            load-offset="100">
            <div class="goods-list" v-if="hotList.length > 0">
                <div class="goods-item" v-for="item in hotList" :key="item.id" @click="jumpPage('goodsdetail',{id:item.id,type:currentTab == 2 ? 'blindbox' : 'goods'})">
                    <div class="img-box">
                        <van-image
                            class="img-box-bg"
                            fit="cover"
                            :src="imgBaseUrl + item.cover"
                        />
                        <div class="img-box-wrapper">
                            <img :src="imgBaseUrl + item.cover" alt=""/>
                        </div>
                    </div>
                    <div class="goods-info">
                        <div class="author-line">
                            <div class="author-info">
                                <van-image
                                    class="auth-pic"
                                    fit="cover"
                                    :src="imgBaseUrl + item.avatar"
                                />
                                <span>{{item.owner}}</span>
                            </div>
                            <div class="status-box">
                                <!-- 状态 1：已上线  2：定时上线 3：售罄  5:预约 -->
                                <template v-if="item.status == 1">
                                    <img src="@/assets/icon-hot.png" alt="">
                                    <span>热销中</span>
                                </template>
                                
                                <div v-if="item.status == 3" class="sale-out">已售罄</div>
                            </div>
                        </div>
                        <div class="goods-name">{{item.name}}</div>
                        <div class="price-line">
                            <div class="price-box">
                                <span>当前售价</span>
                                <span>¥ {{item.price}}</span>
                            </div>
                            <div class="fav-box">
                                <span>{{item.likeNum}}</span>
                                <img src="@/assets/icon-fav-light.png" @click.stop="unstar(item.id,1,item)" alt="" v-if="item.isStared">
                                <img src="@/assets/icon-fav.png" alt="" @click.stop="star(item.id,1,item)" v-else>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <empty v-else />
        </vue-loadmore>
        
    </div>    
</template>

<script>
import $http from '@/utils/request'
import config from '@/config'
import { setItem } from '@/utils/user'

export default {
    data(){
        return {
            banners:[],
            hotList:[],
            currentTab:1,//1 热门藏品 2 盲盒 3 高校艺术藏品
            showSort:false,
            showFilter:false,
            keyword:'',
            order:5,//排序 5：按照上架时间降序，4，喜爱数降序，3，价格降序，2 价格升序，1，默认排序
            range:0,// range是订单状态筛选（0 所有，1，在售，2预收，3 售完)
            pageSize:10,
            current:1,//当前页数
            total:0,//总数
            finished:false,
        }
    },
    created(){
        let code = this.$route.query ? this.$route.query.invitecode : null;
        if(code){
            setItem('shareInvitecode',code);
        }
        this.getBanner();
        this.getHotWork();
    },
    watch:{
        currentTab(newVal){
            this.current = 1;
            if(newVal == 1){
                this.getHotWork('changetab');
            }else if(newVal == 4){
                this.getHotWork('changetab');
            }else{
                this.getBlindBox('changetab');
            }
        }
    },
    computed:{
        maxPage(){
            return Math.ceil(this.total / this.pageSize);
        }
    },
    methods:{
        jumpBanner(link){
            if(link){
                this.$router.push(link);
            }
        },
        //点亮小心心
        star(id,type,item){
            $http({
                method:'get',
                url:config.api.makeStar,
                params:{
                    id,
                    type //type 1 表示藏品的小心心 2 表示藏品组的小心心  3 表示艺术家的关注
                }
            }).then(res=>{
                this.$toast('操作成功');
                item.isStared = true;
                item.likeNum = item.likeNum + 1;
            })
        },
        //去掉小心心
        unstar(id,type,item){
            $http({
                method:'get',
                url:config.api.unFollow,
                params:{
                    id,
                    type
                }
            }).then(res=>{
                this.$toast('操作成功');
                item.isStared = false;
                item.likeNum = item.likeNum - 1;
            })
        },
        goSearch(){
            this.current = 1;
            if(this.currentTab == 1){
                this.getHotWork('search');
            }else{
                this.getBlindBox('search');
            }
        },
        jumpPage(name,params){
            this.$router.push({
                name,
                params
            });
        },
        loadMore(done){
            if(this.current < this.maxPage){
                this.current=this.current + 1;
                if(this.currentTab == 1 || this.currentTab == 4){ //热门藏品
                    this.getHotWork();
                }else{ //盲盒
                    this.getBlindBox();
                }
            }else{
                this.finished = true;
            }
            
            
            done();
        },
        makeSort(val){
            this.order = val;
            this.current = 1;
            this.hotList=[];
            if(this.currentTab == 1 || this.currentTab == 4){ //热门藏品
                this.getHotWork();
            }else{
                this.getBlindBox();
            }
            this.showSort = false;
        },
        makeFilter(val){
            this.range = val;
            this.current = 1;
            this.hotList=[];
            if(this.currentTab == 1 || this.currentTab == 4){ //热门藏品
                this.getHotWork();
            }else{
                this.getBlindBox();
            }
            this.showFilter = false;
        },
        toggleFilter(){
            this.showFilter = !this.showFilter;
            this.showSort = false;
        },
        toggleSort(){
            this.showSort = !this.showSort;
            this.showFilter = false;
        },
        getBanner(){
            $http({
                method:'get',
                url:config.api.getBanner
            }).then(res=>{
                this.banners = res.result;
            })
        },
        getHotWork(type){
            let data={
                keyword:this.keyword,
                order:this.order,
                range:this.range,
                nftType:this.currentTab,
                current:this.current,
                pageSize:this.pageSize
            };

            $http({
                method:'get',
                url:config.api.hotWork,
                params:data
            }).then(res=>{
                if(type == 'search' || type == 'changetab'){
                    this.hotList = res.result.records;
                }else{
                    this.hotList = this.hotList.concat(res.result.records);
                }
                this.total = res.result.total;
            })
        },
        getBlindBox(type){
            let data={
                keyword:this.keyword,
                order:this.order,
                range:this.range,
                current:this.current,
                pageSize:this.pageSize
            };

            $http({
                method:'get',
                url:config.api.blindBox,
                params:data
            }).then(res=>{
                if(type == 'search' || type == 'changetab'){
                    this.hotList = res.result.records;
                }else{
                    this.hotList = this.hotList.concat(res.result.records);
                }
                this.total = res.result.total;
            })
        },
    }
}
</script>

<style lang="scss" scoped>
// 下拉刷新的文字隐藏
::v-deep .vuejs-refresh-head{
    display: none;
}
.page-container{
    padding:30px;
    .top-line{
        display:flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 35px;
        .search-box{
            width:100%;
            box-sizing: border-box;
            padding:10px 30px;
            background: #323232;
            border-radius: 34px 34px 34px 34px;
            border: 1px solid #FFFFFF;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            img{
                width:48px;
                flex-shrink: 0;
                margin-right: 5px;
            }
            input{
                width:100%;
            }
        }
        .icon-user{
            width:68px;
            flex-shrink: 0;
            margin-left: 22px;
        }
    }
    .banner{
        overflow: hidden;
        height:428px;
        margin-bottom: 30px;
        .my-swipe{
            overflow: visible!important;
            .van-swipe-item {
                height:388px;
                .banner-pic{
                    width:100%;
                    height:100%;
                    border-radius: 16px;
                    overflow: hidden;
                }
            }
            ::v-deep .van-swipe__indicators{
                bottom:-40px;
                .van-swipe__indicator{
                    height:20px;
                    width:20px;
                    background: #d9d9d9;
                }
                .van-swipe__indicator--active{
                    width: 50px;
                    height: 20px;
                    background: linear-gradient(270deg, #40A9FF 0%, #0C81F5 100%);
                    border-radius: 10px 10px 10px 10px;
                }
                .van-swipe__indicator:not(:last-child){
                    margin-right: 20px;
                }
            }
        } 
        
    }
    .sale-calendar{
        height:256px;
        background-color: #080607;
        border-radius: 16px;
        display: flex;
        justify-content: center;
        align-items: center;
        .rili{
            width:248px;
            margin-right: 77px;
        }
        .diqiu{
            width:200px;
        }
    }
    .tabs{
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        margin-top: 30px;
        .tab-item{
            position: relative;
            z-index: 1;
            width: 175px;
            height: 60px;
            line-height: 60px;
            text-align: center;
            border: 1px solid #666666;
            font-size: 24px;
            font-family: Source Han Sans CN-Bold, Source Han Sans CN;
            font-weight: bold;
            color: #666666;
            &:last-child{
                left:-1px;
            }
            &.active{
                border: 1px solid #0C81F5;
                background: rgba(64,169,255,0.2000);              
                color: #1296DB;
                z-index: 2;
            }
        }
    }
    .filter-box{
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin-top: 30px;
        padding-bottom: 30px;
        position: relative;
        .filter-item{
            display: flex;
            justify-content: flex-end;
            align-items: center;
            margin-left: 40px;
            height:32px;
            &.active{
                span{
                    color:#0C81F5;
                }
            }
            img{
                width:32px;
                margin-left: 10px;
            }
            span{
                font-size: 24px;
                font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                font-weight: 400;
                color: #666666;
            }
        }
        .filter-layer{
            position: absolute;
            z-index: 3;
            width:100%;
            top:62px;
            background-color: #272727;
            left:0;
            .filter-layer-item{
                height:70px;
                line-height: 70px;
                border-bottom: 1px solid #707070;
                padding:0 66px;
                font-size: 24px;
                font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                font-weight: 400;
                color: #FFFFFF;
                &.active{
                    color:#1296DB;
                }
                &:last-child{
                    border-bottom: none;
                }
            }
        }
    }
    .goods-list{
        .goods-item{
            background-color: #080607;
            border-radius: 16px;
            overflow: hidden;
            margin-bottom: 30px;
            .img-box{
                position: relative;
                height:542px;
                border-radius: 16px;
                overflow: hidden;
                .img-box-bg{
                    position: absolute;
                    left:0;
                    right:0;
                    top:0;
                    bottom:0;
                    // background: url('../assets/tmp-cover.png');
                    // background-size: cover;
                    opacity: 0.5;
                    z-index: 1;
                }
                .img-box-wrapper{
                    width:100%;
                    height:542px;
                    padding:26px;
                    box-sizing: border-box;
                    position: absolute;
                    top:0;
                    left:0;
                    z-index: 2;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    img{
                        display: block;
                        max-height: 100%;
                    }
                }
            }
            .goods-info{
                padding:30px;
                padding-bottom: 0;
                .author-line{
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    margin-bottom: 28px;
                    .author-info{
                        display: flex;
                        justify-content: start;
                        align-items: center;
                        .auth-pic{
                            margin-right: 30px;
                            width:64px;
                            height:64px;
                            border-radius: 50%;
                            overflow: hidden;
                        }
                        span{
                            font-size: 22px;
                            font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                            font-weight: 400;
                            color: #999999;
                        }
                    }
                    .status-box{
                        display: flex;
                        justify-content: end;
                        align-items: center;
                        img{
                            margin-right: 20px;
                            width:28px;
                        }
                        span{
                            font-size: 22px;
                            font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                            font-weight: 400;
                            color: #999999;
                        }
                    }
                    .sale-out{
                        width: 92px;
                        height: 40px;
                        line-height: 40px;
                        text-align: center;
                        background: #999999;
                        font-size: 22px;
                        font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                        font-weight: 400;
                        color: #FFFFFF;
                    }
                }
                .goods-name{
                    font-size: 30px;
                    font-family: Source Han Sans CN-Medium, Source Han Sans CN;
                    font-weight: 500;
                    color: #FFFFFF;
                    padding-bottom: 24px;
                    border-bottom: 1px solid #707070;
                }
                .price-line{
                    padding:18px 0;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    .price-box{
                        display: flex;
                        justify-content: start;
                        align-items: center;
                        span{
                            &:first-child{
                                font-size: 22px;
                                font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                                font-weight: 400;
                                color: #999999;
                                margin-right: 20px;
                            }
                            &:last-child{
                                font-size: 30px;
                                font-family: Source Han Sans CN-Medium, Source Han Sans CN;
                                font-weight: 500;
                                color: #FFFFFF;
                            }
                        }
                    }
                    .fav-box{
                        display: flex;
                        justify-content: end;
                        align-items: center;
                        span{
                            margin-right: 10px;
                            font-size: 22px;
                            font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                            font-weight: 400;
                            color: #999999;
                        }
                        img{
                            width:48px;
                        }
                    }
                }
            }
        }
    }
}
</style>
<template>
    <div class="page-container">
        <vue-loadmore 
            :on-loadmore="loadMore"
            :finished="finished"
            load-offset="100">
            <div class="goods-list" v-if="collectList.length > 0">
                <div class="goods-item" v-for="item in collectList" :key="item.id">
                    <div class="item-top">
                        <div class="info-box">
                            <van-image
                                class="goods-pic"
                                fit="cover"
                                :src="imgBaseUrl + item.cover"
                            />
                            <div class="name-code">
                                <div>{{item.name}}</div>
                                <div>{{item.tokenId}}#{{item.number}}</div>
                            </div>
                        </div>
                        <div class="status">交易成功</div>
                    </div>
                    <div class="item-bottom">
                        <div class="bottom-item">
                            <div>交易金额</div>
                            <div>{{item.price}}元</div>
                        </div>
                        <div class="bottom-item">
                            <div>交易时间</div>
                            <div>{{item.launchTime}}</div>
                        </div>
                        <div class="bottom-item">
                            <div>记录单号</div>
                            <div>{{item.id}}</div>
                        </div>
                    </div>
                </div>
            </div>
            <empty v-else />
        </vue-loadmore>
        
        <!-- <div class="no-more">没有更多了</div> -->
    </div>
</template>

<script>
import $http from '@/utils/request'
import config from '@/config'


export default {
    data(){
        return {
            current:1,
            pageSize:10,
            collectList:[],
            finished:false,
            total:0,
        }
    },
    created(){
        this.getData();
    },
    computed:{
        maxPage(){
            return Math.ceil(this.total / this.pageSize);
        }
    },
    methods:{
        loadMore(done){
            if(this.current < this.maxPage){
                this.current=this.current + 1;
                this.getData();
            }else{
                this.finished = true;
            }
            done();
        },
        getData(){
            $http({
                method:'get',
                url:config.api.myCollect,
                params:{
                    current:this.current,
                    pageSize:this.pageSize
                }
            }).then(res=>{
                this.collectList = this.collectList.concat(res.result.records);
                this.total = res.result.total;
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.page-container{
    padding:30px;
    .goods-list{
        .goods-item{
            padding:24px 20px;
            border-radius: 16px;
            background-color: #2A2B2C;
            margin-bottom: 20px;
            .item-top{
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding-bottom: 30px;
                border-bottom: 1px solid #707070;
                .info-box{
                    width:100%;
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    .goods-pic{
                        width:100px;
                        height:100px;
                        display: block;
                        margin-right: 30px;
                        flex-shrink: 0;
                        border-radius: 8px;
                    }
                    .name-code{
                        width:100%;
                        &>div{
                            &:first-child{
                                font-size: 30px;
                                font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                                font-weight: 400;
                                color: #FFFFFF;
                                margin-bottom: 30px;
                            }
                            &:last-child{
                                font-size: 26px;
                                font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                                font-weight: 400;
                                color: #999999;
                            }
                        }
                    }
                }
                .status{
                    flex-shrink: 0;
                    max-width: 130px;
                    font-size: 26px;
                    font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                    font-weight: 400;
                    color: #52C41A;
                }
            }
            .item-bottom{
                padding-top: 20px;
                .bottom-item{
                    margin-bottom: 30px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    &:last-child{
                        margin-bottom: 0;
                    }
                    &>div{
                        font-size: 24px;
                        font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                        font-weight: 400;
                        color: #FFFFFF;
                    }
                }
            }
        }
    }
    .no-more{
        margin-top: 30px;
        font-size: 24px;
        font-family: Source Han Sans CN-Regular, Source Han Sans CN;
        font-weight: 400;
        color: #666666;
        text-align: center;
    }
}
</style>
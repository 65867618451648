<template>
    <div class="page-container">
        <div class="page-top">
            <div class="info-box">
                <van-image
                    class="user-pic"
                    fit="cover"
                    :src="imgBaseUrl + pageData.avatar"
                />
                <div class="username">{{pageData.userName}}</div>
            </div>
        </div>
        <div class="level-line">
            <div class="level-box">
                <img src="@/assets/mine/icon-level.png" alt="">
                <span>{{pageData.grade}}能量值</span>
            </div>
        </div>
        <!-- <div class="tabs">
            <div class="tab-item active">全部</div>
            <div class="tab-item">收入</div>
            <div class="tab-item">支出</div>
        </div> -->
        <vue-loadmore 
            :on-loadmore="loadMore"
            :finished="finished"
            load-offset="100">
            <div class="content-list" v-if="collectList.length > 0">
                <div class="content-item" v-for="item in collectList" :key="item.id">
                    <div class="info-box">
                        <div>
                            {{item.fromUserName}} {{item.workName}}
                        </div>
                        <div>{{item.createTime | formatDate}}</div>
                    </div>
                    <div class="price out">-{{item.grade}}</div>
                    <!-- <div class="price">+1</div> -->
                </div>
            </div>
            <empty v-else />
        </vue-loadmore>
    </div>
</template>

<script>
import $http from '@/utils/request'
import config from '@/config'
import dayjs from 'dayjs'

export default {
    data(){
        return {
            current:1,
            pageSize:10,
            total:0,
            collectList:[],
            pageData:{},
            finished:false,
        }
    },
    created(){
        this.getData();
        this.getRecord();
    },
    computed:{
        maxPage(){
            return Math.ceil(this.total / this.pageSize);
        }
    },
    filters:{
        formatDate(val){
            if(!val){
                return ''
            }else{
                return dayjs(val).format("YYYY-MM-DD HH:mm:ss");
            }
        }
    },
    methods:{
        getData(){
            $http({
                method:'get',
                url:config.api.getGrade
            }).then(res=>{
                this.pageData = res.result;
            })
        },
        getRecord(){
            $http({
                method:'get',
                url:config.api.exchangeRecord,
                params:{
                    current:this.current,
                    pageSize:this.pageSize
                }
            }).then(res=>{
                this.collectList = this.collectList.concat(res.result.records);
                this.total = res.result.total;
            })
        },
        loadMore(done){
            if(this.current < this.maxPage){
                this.current=this.current + 1;
                this.getRecord();
            }else{
                this.finished = true;
            }
            done();
        },
    }
}
</script>

<style lang="scss" scoped>
.page-container{
    padding:30px;
    .page-top{
        padding-bottom: 40px;
        border-bottom: 1px solid #707070;
        margin-bottom: 48px;
        .info-box{
            padding-left: 30px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            .user-pic{
                width:64px;
                height:64px;
                display: block;
                margin-right: 20px;
                flex-shrink: 0;
                border-radius: 50%;
                overflow: hidden;
                flex-shrink: 0;
            }
            .username{
                font-size: 30px;
                font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                font-weight: 400;
                color: #FFFFFF;
            }
        }
    }
    .level-line{
        display: flex;
        justify-content: flex-start;
        margin-bottom: 50px;
        .level-box{
            padding:0 22px;
            height: 46px;
            border-radius: 24px 24px 24px 24px;
            border: 1px solid #707070;
            display: flex;
            justify-content: center;
            align-items: center;
            img{
                width:32px;
                margin-right: 10px;
            }
            span{
                font-size: 24px;
                font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                font-weight: 400;
                color: #87C38F;
            }
        }
    }
    .tabs{
        display: flex;
        justify-content: space-around;
        align-items: center;
        .tab-item{
            font-size: 36px;
            font-family: Source Han Sans CN-Regular, Source Han Sans CN;
            font-weight: 400;
            color: #666666;
            position: relative;
            &.active{
                color:#fff;
                &::after{
                    content:'';
                    display: block;
                    width:80px;
                    height:4px;
                    border-radius: 4px;
                    background: #fff;
                    position: absolute;
                    left:50%;
                    transform: translateX(-50%);
                    bottom:-10px;
                }
            }
        }
    }
    .content-list{
        margin-top: 40px;
        .content-item{
            padding:20px 0;
            border-bottom: 1px solid #707070;
            display: flex;
            justify-content: space-between;
            align-items: center;
            &:last-child{
                border-bottom: none;
            }
            .info-box{
                width:100%;
                div{
                    &:first-child{
                        font-size: 24px;
                        font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                        font-weight: 400;
                        color: #FFFFFF;
                        margin-bottom: 8px;
                    }
                    &:last-child{
                        font-size: 20px;
                        font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                        font-weight: 400;
                        color: #999999;
                    }
                }
            }
            .price{
                width:100px;
                flex-shrink: 0;
                font-size: 30px;
                font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                font-weight: 400;
                color: #FF4D4F;
                text-align: right;
                &.out{
                    color:#52C41A;
                }
            }
        }
    }
}
</style>
import axios from 'axios'
import { Toast } from 'vant';
import { getItem } from '@/utils/user';
import router from '@/router'

//在axios中默认是不让后端写入cookie的，所以你要设置axios.defaults.withCredentials = true
axios.defaults.withCredentials = true;

const $http = axios.create({
    baseURL:process.env.VUE_APP_BASE_API,
    timeout:900000
});

//请求拦截
$http.interceptors.request.use(config=>{
    // 请求头添加token
    // let token = getItem('token') || null;
    // if(token){
    //     config.headers.token = token;
    // }


    // let uid = getItem('uid') || null;
    // if(uid){ //有uid需要每次请求带上uid
    //     let getParams=config.params || {};
    //     let postData=config.data || {};
    //     if(config.method == 'get'){
    //         config.params=Object.assign(getParams,{id:uid});
    //     }else if(config.method == 'post'){
    //         config.data=Object.assign(postData,{id:uid});
    //     }
    // }
    
    return config
},error=>{
    console.log(`req-inteceptor error ${error}`);
    Promise.reject(error);
});
//响应拦截
$http.interceptors.response.use(response=>{
    if(response.status != 200){
        console.log('网络请求错误');
    }else{
        if(response.data.code != 200){
            Toast(response.data.message);
            if(response.data.code == 40115){
                router.push('/login?action=login');
            }
            return Promise.reject(response.data.message);
        }
    }
    return response.data;
},error=>{
    return Promise.reject(error);
});

export default $http;
<template>
    <div class="page-container">
        <div class="tabs">
            <div class="tab-item" :class="{active:currentTab == -1}" @click="changeTab(-1)">全部</div>
            <div class="tab-item" :class="{active:currentTab == 2}" @click="changeTab(2)">待付款</div>
            <div class="tab-item" :class="{active:currentTab == 1}" @click="changeTab(1)">已付款</div>
            <div class="tab-item" :class="{active:currentTab == 3}" @click="changeTab(3)">已取消</div>
        </div>
        <vue-loadmore 
            :on-loadmore="loadMore"
            :finished="finished"
            load-offset="100">
            <div class="order-list" v-if="collectList.length > 0">
                <div class="order-item" v-for="item in collectList" :key="item.id">
                    <van-image
                        class="user-pic"
                        fit="cover"
                        :src="imgBaseUrl + item.cover"
                    />
                    <div class="right-block">
                        <div class="name-status">
                            <div class="name">{{item.name}}</div>
                            <div class="status fail" v-if="item.status == 2">等待支付</div>
                            <div class="status success" v-if="item.status == 1">交易成功</div>
                            <div class="status" v-if="item.status == 3">取消交易</div>
                        </div>
                        <div class="user-name">{{item.auther}}</div>
                        <div class="price">{{item.price}}元</div>
                    </div>
                </div>
            </div>
            <empty v-else />
        </vue-loadmore>
        
        <!-- <div class="nomore">没有更多了</div> -->
    </div>
</template>

<script>
import $http from '@/utils/request'
import config from '@/config'

export default {
    data(){
        return {
            current:1,
            pageSize:10,
            collectList:[],
            finished:false,
            total:0,
            currentTab:-1,//-1 全部 ，1 已支付，2 待支付，3 已取消
        }
    },
    created(){
        this.getData();
    },
    computed:{
        maxPage(){
            return Math.ceil(this.total / this.pageSize);
        }
    },
    methods:{
        changeTab(val){
            this.currentTab = val;
            this.finished = false;
            this.current = 1;
            this.collectList = [];
            this.getData();
        },
        loadMore(done){
            if(this.current < this.maxPage){
                this.current=this.current + 1;
                this.getData();
            }else{
                this.finished = true;
            }
            done();
        },
        getData(){
            $http({
                method:'get',
                url:config.api.orders,
                params:{
                    current:this.current,
                    pageSize:this.pageSize,
                    status:this.currentTab
                }
            }).then(res=>{
                this.collectList = this.collectList.concat(res.result.records);
                this.total = res.result.total;
            })
        }
    }
}
</script>

<style lang="scss" scoped>
// 隐藏下拉刷新的部分占位
::v-deep .vuejs-refresh-head{
    display: none;
}
.page-container{
    padding:30px;
    .tabs{
        display: flex;
        justify-content: space-around;
        align-items: center;
        .tab-item{
            font-size: 36px;
            font-family: Source Han Sans CN-Regular, Source Han Sans CN;
            font-weight: 400;
            color: #666666;
            position: relative;
            &.active{
                color:#fff;
                &::after{
                    content:'';
                    display: block;
                    width:80px;
                    height:4px;
                    border-radius: 4px;
                    background: #fff;
                    position: absolute;
                    left:50%;
                    transform: translateX(-50%);
                    bottom:-10px;
                }
            }
        }
    }
    .order-list{
        margin-top: 30px;
        .order-item{
            background-color: #2A2B2C;
            border-radius: 16px;
            padding:30px 26px;
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            margin-bottom: 20px;
            overflow: hidden;
            .user-pic{
                width:150px;
                height:150px;
                display: block;
                margin-right: 30px;
                flex-shrink: 0;
                border-radius: 8px;
                overflow: hidden;
                flex-shrink: 0;
            }
            .right-block{
                width:calc(100% - 180px);
                display: flex;
                height:150px;
                justify-content: space-between;
                align-items: flex-start;
                flex-direction: column;
                .name-status{
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    width:100%;
                    .name{
                        font-size: 30px;
                        font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                        font-weight: 400;
                        color: #FFFFFF;
                        width:calc( 100% - 110px );
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                    }
                    .status{
                        width:110px;
                        flex-shrink: 0;
                        font-size: 24px;
                        font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                        font-weight: 400;
                        color: #666666;
                        &.success{
                            color:#52C41A;
                        }
                        &.fail{
                            color:#FF4D4F;
                        }
                    }
                }
                .user-name{
                    font-size: 24px;
                    font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                    font-weight: 400;
                    color: #666666;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    width:100%;
                }
                .price{
                    font-size: 24px;
                    font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                    font-weight: 400;
                    color: #FFFFFF;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    width:100%;
                }
            }
        }
    }
    .nomore{
        margin-top: 30px;
        font-size: 24px;
        font-family: Source Han Sans CN-Regular, Source Han Sans CN;
        font-weight: 400;
        color: #666666;
        text-align: center;
    }
}
</style>
export default {
    api:{
        regist:'/user/register',//注册
        getCode:'/user/code',//获取短信验证码
        login:'/user/login',//登录
        forgetPass:'/user/password',//忘记密码
        changeMobile:'/user/phone',//更换手机号
        realName:'/user/auth/normal',//实名认证
        getBanner:'/index/banners',//获取首页banner
        hotWork:'/work/list',//获取首页热门藏品
        blindBox:'/blindbox/list',//首页盲盒列表
        goodsDetail:'/work/detail',//获取藏品详情
        boxDetail:'/blindbox/info',//获取盲盒详情
        buyRecord:'/work/purchaseRecord',//某个藏品的购买记录
        createOrder:'/createOrder',//生成订单
        alipay:'/payment/alipayWap',//支付宝支付接口
        artList:'/artist/list',//艺术家列表
        mine:'/user/current',//"我的页面"
        upload:'/personal/upload/fdfs',//上传头像
        updateInfo:'/user/info',//更新用户信息
        codeLock:'/user/setTradePassword',//设置安全锁密码
        artDetail:'/artist/detail',//艺术家详情
        pavilionDetail:'artist/getArtDetail',//藏品馆详情
        myCollect:'/personal/works/paid',//我的藏品
        myLike:'/work/listMyLike',//我的喜欢
        myFollow:'/work/listMyFollow',//我的关注
        orders:'/getOrdersList',//订单列表
        unFollow:'/user/unstar',//取消关注
        getGrade:'/user/getGrade',//获取能量值和头像
        exchange:'/user/getChangeWork',//能量值兑换列表
        exchangeRecord:'/user/getChangeList',//兑换记录
        gradeList:'/user/getGradeList',//我的权益 - 查看明细
        addressList:'/address/list',//地址列表
        addAddress:'/address/addAddress',//新增地址
        updateAddress:'/address/updateAddress',//修改地址
        getAddressInfo:'/address/getAddressInfo',//获取地址信息
        exchangeNow:'/user/confirChange',//立即兑换
        logOut:'/user/logout',//退出登录
        collectDetail:'/work/purchasedDetail',//藏品详情
        makeStar:'/user/star',//点亮小心心
        airdrop:'/personal/airdrop',//空投
        airdropDetail:'/personal/airdrop/detail',//空投藏品详情
        getUserByPhone:'/user/getUserByPhone',//根据手机号搜索用户
        isSetTradepw:'/user/isSetTradepw',//查看用户是否设置了交易密码
        resetTradePassword:'/user/resetTradePassword',//重置安全密码
        sendNft:'/user/sendNft',//转赠
        sendDropNft:'/user/sendDropNft',//空投的转赠
        getArtInfo:'/artist/getArtInfo',//判断用户是否是艺术家
        userApply:'/user/apply',//申请成为艺术家
        personalWork:'/personal/work',//发布新作品
        uploadWorks:'/personal/upload/fdfs',//上传作品文件
        personalGroup:'/personal/group/list',//获取作品集列表
        listMySelf:'/work/listMySelf',//管理我的作品
        nftPersonal:'/nftpersonal/apply',//个人作品申请登记
        collectGroup:'/personal/works/group',//获取“我的藏品”集合列表
        collectItem:'/personal/works/groupInfo',//我的藏品系列里的藏品列表
    }
} 
<template>
  <div class="page-container">
    <van-form @submit="onSubmit">
        <van-field name="radio" label="作品类型">
            <template #input>
                <van-radio-group v-model="radio" direction="horizontal">
                    <van-radio :name="1">图片</van-radio>
                    <van-radio :name="2">视频</van-radio>
                    <van-radio :name="3">音频</van-radio>
                </van-radio-group>
            </template>
        </van-field>
        <van-field name="uploader" label="作品图片" v-if="radio == 1">
            <template #input>
                <van-uploader v-model="workPic" :after-read="afterPicRead" max-size="9999999">
                    <div class="uploader-container">
                        <h4>点击添加图片</h4>
                        <h5>WEBP、JPG、PNG、GIF、OR、SVG(Max 5Mb)</h5>
                    </div>
                </van-uploader>
            </template>
        </van-field>
        <div v-else-if="radio == 2">
            <van-field name="uploader" label="作品封面">
                <template #input>
                    <van-uploader v-model="workCover" :after-read="afterCoverRead">
                        <div class="uploader-container">
                            <h4>点击添加图片</h4>
                            <h5>WEBP、JPG、PNG、GIF、OR、SVG(Max 5Mb)</h5>
                        </div>
                    </van-uploader>
                </template>
            </van-field>
            <van-field name="uploader" label="作品内容">
                <template #input>
                    <van-uploader v-model="workFile" :after-read="afterP4Read">
                        <div class="uploader-container">
                            <h4>点击添加视频、音频</h4>
                            <h5>WEBP、JPG、PNG、GIF、OR、SVG(Max 5Mb)</h5>
                        </div>
                    </van-uploader>
                </template>
            </van-field>
        </div>
        <div v-else-if="radio == 3">
            <van-field name="uploader" label="作品封面">
                <template #input>
                    <van-uploader v-model="workCover" :after-read="afterCoverRead">
                        <div class="uploader-container">
                            <h4>点击添加图片</h4>
                            <h5>WEBP、JPG、PNG、GIF、OR、SVG(Max 5Mb)</h5>
                        </div>
                    </van-uploader>
                </template>
            </van-field>
            <van-field name="uploader" label="作品内容">
                <template #input>
                    <van-uploader v-model="workFile" :after-read="afterP3Read">
                        <div class="uploader-container">
                            <h4>点击添加视频、音频</h4>
                            <h5>WEBP、JPG、PNG、GIF、OR、SVG(Max 5Mb)</h5>
                        </div>
                    </van-uploader>
                </template>
            </van-field>
        </div>
        
        <van-field v-model="name" label="作品名称" placeholder="请输入作品名称(不超过50字)" />
        <van-field v-model="price" label="作品售价(元)" placeholder="请设置合理售价，支持小数点2位" />
        <van-field
        readonly
        clickable
        name="picker"
        :value="selectedGroupName"
        label="作品集"
        placeholder="选择作品集"
        @click="showPicker = true"
        />
        <van-field v-model="stockRemain" label="发行总量" placeholder="请输入发行总量，不可超过99" />
        <van-field
        v-model="description"
        rows="5"
        autosize
        label="作品描述"
        type="textarea"
        placeholder="多行输入，不超过500字"
        />
    </van-form>


    <div class="btn-submit actived" @click="confirmApply" v-if="(radio == 1 && picOk) || (radio == 2 && coverOk && p4Ok) || (radio == 3 && coverOk && p3Ok)">申请发售</div>
    <div class="btn-submit" v-else>申请发售</div>

    <van-popup v-model="showPicker" position="bottom">
        <van-picker
            show-toolbar
            :columns="columns"
            @confirm="onConfirm"
            @cancel="showPicker = false"
        />
    </van-popup>

    <!-- 提交成功弹层 -->
    <van-dialog v-model="show" title="提交成功" confirm-button-text="静候佳音">
        <div class="dialog-container">为了数藏市场的合法合规，我们将认真审核您的作品，您可在管理作品内产看作品审核状态。</div>
    </van-dialog>
  </div>
</template>

<script>
import $http from '@/utils/request'
import config from '@/config'
import {getItem} from '@/utils/user'

export default {
    data(){
        return {
            radio:1,
            // uploader: [{ url: 'https://img01.yzcdn.cn/vant/leaf.jpg' }],
            workPic:[],
            workCover:[],
            workFile:[],
            value:null,
            show:false,
            name:null,
            price:null,
            description:null,
            urlCid:null,
            stockRemain:null,
            cover:null,
            groupList:[],
            showPicker: false,
            selectedGroupName:null,
            selectedGroupId:null,
            md5:null,
            picOk:false,//图片是不是传完了
            coverOk:false,//封面是不是传完了
            p4Ok:false,//视频是不是传完了
            p3Ok:false,//音乐是不是传完了
        }
    },
    computed:{
        columns(){
            return this.groupList.map(item => item.title)
        }
    },
    created(){
        //获取作品集
        $http({
            method:'get',
            url:config.api.personalGroup,
        }).then(res=>{
            this.groupList = res.result;
            this.selectedGroupName = this.groupList[0].title;
            this.selectedGroupId = this.groupList[0].id;
        })
    },
    methods:{
        onSubmit(){

        },
        confirmApply(){
            $http({
                method:'post',
                url:config.api.personalWork,
                data:{
                    name:this.name,
                    type:this.radio,
                    price:this.price,
                    description:this.description,
                    urlCid:this.urlCid,
                    stockRemain:this.stockRemain,
                    cover:this.cover,
                    groupId:this.selectedGroupId,
                    md5:this.md5,
                    author:getItem('uid')
                },
            }).then(res=>{
                this.$toast("提交成功");
                setTimeout(()=>{
                    this.$router.push({
                        name:'school-works'
                    });
                },1000);
            })
        },
        onConfirm(value) {
            this.selectedGroupName = value;
            this.selectedGroupId = this.groupList.find(item => item.title == value).id;
            this.showPicker = false;
        },
        afterPicRead(file) {
            let data = new FormData();
            data.append('file',file.file);
            data.append('type',1);//图片type 1
            $http({
                method:'post',
                url:config.api.uploadWorks,
                data:data
            }).then(res=>{
                this.cover = res.result.path;
                this.urlCid = res.result.path;
                this.md5 = res.result.md5;
                this.picOk = true;
            }).catch(err=>{
                alert('文件上传错误');
            })
        },
        afterCoverRead(file) {
            let data = new FormData();
            data.append('file',file.file);
            data.append('type',1);//图片type 1
            $http({
                method:'post',
                url:config.api.uploadWorks,
                data,
            }).then(res=>{
                this.cover = res.result.path;
                this.coverOk = true;
            })
        },
        afterP4Read(file) {
            let data = new FormData();
            data.append('file',file.file);
            data.append('type',2);//图片type 1
            $http({
                method:'post',
                url:config.api.uploadWorks,
                data,
            }).then(res=>{
                this.urlCid = res.result.path;
                this.md5 = res.result.md5;
                this.p4Ok = true;
            })
        },
        afterP3Read(file) {
            let data = new FormData();
            data.append('file',file.file);
            data.append('type',3);//图片type 1
            $http({
                method:'post',
                url:config.api.uploadWorks,
                data,
            }).then(res=>{
                this.urlCid = res.result.path;
                this.md5 = res.result.md5;
                this.p3Ok = true;
            })
        },
    }
}
</script>

<style lang="scss" scoped>
::v-deep .van-cell{
    background-color: #080607;
}
::v-deep .van-field__label{
    color:#fff;
}
::v-deep .van-radio__label{
    color:#fff;
}
::v-deep .van-field__control{
    color:#fff;
}
.page-container{
    background-color: #080607;
    padding-bottom: 80px;
}
.uploader-container{
    color:#fff;
    text-align: center;
    h4,h5{
        margin:10px 0;
    }
    background: #1F1F1F;
    border-radius: 10px;
    border: 1px solid #666666;
    padding: 10px 15px;
}
::v-deep textarea.van-field__control{
    background: #1F1F1F;
    border-radius: 10px;
    border: 1px solid #666666;
    padding: 10px 15px;
}
.btn-submit{
    margin:0 auto;
    margin-top: 80px;
    width: 416px;
    height: 60px;
    line-height: 60px;
    text-align: center;
    background: #333333;
    border-radius: 10px;
    font-size: 32px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #999999;
    &.actived{
        background-color: #1296DB;
        color:#fff;
    }
}
.dialog-container{
    padding:30px;
    line-height: 45px;
}
</style>
<template>
    <div class="page-container">
        <div class="title">实名认证</div>
        <div class="desc">按照法律法规相关监管要求，年满18周岁的中国大陆居民，请先通过账户
的实名认证，再进行交易体验。需要确定您的手机号、姓名、身份证必须
为同一持有人，如有错误，平台有权利封禁您的账号，冻结数字藏品的使
用。</div>
        <div class="form-item">
            <div class="form-label">手机号</div>
            <div class="input-box">
                <input type="text" v-model="phone" placeholder="请输入您的手机号" />
            </div>
        </div>
        <div class="form-item">
            <div class="form-label">真实姓名</div>
            <div class="input-box">
                <input type="text" v-model="username" placeholder="请输入您的真实姓名" />
            </div>
        </div>
        <div class="form-item">
            <div class="form-label">身份证号</div>
            <div class="input-box">
                <input type="text" v-model="idCard" placeholder="请输入您的身份证号" />
            </div>
        </div>
        <div class="btn-login" @click="realName">确定</div>
    </div>
</template>

<script>
import $http from '@/utils/request'
import config from '@/config'

export default {
    data(){
        return {
            phone:'',
            username:'',
            idCard:''
        }   
    },
    methods:{ 
        realName(){
            $http({
                method:'post',
                url:config.api.realName,
                data:{
                    phone:this.phone,
                    username:this.username,
                    idCard:this.idCard
                }
            }).then(res=>{
                if(res.code == 200){
                    this.$toast('实名认证已提交');
                    this.$router.go(-1);
                }
            })
        },
    }    
}
</script>

<style lang="scss" scoped>
.page-container{
    padding:22px 60px;
    .title{
        font-size: 30px;
        font-family: Source Han Sans CN-Bold, Source Han Sans CN;
        font-weight: bold;
        color: #0C81F5;
        margin-bottom: 15px;
    }
    .desc{
        font-size: 20px;
        font-family: Source Han Sans CN-Regular, Source Han Sans CN;
        font-weight: 400;
        color: #666666;
        line-height: 30px;
    }
    .form-item{
        margin-top: 44px;
        border-bottom: 1px solid #FFFFFF;
        .form-label{
            font-size: 26px;
            font-family: Source Han Sans CN-Medium, Source Han Sans CN;
            font-weight: 500;
            color: #FFFFFF;
            line-height: 22px;
        }
        .input-box{
            margin-top: 10px;
            padding:20px 0 ;
            display: flex;
            justify-content: space-between;
            align-items: center;
            & > input{
                width: 100%;
            }
            .btn-grp{
                flex-shrink: 0;
                display: flex;
                justify-content: center;
                align-items: center;
                &>img{
                    width:32px;
                    height:32px;
                    margin-right: 30px;
                }
                &>div{
                    font-size: 24px;
                    font-family: Source Han Sans CN-Medium, Source Han Sans CN;
                    font-weight: 500;
                    color: #0C81F5;
                }
            }
        }
    }
    .btn-login{
        text-align: center;
        margin-top: 200px;
        height: 79px;
        line-height: 79px;
        background: linear-gradient(90deg, #0C81F5 0%, #40A9FF 100%);
        border-radius: 40px 40px 40px 40px;
        font-size: 30px;
        font-family: Source Han Sans CN-Regular, Source Han Sans CN;
        font-weight: 400;
        color: #FFFFFF;
    }
}
</style>
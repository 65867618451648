<template>
    <div class="page-container">
        <div class="setting-list">
            <div class="setting-item" @click="showAction = true">
                <div>头像</div>
                <div>
                    <van-image
                        class="userpic"
                        fit="cover"
                        :src="imgBaseUrl + pageData.avatar"
                    />
                    <img src="@/assets/mine/icon-r-arrow.png" alt="" class="arrow">
                </div>
            </div>
            <div class="setting-item" @click="showChangeNickname =true">
                <div>昵称</div>
                <div>
                    <div class="subdiv">{{pageData.username}}</div>
                    <img src="@/assets/mine/icon-r-arrow.png" alt="" class="arrow">
                </div>
            </div>
            <div class="setting-item" @click="jumpPage('changemobile')">
                <div>手机号</div>
                <div>
                    <div class="subdiv">{{pageData.phone}}</div>
                    <img src="@/assets/mine/icon-r-arrow.png" alt="" class="arrow">
                </div>
            </div>
            <div class="setting-item" @click="jumpPage('changepassword')">
                <div>登录密码</div>
                <div>
                    <img src="@/assets/mine/icon-r-arrow.png" alt="" class="arrow">
                </div>
            </div>
            <div class="setting-item" @click="jumpPage('realname')">
                <div>实名认证</div>
                <div>
                    <img src="@/assets/mine/icon-sm.png" alt="" class="icon-sm">
                    <div class="subdiv">
                        {{
                            pageData.isAuth == 1 ? "待审核" :
                            pageData.isAuth == 2 ? "认证失败" :
                            pageData.isAuth == 3 ? "已认证" : ""
                        }}
                    </div>
                    <img src="@/assets/mine/icon-r-arrow.png" alt="" class="arrow">
                </div>
            </div>
            <div class="setting-item" @click="jumpPage('codelock')">
                <div>安全密码锁</div>
                <div>
                    <div class="subdiv">修改</div>
                    <img src="@/assets/mine/icon-r-arrow.png" alt="" class="arrow">
                </div>
            </div>
            <!-- <div class="setting-item">
                <div>订阅发售日历</div>
                <div>
                    <van-switch v-model="checked" />
                </div>
            </div> -->
            <div class="setting-item" >
                <div>邀请码</div>
                <div>
                    <div class="subdiv">{{mycode}}</div>
                    <img src="@/assets/goodsdetail/icon-copy.png" alt="" class="arrow" v-clipboard:copy="mycode" v-clipboard:success="copySucc" v-clipboard:error="copyFail">
                </div>
            </div>
            <div class="setting-item" @click="jumpPage('addresslist')">
                <div>收货地址</div>
                <div>
                    <img src="@/assets/mine/icon-r-arrow.png" alt="" class="arrow">
                </div>
            </div>
        </div>
        <div class="btn-logout" @click="logOut">退出登录</div>

        <!-- 切换头像Action -->
        <van-action-sheet
            v-model="showAction"
            :actions="actions"
            @select="selectAction"
            cancel-text="取消"
            close-on-click-action
        />
        <!-- 图片上传 -->
        <van-uploader ref="uploader" :after-read="afterRead" />
        <!-- 修改昵称 -->
        <van-dialog v-model="showChangeNickname" title="修改昵称" @confirm="updateName" @closed="newName = ''" show-cancel-button>
            <div class="origin-name">原昵称：{{pageData.username}}</div>
            <input placeholder="支持2-16为中英文、数字" v-model="newName" class="name-input"/>
        </van-dialog>
    </div>
</template>

<script>
import $http from '@/utils/request'
import config from '@/config'
import { getItem } from '../../utils/user';


export default {
    data() {
        return {
            checked: true,
            showAction:false,
            actions: [{ name: '拍摄' }, { name: '从手机相册选择' }],
            showChangeNickname:false,
            pageData:{},
            newName:''
        };
    },
    created(){
        this.getData();
    },
    computed:{
        mycode(){
            return getItem('myInvitecode') ? getItem('myInvitecode') : null;
        }
    },
    methods:{
        copySucc(){
            this.$toast('复制成功');
        },
        copyFail(){
            this.$toast('复制失败');
        },
        logOut(){
            $http({
                method:'get',
                url:config.api.logOut,
            }).then(res=>{
                this.$toast("退出成功");
                this.$router.push({
                    name:'index'
                });
            });
        },
        jumpPage(name){
            this.$router.push({
                name
            });
        },
        updateName(){
            $http({
                method:'post',
                url:config.api.updateInfo,
                data:{
                    username:this.newName
                }
            }).then(result=>{
                this.$toast("更新成功");
                this.getData();
            })
        },
        getData(){
            $http({
                method:'get',
                url:config.api.mine,
            }).then(res=>{
                this.pageData = res.result;
            })
        },
        afterRead(file) {
            let data = new FormData();
            data.append('file',file.file);
            data.append('type',1);//图片type 1
            $http({
                method:'post',
                url:config.api.upload,
                data,
                // headers:{
                //     "Content-Type":"multipart/form-data"
                // }
            }).then(res=>{
                $http({
                    method:'post',
                    url:config.api.updateInfo,
                    data:{
                        avatar:res.result.path
                    }
                }).then(result=>{
                    this.$toast("上传成功");
                    this.getData();
                })
            })
        },
        selectAction(action,index){
            this.$refs['uploader'].chooseFile();
        },
    }
}
</script>

<style lang="scss" scoped>
::v-deep .van-uploader{
    display: none;
}
::v-deep .van-button--default{
    background-color: #2D2F2E;
}
::v-deep .van-dialog__confirm{
    color:#fff;
}
::v-deep .van-button--default{
    color:#fff;
}
::v-deep .van-dialog__header{
    color:#fff;
}
.page-container{
    padding:30px;
    position: relative;
    .origin-name{
        padding:40px 0;
        text-align: center;
        font-size: 32px;
        color:#fff;
    }
    .name-input{
        width: 80%;
        height: 82px;
        line-height: 82px;
        display: block;
        margin:0 auto;
        padding:0 20px;
        color:#fff;
        background: #373938;
        border-radius: 4px 4px 4px 4px;
        font-size: 36px;
        margin-bottom: 30px;
    }
    .setting-list{
        .setting-item{
            height:111px;
            border-radius: 16px;
            background-color: #2A2B2C;
            margin-bottom: 10px;
            padding:0 30px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            &>div{
                &:first-child{
                    font-size: 32px;
                    font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                    font-weight: 400;
                    color: #FFFFFF;
                    width:100%;
                }
                &:last-child{
                    display: flex;
                    justify-content: flex-end;
                    align-items: center;
                    flex-shrink: 0;
                    .userpic{
                        width:64px;
                        height: 64px;
                        border-radius: 50%;
                        overflow: hidden;
                    }
                    .icon-sm{
                        width:32px;
                        margin-right: 10px;
                    }
                    .subdiv{
                        font-size: 32px;
                        font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                        font-weight: 400;
                        color: #999999;
                    }
                    .arrow{
                        width:32px;
                        margin-left: 10px;
                    }
                }
            }
        }
    }
    .btn-logout{
        margin-top: 90px;
        height: 90px;
        line-height: 90px;
        text-align: center;
        background: #2A2B2C;
        border-radius: 16px 16px 16px 16px;
        font-size: 32px;
        font-family: Source Han Sans CN-Regular, Source Han Sans CN;
        font-weight: 400;
        color: #CB4A5D;
    }
}
</style>
<template>
    <div class="page-container">
        <div class="artinfo-box">
            <div class="namepic">
                <van-image
                    class="pic"
                    fit="cover"
                    :src="imgBaseUrl + pageData.avatar"
                />
                <div>{{pageData.name}}</div>
            </div>
            <div class="favinfo-box">
                <div class="box-item">
                    <div>{{pageData.fansNum}}</div>
                    <div>粉丝</div>
                </div>
                <div class="box-item">
                    <div>{{pageData.likesNum}}</div>
                    <div>获赞</div>
                </div>
            </div>
            <div class="btn-follow followed" @click.stop="unstar(pageData.id,3)" v-if="pageData.isStared">
                <img src="../assets/icon-followed.png" alt="">
                <span>已关注</span>
            </div>
            <div class="btn-follow" v-else @click.stop="star(pageData.id,3)">
                <img src="../assets/icon-follow.png" alt="">
                <span>关注</span>
            </div>
        </div>
        <!-- 个人介绍 -->
        <div class="introduce-box">
            <div class="introduce-title">
                <img src="../assets/icon-introduce-title.png" alt="">
                <span>个人介绍</span>
                <img src="../assets/icon-introduce-title.png" alt="">
            </div>
            <div class="introduce-content">
                {{pageData.description}}
            </div>
        </div>
        <div class="tabs">
            <div class="tab-item" :class="{active:currentTab == 1}" @click="currentTab = 1">画廊</div>
            <div class="tab-item" :class="{active:currentTab == 2}" @click="currentTab = 2">藏品馆</div>
        </div>
        <div class="filters">全部</div>
        <!-- 藏品列表 -->
        <div class="goods-list" v-if="currentTab == 1">
            <div class="goods-item" v-for="item in pageData.works" :key="item.id" @click="jumpPage('goodsdetail',{id:item.id,type:'goods'})">
                <div class="img-box">
                    <van-image
                        class="img-box-bg"
                        fit="cover"
                        :src="imgBaseUrl + item.avatar"
                    />
                    <div class="img-box-wrapper">
                        <img :src="imgBaseUrl + item.avatar" alt=""/>
                    </div>
                </div>
                <div class="goods-info">
                    <div class="author-line">
                        <div class="author-info">
                            <van-image
                                class="pic"
                                fit="cover"
                                :src="imgBaseUrl + pageData.avatar"
                            />
                            <span>{{pageData.name}}</span>
                        </div>
                        <div class="status-box" v-if="item.status == 1">
                            <img src="@/assets/icon-hot.png" alt="">
                            <span>热销中</span>
                        </div>
                        <div class="sale-out" v-if="item.status == 3">已售罄</div>
                        <div class="sale-out" v-if="item.status == 2">定时上线</div>
                        <div class="sale-out" v-if="item.status == 5">预约</div>
                    </div>
                    <div class="goods-name">{{item.name}}</div>
                    <div class="price-line">
                        <div class="price-box">
                            <span>当前售价</span>
                            <span>¥ {{item.price}}</span>
                        </div>
                        <div class="fav-box">
                            <span>{{item.likeNum}}</span>
                            <img src="@/assets/icon-fav-light.png" @click.stop="unstar(item.id,1,item)" v-if="item.stared" alt="">
                            <img src="@/assets/icon-fav.png" @click.stop="star(item.id,1,item)" v-else alt="">
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- 藏品馆列表 -->
        <div class="guan-list" v-if="currentTab == 2">
            <div class="guan-item" v-for="item in pageData.gworks" :key="item.id" @click="jumpPage('pavilion',{id:item.id})">
                <img :src="imgBaseUrl + item.cover" alt="" class="jh-cover">
                <div class="info-box">
                    <div class="title">{{item.title}}</div>
                    <div class="introduce" v-html="item.description"></div>
                    <div class="arrow-line">
                        <img src="../assets/icon-r-arrow.png" alt="">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import $http from '@/utils/request'
import config from '@/config'
import {getItem} from '@/utils/user'

export default {
    name:"artdetail",
    data(){
        return {
            pageData:{
                works:[],
                gworks:[]
            },
            currentTab:1,
        }
    },
    computed:{
        id(){
            return this.$route.params.id;
        }
    },
    activated(){
        // this.pageData = JSON.parse(getItem('targetArt'));
        $http({
            method:'get',
            url:config.api.artDetail,
            params:{
                id:this.id,
            }
        }).then(res=>{
            this.pageData = res.result;
        })
    },
    methods:{
        //点亮小心心
        star(id,type,item){
            $http({
                method:'get',
                url:config.api.makeStar,
                params:{
                    id,
                    type //type 1 表示藏品的小心心 2 表示藏品组的小心心  3 表示艺术家的关注
                }
            }).then(res=>{
                this.$toast('操作成功');
                if(item){
                    item.stared = true;
                    item.likeNum = item.likeNum + 1;
                }else{
                    this.pageData.isStared = true;
                    this.pageData.fansNum = this.pageData.fansNum + 1;
                }
                
                this.pageData = this.pageData;
            })
        },
        //去掉小心心
        unstar(id,type,item){
            $http({
                method:'get',
                url:config.api.unFollow,
                params:{
                    id,
                    type
                }
            }).then(res=>{
                this.$toast('操作成功');
                if(item){
                    item.stared = false;
                    item.likeNum = item.likeNum - 1;
                }else{
                    this.pageData.isStared = false;
                    this.pageData.fansNum = this.pageData.fansNum - 1;
                }
                
                this.pageData = this.pageData;
            })
        },
        jumpPage(name,params){
            this.$router.push({
                name,
                params
            });
        },
    }
}
</script>

<style lang="scss" scoped>
.page-container{
    padding:30px;
    .artinfo-box{
        padding:60px 0;
        .namepic{
            .pic{
                display: block;
                width:200px;
                height:200px;
                border-radius: 50%;
                margin:0 auto;
                margin-bottom: 30px;
                overflow: hidden;
            }
            & > div{
                font-size: 36px;
                font-family: Source Han Sans CN-Bold, Source Han Sans CN;
                font-weight: bold;
                color: #FFFFFF;
                text-align: center;
            }
        }
        .favinfo-box{
            margin:60px auto;
            width:380px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            .box-item{
                text-align: center;
                & > div{
                    &:first-child{
                        font-size: 36px;
                        font-family: Source Han Sans CN-Bold, Source Han Sans CN;
                        font-weight: bold;
                        color: #FFFFFF;
                        margin-bottom: 10px;
                    }
                    &:last-child{
                        font-size: 30px;
                        font-family: Source Han Sans CN-Medium, Source Han Sans CN;
                        font-weight: 500;
                        color: #999999;
                    }
                }
            }
        }
        .btn-follow{
            background: linear-gradient(90deg, #0C81F5 0%, #40A9FF 100%);
            width:152px;
            height:55px;
            border-radius: 28px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin:0 auto;
            margin-bottom: 60px;
            &.followed{
                background: #333;
                color:#999;
            }
            img{
                width:32px;
                margin-right: 10px;
            }
            span{
                font-size: 24px;
                font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                font-weight: 400;
                color: #FFFFFF;
            }
        }
    }
    .introduce-box{
        padding:58px 20px;
        border-radius: 18px;
        border:2px solid #0C81F5;
        margin-bottom: 59px;
        .introduce-title{
            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom: 50px;
            img{
                width:16px;
            }
            span{
               font-size: 36px;
                font-family: Source Han Sans CN-Bold, Source Han Sans CN;
                font-weight: bold;
                color: #40A9FF; 
                margin:0 24px;
            }
        }
        .introduce-content{
            font-size: 26px;
            font-family: Source Han Sans CN-Regular, Source Han Sans CN;
            font-weight: 400;
            color: #999999;
            line-height: 40px;
            word-break: break-all;
        }
    }
    .tabs{
        border-bottom: 1px solid #707070;
        padding:0 170px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .tab-item{
            height:66px;
            line-height: 66px;
            position: relative;
            width:102px;
            text-align: center;
            font-size: 26px;
            font-family: Source Han Sans CN-Regular, Source Han Sans CN;
            font-weight: 400;
            color: #999999;
            &.active{
                color:#fff;
                &::after{
                    content:'';
                    display: block;
                    width:102px;
                    height:9px;
                    background-color: #0C81F5;
                    border-radius: 5px;
                    position: absolute;
                    left:0;
                    bottom:0;
                }
            }
        }
    }
    .filters{
        padding:30px 0;
        font-size: 24px;
        font-family: Source Han Sans CN-Regular, Source Han Sans CN;
        font-weight: 400;
        color: #40A9FF;
    }
    .goods-list{
        .goods-item{
            background-color: #080607;
            border-radius: 16px;
            overflow: hidden;
            margin-bottom: 30px;
            .img-box{
                position: relative;
                height:542px;
                border-radius: 16px;
                overflow: hidden;
                .img-box-bg{
                    position: absolute;
                    left:0;
                    right:0;
                    top:0;
                    bottom:0;
                    // background: url('../assets/tmp-cover.png');
                    // background-size: cover;
                    opacity: 0.5;
                    z-index: 1;
                }
                .img-box-wrapper{
                    width:100%;
                    height:542px;
                    padding:26px;
                    box-sizing: border-box;
                    position: absolute;
                    top:0;
                    left:0;
                    z-index: 2;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    img{
                        display: block;
                        max-height: 100%;
                    }
                }
            }
            .goods-info{
                padding:30px;
                padding-bottom: 0;
                .author-line{
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    margin-bottom: 28px;
                    .author-info{
                        display: flex;
                        justify-content: start;
                        align-items: center;
                        .pic{
                            margin-right: 30px;
                            width:64px;
                            height:64px;
                            border-radius: 50%;
                            overflow: hidden;
                        }
                        span{
                            font-size: 22px;
                            font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                            font-weight: 400;
                            color: #999999;
                        }
                    }
                    .status-box{
                        display: flex;
                        justify-content: end;
                        align-items: center;
                        img{
                            margin-right: 20px;
                            width:28px;
                        }
                        span{
                            font-size: 22px;
                            font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                            font-weight: 400;
                            color: #999999;
                        }
                    }
                    .sale-out{
                        width: 92px;
                        height: 40px;
                        line-height: 40px;
                        text-align: center;
                        background: #999999;
                        font-size: 22px;
                        font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                        font-weight: 400;
                        color: #FFFFFF;
                    }
                }
                .goods-name{
                    font-size: 30px;
                    font-family: Source Han Sans CN-Medium, Source Han Sans CN;
                    font-weight: 500;
                    color: #FFFFFF;
                    padding-bottom: 24px;
                    border-bottom: 1px solid #707070;
                }
                .price-line{
                    padding:18px 0;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    .price-box{
                        display: flex;
                        justify-content: start;
                        align-items: center;
                        span{
                            &:first-child{
                                font-size: 22px;
                                font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                                font-weight: 400;
                                color: #999999;
                                margin-right: 20px;
                            }
                            &:last-child{
                                font-size: 30px;
                                font-family: Source Han Sans CN-Medium, Source Han Sans CN;
                                font-weight: 500;
                                color: #FFFFFF;
                            }
                        }
                    }
                    .fav-box{
                        display: flex;
                        justify-content: end;
                        align-items: center;
                        span{
                            margin-right: 10px;
                            font-size: 22px;
                            font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                            font-weight: 400;
                            color: #999999;
                        }
                        img{
                            width:48px;
                        }
                    }
                }
            }
        }
    }
    .guan-list{
        .guan-item{
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            border:1px solid #40A9FF;
            border-radius: 16px;
            padding:22px 18px;
            margin-bottom: 30px;
            .jh-cover{
                flex-shrink: 0;
                width:321px;
                height:187px;
                margin-right: 30px;
            }
            .info-box{
                width:100%;
                .title{
                    font-size: 24px;
                    font-family: Source Han Sans CN-Medium, Source Han Sans CN;
                    font-weight: 500;
                    color: #FFFFFF;
                    margin-bottom: 30px;
                }
                .introduce{
                    font-size: 20px;
                    font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                    font-weight: 500;
                    color: #666666;
                    line-height: 36px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display:-webkit-box;
                    -webkit-line-clamp: 3;
                    /* autoprefixer:off */
                    -webkit-box-orient: vertical;
                    /* autoprefixer:on */
                }
                .arrow-line{
                    text-align: right;
                    margin-top: 9px;
                    img{
                        width:32px;
                    }
                }
            }
        }
    }
}
</style>
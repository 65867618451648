<template>
    <div class="page-container">
        <div class="page-top">
            <div class="info-box">
                <van-image
                    class="user-pic"
                    fit="cover"
                    :src="imgBaseUrl + pageData.avatar"
                />
                <div class="level-box">
                    <img src="@/assets/mine/icon-level.png" alt="">
                    <span>{{pageData.grade}}能量值</span>
                </div>
            </div>
            <div class="record-link" @click="jumpPage('exchangerecord')">
                <span>兑换记录</span>
                <img src="@/assets/mine/icon-zone-arrow.png" alt="">
            </div>
        </div>
        <div class="title-line">兑换好礼</div>
        <vue-loadmore 
            :on-loadmore="loadMore"
            :finished="finished"
            load-offset="100">
            <div class="exchange-list" v-if="collectList.length > 0">
                <div class="exchange-item" v-for="item in collectList" :key="item.id" @click="goDetail(item)">
                    <van-image
                        class="goods-pic"
                        fit="cover"
                        :src="imgBaseUrl + item.cover"
                    />
                    <div class="goods-name">{{item.title}}</div>
                    <div class="btn-exchange" @click="jumpPage('exchangepage')">{{item.price}}能量值</div>
                </div>
            </div>
            <empty v-else />
        </vue-loadmore>
    </div>
</template>

<script>
import $http from '@/utils/request'
import config from '@/config'
import {setItem} from '@/utils/user'

export default {
    data(){
        return {
            current:1,
            pageSize:10,
            total:0,
            collectList:[],
            pageData:{},
            finished:false,
        }
    },
    created(){
        this.getData();
        this.getRecord();
    },
    computed:{
        maxPage(){
            return Math.ceil(this.total / this.pageSize);
        }
    },
    methods:{
        goDetail(item){
            setItem('currentExchange',JSON.stringify(item));
            this.$router.push({
                name:'exchangepage'
            });
        },
        getData(){
            $http({
                method:'get',
                url:config.api.getGrade
            }).then(res=>{
                this.pageData = res.result;
            })
        },
        jumpPage(name){
            this.$router.push({
                name
            });
        },
        getRecord(){
            $http({
                method:'get',
                url:config.api.exchange,
                params:{
                    current:this.current,
                    pageSize:this.pageSize
                }
            }).then(res=>{
                this.collectList = this.collectList.concat(res.result.records);
                this.total = res.result.total;
            })
        },
        loadMore(done){
            if(this.current < this.maxPage){
                this.current=this.current + 1;
                this.getRecord();
            }else{
                this.finished = true;
            }
            done();
        },
    }
}
</script>

<style lang="scss" scoped>
.page-container{
    padding:30px;
    .page-top{
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 40px;
        border-bottom: 1px solid #707070;
        margin-bottom: 48px;
        .info-box{
            padding-left: 30px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            .user-pic{
                width:64px;
                height:64px;
                display: block;
                margin-right: 10px;
                flex-shrink: 0;
                border-radius: 50%;
                overflow: hidden;
                flex-shrink: 0;
            }
            .level-box{
                padding:0 22px;
                height: 46px;
                border-radius: 24px 24px 24px 24px;
                border: 1px solid #707070;
                display: flex;
                justify-content: center;
                align-items: center;
                img{
                    width:32px;
                    margin-right: 10px;
                }
                span{
                    font-size: 24px;
                    font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                    font-weight: 400;
                    color: #87C38F;
                }
            }
        }
        .record-link{
            display: flex;
            justify-content: flex-end;
            align-items: center;
            span{
                font-size: 30px;
                font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                font-weight: 400;
                color: #FFFFFF;
            }
            img{
                width:32px;
                margin-left: 10px;
            }
        }
    }
    .title-line{
        font-size: 30px;
        font-family: Source Han Sans CN-Bold, Source Han Sans CN;
        font-weight: bold;
        color: #0C81F5;
        margin-bottom: 30px;
    }
    .exchange-list{
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        flex-wrap: wrap;
        .exchange-item{
            margin-bottom: 30px;
            border-radius: 16px;
            background-color: #2A2B2C;
            box-sizing: border-box;
            width:330px;
            padding:30px;
            .goods-pic{
                width:274px;
                height:244px;
                display: block;
                margin-bottom: 30px;
                border-radius: 16px;
                overflow: hidden;
            }
            .goods-name{
                font-size: 30px;
                font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                font-weight: 400;
                color: #FFFFFF;
                text-align: center;
                margin-bottom: 30px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
            .btn-exchange{
                margin:0 auto;
                width: 233px;
                height: 48px;
                line-height: 48px;
                text-align: center;
                border-radius: 24px 24px 24px 24px;
                opacity: 1;
                border: 1px solid #87C38F;
                font-size: 24px;
                font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                font-weight: 400;
                color: #7EB686;
            }
        }
    }
}
</style>
<template>
  <div class="page-container">
    <div class="page-top">
        <h4>成为艺术家就差一步</h4>
        <h5>成为艺术家后即可发售自己的作品</h5>
    </div>
    <van-form>
        <van-field v-model="pageData.phone" label="当前账号" readonly placeholder="请输入当前账号" />
        <van-field v-model="username" label="设置艺名" placeholder="请设置您的艺术家名称" />
        <van-field
        v-model="descrption"
        rows="5"
        autosize
        label="对自己说的话"
        type="textarea"
        placeholder="多行输入，不超过500字"
        />
        <van-field name="uploader" label="设置展示头像">
            <template #input>
                <van-uploader v-model="uploader" :after-read="afterRead">
                    <div class="uploader-container">
                        <img src="@/assets/school/icon-headpic.png" alt="">
                    </div>
                </van-uploader>
            </template>
        </van-field>
    </van-form>
    <van-checkbox v-model="xieyiOk" shape="square" class="xieyi">选中即表示同意并遵守<a target="_blank" href="http://image.nftnet.world/zhucexieyi.pdf">《用户注册协议》</a></van-checkbox>

    <div class="btn-submit actived" @click="confirmApply" v-if="resultPath && xieyiOk">完成，去上传作品</div>
    <div class="btn-submit" v-else @click="makeToast">完成，去上传作品</div>
  </div>
</template>

<script>
import $http from '@/utils/request'
import config from '@/config'


export default {
    data(){
        return {
            descrption:null,
            username:null,
            uploader:[],
            pageData:{},
            resultPath:null,
            xieyiOk:true,
        }
    },
    created(){
        this.getData();
    },
    methods:{
        makeToast(){
            if(!this.resultPath){
                this.$toast("请上传头像");
                return;
            }
            if(!this.xieyiOk){
                this.$toast("请勾选用户注册协议");
                return;
            }
        },
        getData(){
            $http({
                method:'get',
                url:config.api.mine,
            }).then(res=>{
                this.pageData = res.result;
            })
        },
        afterRead(file) {
            let data = new FormData();
            data.append('file',file.file);
            data.append('type',1);//图片type 1
            $http({
                method:'post',
                url:config.api.upload,
                data,
                // headers:{
                //     "Content-Type":"multipart/form-data"
                // }
            }).then(res=>{
                this.resultPath = res.result.path;
            })
        },
        confirmApply(){
            $http({
                method:'post',
                url:config.api.userApply,
                data:{
                    avatar:this.resultPath,
                    username:this.username,
                    descrption:this.descrption
                }
            }).then(res=>{
                this.$toast("申请成功!");
                setTimeout(()=>{
                    this.$router.push({
                        name:'school-release'
                    });
                },1000);
            })
        },
    }
}
</script>

<style lang="scss" scoped>
::v-deep .van-cell{
    background-color: #080607;
}
::v-deep .van-field__label{
    color:#fff;
}
::v-deep .van-radio__label{
    color:#fff;
}
::v-deep .van-field__control{
    color:#fff;
}
::v-deep textarea.van-field__control{
    background: #1F1F1F;
    border-radius: 10px;
    border: 1px solid #666666;
    padding: 10px 15px;
}
.page-container{
    padding:30px 0;
    min-height: 100vh;
    background-color: #080607;
    .page-top{
        text-align: center;
        color: #FFFFFF;
        h4{
            font-size: 48px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            margin:0;
            margin-bottom: 20px;
        }
        h5{
            font-size: 28px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            margin:0;
            margin-bottom: 20px;
        }
    }
    .uploader-container{
        &>img{
            width:160px;
        }
    }
    .btn-submit{
        margin:0 auto;
        margin-top: 80px;
        width: 416px;
        height: 60px;
        line-height: 60px;
        text-align: center;
        background: #333333;
        border-radius: 10px;
        font-size: 32px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #999999;
        &.actived{
            background-color: #1296DB;
            color:#fff;
        }
    }
    .xieyi{
        color:#fff;
        padding:20px;
        margin-top: 50px;
        a{
            color:#0c81f5;
        }
    }
    ::v-deep .van-checkbox__label{
        color:#fff;
    }
}
</style>
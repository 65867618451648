<template>
    <div class="page-container">
        <div class="page-top">
            <img src="@/assets/calendar/logo.png" alt="">
            <div class="btn-grp">
                <!-- <div class="btn-item">订阅</div> -->
                <div class="btn-item" @click="makeShare">分享</div>
            </div>
        </div>

        <!-- <div class="date-list" v-if="hotList.length > 0">
            <div class="date-item" v-for="item in hotList" :key="item.id">
                <div class="date-box">
                    <div class="date">{{item.launchTime | getDate}}</div>
                    <div class="time">{{item.launchTime | getTime}}</div>
                </div>
                <div class="goods-list">
                    <div class="goods-item">
                        <van-image
                        class="cover-img"
                        fit="cover"
                        :src="imgBaseUrl + item.cover"
                        />
                        <div class="goods-info">
                            <div class="goods-name">{{item.owner}}·{{item.name}}</div>
                            <div class="counts">
                                <div class="counts-tag">限量</div>
                                <div>{{item.stockTotal}}份</div>
                            </div>
                            <div class="price">¥ {{item.price}}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <empty v-else /> -->

        <img src="@/assets/calendar/fsjh.jpg" style="width:100%;display:block;" alt="">

        <!-- 敬请期待 -->
        <!-- <div class="coming-soon">—更多内容敬请期待—</div> -->

        <!-- 分享弹层 -->
        <shareLayer v-if="showShare" from="calendar" @closeShare="showShare = false" />
    </div>
</template>

<script>
import shareLayer from '@/components/shareLayer'

import $http from '@/utils/request'
import config from '@/config'

export default {
    data(){
        return {
            current:1,
            pageSize:10,
            hotList:[],
            total:0,
            showShare:false,
        }
    },
    filters:{
        getDate(val){
            if(!val){
                return ''
            }else{
                return val.split(' ')[0]
            }
        },
        getTime(val){
            if(!val){
                return ''
            }else{
                return val.split(' ')[1]
            }
        }
    },
    components:{
        shareLayer
    },
    created(){
        this.getHotWork();
    },
    methods:{
        makeShare(){
            this.showShare = true;
        },
        getHotWork(){
            let data={
                range:2,
                current:this.current,
                pageSize:this.pageSize
            };

            $http({
                method:'get',
                url:config.api.hotWork,
                params:data
            }).then(res=>{
                this.hotList = res.result.records;
                this.total = res.result.total;
            })
        },
    }
}
</script>

    
<style lang="scss" scoped>
.page-container{
    // padding:25px 30px;
    padding:25px 0;
    .page-top{
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 30px;
        padding:0 30px;
        &>img{
            width:216px;
        }
        .btn-grp{
            display: flex;
            justify-content: flex-end;
            align-items: center;
            .btn-item{
                margin-left: 20px;
                width: 96px;
                height: 36px;
                line-height: 36px;
                text-align: center;
                background: linear-gradient(90deg, #0C81F5 0%, #40A9FF 100%);
                border-radius: 21px 21px 21px 21px;
                font-size: 24px;
                font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                font-weight: 400;
                color: #FFFFFF;
            }
        }
    }
    .date-list{
        .date-item{
            background-color: #080607;
            border-radius: 16px;
            margin-bottom: 10px;
            .date-box{
                padding:28px;
                padding-top: 48px;
                .date{
                    font-size: 32px;
                    font-family: Source Han Sans CN-Medium, Source Han Sans CN;
                    font-weight: 500;
                    color: #FFFFFF;
                    margin-bottom: 20px;
                }
                .time{
                    font-size: 28px;
                    font-family: Source Han Sans CN-Medium, Source Han Sans CN;
                    font-weight: 500;
                    color: #FFFFFF;
                }
            }
            .goods-list{
                .goods-item{
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    background-color: #323232;
                    margin-bottom: 30px;
                    border-radius: 16px;
                    &:last-child{
                        margin-bottom: 0;
                    }
                    .cover-img{
                        width:294px;
                        height:294px;
                        border-radius: 16px;
                        flex-shrink: 0;
                    }
                    .goods-info{
                        box-sizing: border-box;
                        width:100%;
                        padding:0 32px;
                        .goods-name{
                            font-size: 28px;
                            font-family: Source Han Sans CN-Medium, Source Han Sans CN;
                            font-weight: 500;
                            color: #FFFFFF;
                            margin-bottom: 33px;
                        }
                        .counts{
                            display: flex;
                            justify-content: flex-start;
                            align-items: center;
                            margin-bottom: 44px;
                            .counts-tag{
                                width: 78px;
                                height: 37px;
                                line-height: 37px;
                                text-align: center;
                                background: linear-gradient(270deg, #40A9FF 0%, #0F61B3 100%);
                                border-radius: 4px 0px 0px 4px;
                                font-size: 22px;
                                font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                                font-weight: 400;
                                color: #FFFFFF;
                                margin-right: 20px;
                            }
                            div{
                                font-size: 22px;
                                font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                                font-weight: 400;
                                color: #FFFFFF;
                            }
                        }
                        .price{
                            font-size: 32px;
                            font-family: Source Han Sans CN-Medium, Source Han Sans CN;
                            font-weight: 500;
                            color: #FFFFFF;
                        }
                    }
                }
            }
        }
    }
    .coming-soon{
        font-size: 24px;
        font-family: Source Han Sans CN-Regular, Source Han Sans CN;
        font-weight: 400;
        color: #666666;
        text-align: center;
        margin-top: 125px;
    }
}
</style>
<template>
    <div class="page-container">
        <div class="title">赠送作品</div>
        <div class="order-list">
            <div class="order-item">
                <van-image
                    class="user-pic"
                    fit="cover"
                    :src="imgBaseUrl + goodsInfo.cover"
                />
                <div class="right-block">
                    <div class="name-status">
                        <div class="name">{{goodsInfo.name}}</div>
                    </div>
                    <div class="user-name">编号：{{goodsInfo.tokenId + '#' + goodsInfo.number}}</div>
                    <div class="price">¥{{goodsInfo.price}}元</div>
                </div>
            </div>
        </div>
        <div class="password-box">
            <div class="title">请输入安全密码，验证信息</div>
            <!-- 密码输入框 -->
            <van-password-input
            :value="password"
            :focused="showKeyboard"
            @focus="showKeyboard = true"
            />
            <div class="forget-pass" @click="jumpPage('resetpassword')">忘记密码?</div>
        </div>
        <div class="pay-way">
            <div class="title">
                <div>GAS费用</div>
                <div>¥1.00</div>
            </div>
            <div class="way-list">
                <div class="way-item" @click="changeType(1)">
                    <div class="waybox">
                        <img src="@/assets/mine/icon-alipay.png" alt="">
                        <div>支付宝</div>
                    </div>
                    <img v-if="type == 1" src="@/assets/mine/icon-checked.png" alt="">
                    <img v-else src="@/assets/mine/icon-notcheck.png" alt="">
                </div>
                <div class="way-item" @click="changeType(2)">
                    <div class="waybox">
                        <img src="@/assets/mine/icon-powerpay.png" alt="">
                        <div>能量值</div>
                    </div>
                    <img v-if="type == 2" src="@/assets/mine/icon-checked.png" alt="">
                    <img v-else src="@/assets/mine/icon-notcheck.png" alt="">
                </div>
            </div>
        </div>

        <div class="btn-grp">
            <div class="btn-item" @click="makeCancel">取消</div>
            <div class="btn-item light" @click="sendNft">支付GAS费</div>
        </div>


        <!-- 数字键盘 -->
        <van-number-keyboard
        v-model="password"
        :show="showKeyboard"
        @blur="showKeyboard = false"
        />

        <!-- 支付弹层 -->
        <payLayer v-if="showPay" :orderId="orderId" goodsName="GAS费支付" @closePayLayer="showPay = false" author="NFTNET" :count="1" :price="1" />

    </div>
</template>

<script>
import $http from '@/utils/request'
import config from '@/config'
import {getItem} from '@/utils/user'
import payLayer from '@/components/payLayer'
import {Dialog} from 'vant'

export default {
    data(){
        return {
            password:'',
            showKeyboard:false,
            isSet:0,//0 未设置过,1 设置过
            goodsInfo:{},
            type:1,//1 支付宝,2 积分
            showPay:false,
            orderId:null,
        }
    },
    computed:{
        pageType(){
            return this.$route.query.type
        }
    },
    created(){
        this.isSetTradepw();
        this.goodsInfo = JSON.parse(getItem('currentTrans'));
    },
    components:{
        payLayer
    },
    methods:{
        makeCancel(){
            this.$router.go(-1);
        },
        sendNft(){
            if(this.pageType == 'airdrop'){ //空投藏品的转赠
                if(this.type == 2){ //能量值支付
                    $http({
                        method:'post',
                        url:config.api.sendDropNft,
                        data:{
                            txId:this.$route.query.id,
                            id:this.$route.query.userid,
                            tradeword:this.password,
                            type:this.type,
                            amount:10
                        }
                    }).then(res=>{
                        this.$toast('操作成功');
                        this.$router.replace({
                            name:'transfer-manage'
                        });
                    })
                }else if(this.type == 1){ //支付宝支付
                    $http({
                        method:'post',
                        url:config.api.sendDropNft,
                        data:{
                            txId:this.$route.query.id,
                            id:this.$route.query.userid,
                            tradeword:this.password,
                            type:this.type,
                            amount:1
                        }
                    }).then(res=>{
                        this.orderId = res.result.orderId;
                        this.showPay = true;
                    })
                }
            }else{ //普通藏品的转赠
                if(this.type == 2){ //能量值支付
                    $http({
                        method:'post',
                        url:config.api.sendNft,
                        data:{
                            txId:this.$route.query.id,
                            id:this.$route.query.userid,
                            tradeword:this.password,
                            type:this.type,
                            amount:10
                        }
                    }).then(res=>{
                        this.$toast('操作成功');
                        this.$router.replace({
                            name:'transfer-manage'
                        });
                    })
                }else if(this.type == 1){ //支付宝支付
                    $http({
                        method:'post',
                        url:config.api.sendNft,
                        data:{
                            txId:this.$route.query.id,
                            id:this.$route.query.userid,
                            tradeword:this.password,
                            type:this.type,
                            amount:1
                        }
                    }).then(res=>{
                        this.orderId = res.result.orderId;
                        this.showPay = true;
                    })
                }
            }
            
            
        },
        isSetTradepw(){
            $http({
                method:'get',
                url:config.api.isSetTradepw
            }).then(res=>{
                this.isSet = res.result.isSet;
                if(this.isSet == 0){
                    Dialog.confirm({
                        title: '提示',
                        message: '您还没有设置交易密码,请前往设置',
                    })
                    .then(() => {
                        this.$router.push({
                            name:'codelock'
                        });
                    })
                    .catch(() => {
                        this.$router.go(-1);
                    });
                    
                }
            })
        },
        jumpPage(name){
            this.$router.push({
                name
            });
        },
        changeType(val){
            this.type = val;
        }
    }
}
</script>
<style lang="scss" scoped>
::v-deep .van-password-input{
    margin:0;
    .van-password-input__security{
        display: flex;
        justify-content: space-between;
        align-items: center;
        li{
            width: 84px;
            height: 82px;
            border: 1px solid #707070;
            flex: none;
            background-color: transparent;
            box-sizing: border-box;
        }
        i{
            background-color: #fff;
        }
    }
}
::v-deep .van-hairline--surround::after{
    border-width: 0;
}
::v-deep [class*='van-hairline']::after{
    border:none;
}
.page-container{
    padding:30px;
    .title{
        margin-bottom: 20px;
        font-size: 32px;
        font-family: Source Han Sans CN-Bold, Source Han Sans CN;
        font-weight: bold;
        color: #FFFFFF;

    }
    .order-list{
        margin-bottom: 10px;
        .order-item{
            background-color: #2A2B2C;
            border-radius: 16px;
            padding:30px 26px;
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            margin-bottom: 20px;
            .user-pic{
                width:150px;
                height:150px;
                display: block;
                margin-right: 30px;
                flex-shrink: 0;
                border-radius: 8px;
                overflow: hidden;
                flex-shrink: 0;
            }
            .right-block{
                width:100%;
                display: flex;
                height:150px;
                justify-content: space-between;
                align-items: flex-start;
                flex-direction: column;
                .name-status{
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    width:100%;
                    .name{
                        font-size: 30px;
                        font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                        font-weight: 400;
                        color: #FFFFFF;
                        width:100%;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                    }
                }
                .user-name{
                    font-size: 24px;
                    font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                    font-weight: 400;
                    color: #666666;
                }
                .price{
                    font-size: 24px;
                    font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                    font-weight: 400;
                    color: #FFFFFF;
                }
            }
        }
    }
    .password-box{
        padding:20px 10px;
        background: #2A2B2C;
        border-radius: 16px 16px 16px 16px;
        margin-bottom: 10px;
        .title{
            margin-bottom: 38px;
            font-size: 26px;
            font-family: Source Han Sans CN-Regular, Source Han Sans CN;
            font-weight: 400;
            color: #FFFFFF;
            text-align: center;
        }
        .forget-pass{
            font-size: 26px;
            font-family: Source Han Sans CN-Regular, Source Han Sans CN;
            font-weight: 400;
            color: #999999;
            text-align: right;
            margin-top: 25px;
        }
    }
    .pay-way{
        background: #2A2B2C;
        border-radius: 16px 16px 16px 16px;
        .title{
            display: flex;
            justify-content: space-between;
            align-items: center;
            height:87px;
            padding:0 30px;
            border-bottom: 1px solid #707070;
            div{
                font-size: 26px;
                font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                font-weight: 400;
                &:first-child{
                    color: #FFFFFF;
                }
                &:last-child{
                    color:#52C41A;
                }
            }
        }
        .way-list{
            padding:20px 30px;
            .way-item{
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 30px;
                .waybox{
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    &>img{
                        width:64px;
                        margin-right: 5px;
                    }
                    &>div{
                        font-size: 26px;
                        font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                        font-weight: 400;
                        color: #FFFFFF;
                    }
                }
                &>img{
                    width:36px;
                }
            }
        }
    }
    .btn-grp{
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 200px;
        .btn-item{
            width:334px;
            height:79px;
            line-height: 79px;
            text-align: center;
            background-color: #333333;
            border-radius: 40px;
            font-size: 30px;
            font-family: Source Han Sans CN-Regular, Source Han Sans CN;
            font-weight: 400;
            color: #999999;
            &.light{
                background: linear-gradient(90deg, #0C81F5 0%, #40A9FF 100%);
                color:#fff;
            }
        }
    }
}
</style>
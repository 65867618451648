<template>
    <div class="page-container">
        <vue-loadmore 
            :on-loadmore="loadMore"
            :finished="finished"
            load-offset="100">
            <div class="collect-list" v-if="collectList.length > 0">
                <div class="collect-item" v-for="item in collectList" :key="item.id" @click="changeItem(item,item.id)">
                    <van-image
                        class="goods-pic"
                        fit="cover"
                        :src="imgBaseUrl + item.cover"
                    />
                    <div class="goods-info">
                        <div class="authinfo">
                            <van-image
                                class="user-pic"
                                fit="cover"
                                :src="imgBaseUrl + item.avatar"
                            />
                            <div>{{item.owner}}</div>
                        </div>
                        <div class="goods-name">{{item.name}}</div>
                    </div>
                    <div class="goods-id">{{item.tokenId}} # {{item.number}}</div>
                </div>
            </div>
            <empty v-else />
        </vue-loadmore>
        
    </div>
</template>

<script>
import $http from '@/utils/request'
import config from '@/config'
import {setItem} from '@/utils/user'

export default {
    data(){
        return {
            current:1,
            pageSize:10,
            collectList:[],
            finished:false,
            total:0,
        }
    },
    created(){
        this.getData();
    },
    computed:{
        maxPage(){
            return Math.ceil(this.total / this.pageSize);
        }
    },
    methods:{
        changeItem(item,id){
            setItem('currentCollect',JSON.stringify(item));
            this.$router.push({
                name:'collectdetail',
                params:{
                    id
                },
                query:{
                    type:'airdrop'
                }
            });
        },
        loadMore(done){
            if(this.current < this.maxPage){
                this.current=this.current + 1;
                this.getData();
            }else{
                this.finished = true;
            }
            done();
        },
        getData(){
            $http({
                method:'get',
                url:config.api.airdrop,
                params:{
                    current:this.current,
                    pageSize:this.pageSize
                }
            }).then(res=>{
                this.collectList = this.collectList.concat(res.result.records);
                this.total = res.result.total;
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.page-container{
    padding:30px;
    .collect-list{
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        flex-wrap: wrap;
        .collect-item{
            border-radius: 16px;
            background-color: #2A2B2C;
            width:330px;
            margin-bottom: 30px;
            .goods-pic{
                width:100%;
                height:278px;
                border-radius: 16px;
                overflow: hidden;
            }
            .goods-info{
                padding:20px;
                .authinfo{
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    margin-bottom: 20px;
                    .user-pic{
                        width:64px;
                        height:64px;
                        border-radius: 50%;
                        overflow: hidden;
                        margin-right: 10px;
                        flex-shrink: 0;
                    }
                    div{
                        width:100%;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        font-size: 24px;
                        font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                        font-weight: 400;
                        color: #FFFFFF;
                    }
                }
                .goods-name{
                    font-size: 24px;
                    font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                    font-weight: 400;
                    color: #FFFFFF;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }
            }
            .goods-id{
                border-top: 1px solid #707070;
                height:64px;
                line-height: 64px;
                padding:0 20px;
                font-size: 24px;
                font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                font-weight: 400;
                color: #999999;
            }
        }
    }
}
</style>
<template>
    <div class="page-container">
        <vue-loadmore 
            :on-loadmore="loadMore"
            :finished="finished"
            load-offset="100">
            <div class="goods-list" v-if="collectList.length > 0">
                <div class="goods-item" v-for="(item,index) in collectList" :key="item.id" @click="jumpPage('goodsdetail',{id:item.id,type:'goods'})">
                    <div class="img-box">
                        <van-image
                            class="img-box-bg"
                            fit="cover"
                            :src="imgBaseUrl + item.cover"
                        />
                        <div class="img-box-wrapper">
                            <img :src="imgBaseUrl + item.cover" alt=""/>
                        </div>
                    </div>
                    <div class="goods-info">
                        <div class="author-line">
                            <div class="author-info">
                                <img :src="imgBaseUrl + item.avatar" alt="">
                                <span>{{item.owner}}</span>
                            </div>
                            <div class="status-box">
                                <!-- 状态 1：已上线  2：定时上线 3：售罄  5:预约 -->
                                <template v-if="item.status == 1">
                                    <img src="@/assets/icon-hot.png" alt="">
                                    <span>热销中</span>
                                </template>
                                
                                <div v-if="item.status == 3" class="sale-out">已售罄</div>
                            </div>
                        </div>
                        <div class="goods-name">{{item.name}}</div>
                        <div class="price-line">
                            <div class="price-box">
                                <span>当前售价</span>
                                <span>¥ {{item.price}}</span>
                            </div>
                            <div class="fav-box">
                                <span>{{item.likeNum}}</span>
                                <img src="@/assets/icon-fav.png" @click.stop="unstar(item.id,1,index)" alt="">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <empty v-else />
        </vue-loadmore>
    </div>
</template>

<script>
import $http from '@/utils/request'
import config from '@/config'

export default {
    data(){
        return {
            current:1,
            pageSize:10,
            collectList:[],
            finished:false,
            total:0,
        }
    },
    created(){
        this.getData();
    },
    computed:{
        maxPage(){
            return Math.ceil(this.total / this.pageSize);
        }
    },
    methods:{
        unstar(id,type,index){
            $http({
                method:'get',
                url:config.api.unFollow,
                params:{
                    id,
                    type
                }
            }).then(res=>{
                this.$toast("操作成功");
                this.collectList.splice(index,1);
            })
        },
        jumpPage(name,params){
            this.$router.push({
                name,
                params
            });
        },
        loadMore(done){
            if(this.current < this.maxPage){
                this.current=this.current + 1;
                this.getData();
            }else{
                this.finished = true;
            }
            done();
        },
        getData(){
            $http({
                method:'get',
                url:config.api.myLike,
                params:{
                    current:this.current,
                    pageSize:this.pageSize
                }
            }).then(res=>{
                this.collectList = this.collectList.concat(res.result.records);
                this.total = res.result.total;
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.page-container{
    padding:30px;
    .goods-list{
        .goods-item{
            background-color: #080607;
            border-radius: 16px;
            overflow: hidden;
            margin-bottom: 30px;
            .img-box{
                position: relative;
                height:542px;
                border-radius: 16px;
                overflow: hidden;
                .img-box-bg{
                    position: absolute;
                    left:0;
                    right:0;
                    top:0;
                    bottom:0;
                    // background: url('../assets/tmp-cover.png');
                    // background-size: cover;
                    opacity: 0.5;
                    z-index: 1;
                }
                .img-box-wrapper{
                    width:100%;
                    height:542px;
                    padding:26px;
                    box-sizing: border-box;
                    position: absolute;
                    top:0;
                    left:0;
                    z-index: 2;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    img{
                        display: block;
                        max-height: 100%;
                    }
                }
            }
            .goods-info{
                padding:30px;
                padding-bottom: 0;
                .author-line{
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    margin-bottom: 28px;
                    .author-info{
                        display: flex;
                        justify-content: start;
                        align-items: center;
                        img{
                            margin-right: 30px;
                            width:64px;
                            border-radius: 50%;
                        }
                        span{
                            font-size: 22px;
                            font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                            font-weight: 400;
                            color: #999999;
                        }
                    }
                    .status-box{
                        display: flex;
                        justify-content: end;
                        align-items: center;
                        img{
                            margin-right: 20px;
                            width:28px;
                        }
                        span{
                            font-size: 22px;
                            font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                            font-weight: 400;
                            color: #999999;
                        }
                    }
                    .sale-out{
                        width: 92px;
                        height: 40px;
                        line-height: 40px;
                        text-align: center;
                        background: #999999;
                        font-size: 22px;
                        font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                        font-weight: 400;
                        color: #FFFFFF;
                    }
                }
                .goods-name{
                    font-size: 30px;
                    font-family: Source Han Sans CN-Medium, Source Han Sans CN;
                    font-weight: 500;
                    color: #FFFFFF;
                    padding-bottom: 24px;
                    border-bottom: 1px solid #707070;
                }
                .price-line{
                    padding:18px 0;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    .price-box{
                        display: flex;
                        justify-content: start;
                        align-items: center;
                        span{
                            &:first-child{
                                font-size: 22px;
                                font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                                font-weight: 400;
                                color: #999999;
                                margin-right: 20px;
                            }
                            &:last-child{
                                font-size: 30px;
                                font-family: Source Han Sans CN-Medium, Source Han Sans CN;
                                font-weight: 500;
                                color: #FFFFFF;
                            }
                        }
                    }
                    .fav-box{
                        display: flex;
                        justify-content: end;
                        align-items: center;
                        span{
                            margin-right: 10px;
                            font-size: 22px;
                            font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                            font-weight: 400;
                            color: #999999;
                        }
                        img{
                            width:48px;
                        }
                    }
                }
            }
        }
    }
}
</style>
<template>
  <div id="app">
    <!-- 导航栏 -->
    <div class="top-nav">
      <img src="@/assets/icon-nav-arrow.png" class="nav-left-arrow" v-if="showBackArrow" @click="goBack" alt=""/>
      <img src="@/assets/icon-nav-home.png" class="go-index" alt="" @click="jumpPage('index')" v-else>
      <div class="title-wrapper">{{pageTitle}}</div>
      <img src="@/assets/goodsdetail/icon-share.png" alt="" v-if="routeName == 'goodsdetail'" @click="makeShare" class="nav-right-icon">
    </div>

    <div id="router-wrapper" class="hasTop" :class="{hasBottom:bottomNavShow}">
      <keep-alive include="artdetail,goodsdetail,exchangepage">
        <router-view></router-view>
      </keep-alive>
    </div>

    <!-- 底部导航 -->
    <div class="bottom-nav" v-if="bottomNavShow">
      <div class="nav-item" @click="jumpPage('index')">
        <img v-if="routeName == 'index'" src="@/assets/icon-home-light.png" />
        <img v-else src="@/assets/icon-home.png">
        <div :class="{active:routeName == 'index'}">首页</div>
      </div>
      <div class="nav-item" @click="jumpPage('artist')">
        <img v-if="routeName == 'artist'" src="@/assets/icon-tsp-light.png" />
        <img v-else src="@/assets/icon-tsp.png">
        <div :class="{active:routeName == 'artist'}">艺术家</div>
      </div>
      <div class="nav-item" @click="jumpPage('mine')">
        <img v-if="routeName == 'mine'" src="@/assets/icon-mine-light.png" />
        <img v-else src="@/assets/icon-mine.png">
        <div :class="{active:routeName == 'mine'}">我的</div>
      </div>
    </div>

    <!-- 建议浏览器打开弹层 -->
    <div class="browser-layer" v-if="isWeiXin">
      <img src="@/assets/icon-browser-arrow.png" alt="">
      <div>
        <p>建议点击右上角...</p>
        <p>选择在浏览器中打开</p>
      </div>
    </div>

    <!-- 分享弹层 -->
    <shareLayer v-if="showShare" from="goodsdetail" :id="pageData.id" type="goods" :name="pageData.name" :cover="pageData.cover" @closeShare="showShare = false"/>
  </div>
</template>

<script>
import shareLayer from '@/components/shareLayer'
import $http from '@/utils/request'
import config from '@/config'
import { isWeiXin } from '@/utils/tools'

export default {
  name: 'App',
  data(){
    return {
      hasBottomNav:['index','artist','mine','login','calendar'],
      showShare:false,
      pageData:{}
    }
  },
  computed:{
    bottomNavShow(){
      return this.hasBottomNav.includes(this.$route.name)
    },
    routeName(){
      return this.$route.name;
    },
    pageTitle(){
      if(this.$route.name == 'login'){
        let name;
        switch (this.$route.query.action){
          case 'login':
            name = '登录';
            break;
          case 'register':
            name = '注册';
            break;
          case 'forgetpass':
            name = '忘记密码';
            break;
        }
        return name;
      }else{
        return this.$route.meta.title;
      }
    },
    showBackArrow(){
      return window.history.length > 1 ? true : false
    },
    isWeiXin(){
      return isWeiXin();
    }
  },
  components:{
    shareLayer
  },
  methods:{
    jumpPage(name){
      this.$router.push({
        name
      });
    },
    goBack(){
      this.$router.go(-1);
    },
    makeShare(){
      if(this.$route.params.type == 'goods'){ //藏品详情
          $http({
              method:'get',
              url:config.api.goodsDetail,
              params:{workId:this.$route.params.id}
          }).then(res=>{
              this.pageData = res.result;
              this.showShare = true;
          })
      }else{ //盲盒详情
          $http({
              method:'get',
              url:config.api.boxDetail,
              params:{boxId:this.$route.params.id}
          }).then(res=>{
              this.pageData = res.result;
              this.showShare = true;
          })
      }
    }
  }
}
</script>

<style lang="scss">
.top-nav{
  height:94px;
  line-height: 94px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding:0 30px;
  z-index: 99;
  width:100%;
  position: fixed;
  top:0;
  left:0;
  box-sizing: border-box;
  background-color: #1F1F1F;
  box-shadow: 0px 3px 6px 1px rgba(0,0,0,0.1600);
  .nav-left-arrow{
    width:48px;
    flex-shrink: 0;
  }
  .go-index{
    width:50px;
    flex-shrink: 0;
  }
  .nav-right-icon{
    width:48px;
    flex-shrink: 0;
  }
  .title-wrapper{
    text-align: center;
    width:100%;
    font-size: 36px;
    font-family: Source Han Sans CN-Bold, Source Han Sans CN;
    font-weight: bold;
    color: #FFFFFF;
  }
}
#router-wrapper{
  box-sizing: border-box;
  min-height: 100vh;
  background-color: #1F1F1F;
  &.hasTop{
    padding-top: 94px;
  }
  &.hasBottom{
    padding-bottom: 98px;
  }
}
.bottom-nav{
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  left: 0;
  bottom:0;
  z-index: 99;
  width:100%;
  background-color: #1F1F1F;
  height:98px;
  .nav-item{
    width:33%;
    img{
      display: block;
      width:48px;
      margin:0 auto;
      margin-bottom: 8px;
    }
    div{
      font-size: 24px;
      font-family: Source Han Sans CN-Regular, Source Han Sans CN;
      font-weight: 400;
      color: #FFFFFF;
      line-height: 22px;
      text-align: center;
    }
    .active{
      color:#1296DB;
    }
  }
}
.browser-layer{
  position: fixed;
  z-index: 100;
  width:100vw;
  height:100vh;
  top:0;
  left:0;
  background:rgba(0,0,0,0.6);
  img{
    position: absolute;
    top:5px;
    right:72px;
    width:216px;
  }
  div{
    position: absolute;
    right:300px;
    top:190px;
    p{
      font-size: 30px;
      font-family: Source Han Sans CN-Medium, Source Han Sans CN;
      font-weight: 500;
      color: #FFFFFF;
    }
  }
}
</style>

<template>
    <div class="page-container">
        <div class="imgbox">
            <img :src="imgBaseUrl + pageData.cover" alt="">
        </div>
        <div class="page-bottom">
            <div class="goods-info-box">
                <div class="name">{{pageData.name}}</div>
                <div class="idline">
                    <div>唯一标识</div>
                    <div>{{pageData.tokenId}}#{{pageData.number}}</div>
                </div>
            </div>
            <div class="section-block">
                <div class="infoline">
                    <div>收藏者</div>
                    <div>{{pageData.purchasedName}}</div>
                </div>
                <div class="infoline">
                    <div>生成时间</div>
                    <div>{{pageData.launchTime}}</div>
                </div>
                <div class="infoline">
                    <div>艺术家</div>
                    <div>{{pageData.author}}</div>
                </div>
                <div class="infoline">
                    <div>藏品哈希值</div>
                    <div>
                        <span>{{pageData.hash}}</span>
                        <img src="@/assets/goodsdetail/icon-copy.png" alt="" v-clipboard:copy="pageData.hash" v-clipboard:success="copySucc" v-clipboard:error="copyFail" class="btn-copy">
                    </div>
                </div>
            </div>
            <div class="section-block">
                <div class="block-title">
                    <img src="@/assets/goodsdetail/icon-section-title.png" alt="">
                    <div class="title">链上信息</div>
                </div>
                <div class="chain-info">
                    <div class="info-item">
                        <div class="title">合约地址</div>
                        <div class="content">
                            <span>{{pageData.contractAddress}}</span>
                            <img src="@/assets/goodsdetail/icon-copy.png" v-clipboard:copy="pageData.contractAddress" v-clipboard:success="copySucc" v-clipboard:error="copyFail" alt="" class="btn-copy">
                        </div>
                    </div>
                    <div class="info-item">
                        <div class="title">合约类型</div>
                        <div class="content">ERC-1155</div>
                    </div>
                    <div class="info-item">
                        <div class="title">区块链</div>
                        <div class="content">{{pageData.chainName}}</div>
                    </div>
                </div>
            </div>
            <div class="section-block">
                <div class="tabs">
                    <div class="tab-item" :class="{active:currentTab == 'desc'}" @click="changeTab('desc')">藏品描述</div>
                    <div class="tab-item" :class="{active:currentTab == 'record'}" @click="changeTab('record')">流转中心</div>
                </div>
                <!-- 藏品描述 -->
                <div class="goods-desc" v-html="pageData.description" v-show="currentTab == 'desc'">
                    
                </div>
                <!-- 购买记录 -->
                <div class="record-list" v-show="currentTab == 'record'">
                    <div class="record-item title-item">
                        <div>用户</div>
                        <div>购买时间</div>
                        <div>费用</div>
                        <div>交易哈希</div>
                    </div>
                    <div class="record-item" v-for="item in recordList" :key="item.id">
                        <div>{{item.userName}}</div>
                        <div>{{item.date | getDate}} <br> {{item.date | getTime}}</div>
                        <div>¥{{item.price}}</div>
                        <div>{{item.hash}}</div>
                    </div>
                    <empty v-if="recordList.length == 0" />
                    <div class="record-item line-more" v-show="total > recordList.length" @click="loadAllRecord">
                        <span>展开更多</span>
                        <img src="../../assets/goodsdetail/icon-line-more.png" alt="">
                    </div>
                </div>
            </div>
        </div>


        <!-- 底部悬浮条 -->
        <div class="footer-line">
            <div class="footer-item" @click="jumpPage('flaunt',{id:gid,type:pageType})">
                <img src="@/assets/mine/icon-xy.png" alt="">
                <div>炫耀</div>
            </div>
            <div class="footer-item" @click="jumpPage('certificate',{id:gid,type:pageType})">
                <img src="@/assets/mine/icon-zs.png" alt="">
                <div>证书</div>
            </div>
            <div class="footer-item" @click="makeTransfer">
                <img src="@/assets/mine/icon-zz.png" alt="">
                <div>转赠</div>
            </div>
        </div>

        <!-- 支付弹层 -->
        <payLayer v-if="false" />
    </div>
</template>

<script>
import $http from '@/utils/request'
import config from '@/config'
import {setItem} from '@/utils/user'
import payLayer from '@/components/payLayer'
import dayjs from 'dayjs'

export default {
    data(){
        return {
            pageData:{},
            currentTab:'desc',
            current:1,
            pageSize:10,
            total:0,
            recordList:[],
        }
    },
    filters:{
        getDate(val){
            if(!val){
                return ''
            }else{
                return dayjs(val).format("YYYY-MM-DD");
            }
        },
        getTime(val){
            if(!val){
                return ''
            }else{
                return dayjs(val).format("HH:mm:ss");
            }
        }
    },
    computed:{
        gid(){
            return this.$route.params.id;
        },
        pageType(){
            return this.$route.query.type;
        }
    },
    components:{
        payLayer
    },
    created(){
        // this.pageData = JSON.parse(getItem('currentCollect'));
        this.getData();
        this.getRecord();
    },
    methods:{
        copySucc(){
            this.$toast('复制成功');
        },
        copyFail(){
            this.$toast('复制失败');
        },
        makeTransfer(){
            if(this.pageData.isTranfer != 1){
                this.$toast("该藏品暂不支持转赠");
                return;
            }

            setItem('currentTrans',JSON.stringify(this.pageData));
            this.$router.push({
                name:'transfer-search',
                query:{
                    id:this.gid,
                    type:this.pageType
                }
            });
            
        },
        jumpPage(name,data){
            this.$router.push({
                name,
                params:data
            });
        },
        getData(){
            if(this.pageType == 'collect'){
                $http({
                    method:'get',
                    url:config.api.collectDetail,
                    params:{
                        txId:this.gid,
                    }
                }).then(res=>{
                    this.pageData = res.result;
                })
            }else if(this.pageType == 'airdrop'){
                $http({
                    method:'get',
                    url:config.api.airdropDetail,
                    params:{
                        airUserId:this.gid,
                    }
                }).then(res=>{
                    this.pageData = res.result;
                })
            }
            
        },
        getRecord(){
            $http({
                method:'get',
                url:config.api.buyRecord,
                params:{
                    workId:this.gid,
                    current:this.current,
                    pageSize:this.pageSize
                }
            }).then(res=>{
                this.recordList = res.result.records;
                this.total = res.result.total;
            })
        },
        changeTab(val){
            this.currentTab = val;
        },
        loadAllRecord(){
            $http({
                method:'get',
                url:config.api.buyRecord,
                params:{
                    workId:this.gid,
                    current:this.current,
                    pageSize:100
                }
            }).then(res=>{
                this.recordList = res.result.records;
                this.total = res.result.total;
            })
        },
    }
}
</script>

<style lang="scss">
    .goods-desc{
        img{
            width:100%;
        }
    }
</style>
<style lang="scss" scoped>
.page-container{
    padding-bottom: 100px;
    .imgbox{
        height:1061px;
        background:url('../../assets/goodsdetail/imgbox-bg.png') no-repeat center center;
        background-size: 100% 100%;
        position: relative;
        &>img{
            position: absolute;
            left:50%;
            transform: translateX(-50%);
            bottom:296px;
            max-width: 500px;
            max-height: 618px;
        }
    }
    .page-bottom{
        position: relative;
        top:-50px;
        background-color: #1F1F1F;
        padding:30px;
        .section-block{
            padding:30px;
            background-color: #000;
            border-radius: 20px;
            margin-bottom: 10px;
        }
        .infoline{
            margin-bottom: 48px;
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            div{
                font-size: 26px;
                font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                font-weight: 400;
                &:first-child{
                    color:#999;
                    width:150px;
                    flex-shrink: 0;
                }
                &:last-child{
                    width:100%;
                    color:#fff;
                    word-break: break-all;
                    text-align: right;
                    .btn-copy{
                        margin-left: 16px;
                        width:32px;
                    }
                }
            }
            &:last-child{
                margin-bottom: 0;
            }
        }
        .goods-info-box{
            box-sizing: border-box;
            margin-bottom: 20px;
            width:690px;
            height:165px;
            padding-top: 30px;
            background: url('../../assets/mine/collect-info-bg.png') no-repeat center center;
            background-size: 100% 100%;
            .name{
                font-size: 28px;
                font-family: Source Han Sans CN-Bold, Source Han Sans CN;
                font-weight: bold;
                color: #FFFFFF;
                text-align: center;
                margin-bottom: 20px;

            }
            .idline{
                display: flex;
                justify-content: center;
                align-items: center;
                div{
                    &:first-child{
                        width:119px;
                        height:46px;
                        line-height: 46px;
                        text-align: center;
                        background: linear-gradient(90deg, #89D1F9 0%, #A6A0F5 100%);
                        color:#fff;
                        font-size: 24px;
                        font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                        font-weight: 400;
                        border-radius: 8px;
                        margin-right: 20px;
                    }
                    &:last-child{
                        font-size: 24px;
                        font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                        font-weight: 400;
                        color: #FFFFFF;
                    }
                }
            }
            
        }
        .block-title{
            display: flex;
            justify-content: flex-start;
            align-items: center;
            margin-bottom: 30px;
            img{
                width:8px;
                margin-right: 10px;
            }
            .title{
                font-size: 26px;
                font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                font-weight: 400;
                color: #999999;
            }
        }

        .chain-info{
            .info-item{
                margin-bottom: 20px;
                .title{
                    font-size: 24px;
                    font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                    font-weight: 400;
                    color: #999999;
                    margin-bottom: 20px;
                }
                .content{
                    font-size: 24px;
                    font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                    font-weight: 400;
                    color: #FFFFFF;
                    .btn-copy{
                        float:right;
                        width:32px;
                    }
                }
            }
        }
        .tabs{
            display: flex;
            justify-content: flex-start;
            align-items: center;
            margin-bottom: 37px;
            .tab-item{
                margin-right: 30px;
                position: relative;
                font-size: 26px;
                font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                font-weight: 400;
                color: #999999;
                &.active{
                    color:#fff;
                    &::after{
                        content: '';
                        width:104px;
                        height:7px;
                        background:url('../../assets/goodsdetail/icon-tab-active.png') no-repeat center center;
                        background-size: 100% 100%;
                        position: absolute;
                        left:0;
                        bottom:-17px;
                    }
                }
            }
        }
        .record-list{
            .record-item{
                padding:0 30px;
                border-bottom: 1px solid #707070;
                height:62px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                font-size: 20px;
                font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                font-weight: 400;
                color: #999999;
                &.title-item{
                    height:84px;
                    border-top: 1px solid #707070;
                }
                &.line-more{
                    justify-content: center;
                    border-bottom: none;
                    img{
                        width:32px;
                        margin-left: 5px;
                    }
                    span{
                        font-size: 24px;
                        font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                        font-weight: 400;
                        color: #40A9FF;
                    }
                }
                div{
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    &:first-child{
                        width: 120px;
                    }
                    &:nth-child(2){
                        width: 166px;
                    }
                    &:nth-child(3){
                        width: 140px;
                    }
                    &:last-child{
                        width: 220px;
                    }
                }
            }
        }
    }
    .footer-line{
        position: fixed;
        width:100%;
        height:100px;
        left:0;
        bottom:0;
        background-color: #282828;
        box-sizing: border-box;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .footer-item{
            width:32%;
            display: flex;
            justify-content: center;
            align-items: center;
            padding:7px 0;
            &:nth-child(2){
                border-left: 1px solid #707070;
                border-right: 1px solid #707070;
            }
            img{
                width:32px;
                margin-right: 5px;
            }
            div{
                font-size: 30px;
                font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                font-weight: 400;
                color: #FFFFFF;
            }
        }
    }
}
</style>
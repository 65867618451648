<template>
    <div class="page-container">
        <div class="page-top">
            <div class="top-title">转赠</div>
            <div class="top-sub-title">
                通过手机号检索用户信息，<span>确定赠送后该数字藏品将会被转移</span>
            </div>
            <div class="search-line">
                <div class="search-box">
                    <img src="@/assets/icon-search.png" alt="">
                    <input type="text" v-model="phone" placeholder="请输入手机号" />
                </div>
                <div class="btn-search" @click="goSearch">搜索</div>
            </div>
        </div>
        <div class="result-list" v-if="resultList.length > 0">
            <div class="result-item" v-for="item in resultList" :key="item.id" @click="makeTrans(item.id)">
                <div class="info-box">
                    <van-image
                        class="user-pic"
                        fit="cover"
                        :src="imgBaseUrl + item.avatar"
                    />
                    <div class="userinfo">
                        <div>{{item.username}}</div>
                        <div>{{item.phone}}</div>
                    </div>
                </div>
                <div class="btn-trans">转赠</div>
            </div>
        </div>
    </div>
</template>

<script>
import $http from '@/utils/request'
import config from '@/config'

export default {
    data(){
        return {
            phone:null,
            resultList:[],
        }
    },
    methods:{
        goSearch(){
            $http({
                method:'get',
                url:config.api.getUserByPhone,
                params:{
                    phone:this.phone,
                }
            }).then(res=>{
                this.resultList = res.result;
            })
        },
        makeTrans(id){
            this.$router.replace({
                name:'transfer',
                query:{
                    userid:id,
                    id:this.$route.query.id,
                    type:this.$route.query.type
                }
            });
        }
    }
}
</script>

<style lang="scss" scoped>
.page-container{
    .page-top{
        background-color: #000;
        padding:30px;
        padding-bottom: 52px;
        .top-title{
            font-size: 32px;
            font-family: Source Han Sans CN-Bold, Source Han Sans CN;
            font-weight: bold;
            color: #FFFFFF;
            margin-bottom: 30px;
        }
        .top-sub-title{
            font-size: 24px;
            font-family: Source Han Sans CN-Bold, Source Han Sans CN;
            font-weight: bold;
            color:#999;
            margin-bottom: 30px;
            span{
                color: #0C81F5;
            }
        }
        .search-line{
            display: flex;
            justify-content: space-between;
            align-items: center;
            .search-box{
                width:100%;
                box-sizing: border-box;
                padding:10px 30px;
                background: #323232;
                border-radius: 34px 34px 34px 34px;
                border: 1px solid #FFFFFF;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                img{
                    width:48px;
                    flex-shrink: 0;
                    margin-right: 5px;
                }
                input{
                    width:100%;
                }
            }
            .btn-search{
                flex-shrink: 0;
                margin-left: 36px;
                font-size: 30px;
                font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                font-weight: 400;
                line-height: 68px;
                color: #FFFFFF;
            }
        }
    }
    .result-list{
        position: relative;
        top:-20px;
        padding:30px;
        padding-top: 0;
        .result-item{
            background: #272727;
            border-radius: 20px 20px 20px 20px;
            padding:24px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 10px;
            .info-box{
                display: flex;
                justify-content: flex-start;
                align-items: center;
                .user-pic{
                    width:64px;
                    height:64px;
                    margin-right: 10px;
                    border-radius: 50%;
                    overflow: hidden;
                }
                .userinfo{
                    &>div{
                        &:first-child{
                            font-size: 30px;
                            font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                            font-weight: 400;
                            color: #FFFFFF;
                            margin-bottom: 5px;
                        }
                        &:last-child{
                            font-size: 24px;
                            font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                            font-weight: 400;
                            color: #FFFFFF;
                        }
                    }
                }
            }
            .btn-trans{
                font-size: 24px;
                font-family: Source Han Sans CN-Bold, Source Han Sans CN;
                font-weight: bold;
                color: #0C81F5;
            }
        }
    }
}
</style>
<template>
    <div class="btn-grp">
        <div class="count-down" v-if="isSending">{{seconds}}s后重发</div>
        <div @click="getCode" v-else>获取验证码</div>
    </div>
</template>

<script>
import $http from '@/utils/request'
import config from '@/config'

export default {
    data(){
        return {
            isSending:false,
            seconds:60,
            intervalId:null,
        }
    },
    watch:{
        '$route.query':function(){
            this.stopCountdown();
        },
        '$route.name':function(){
            this.stopCountdown();
        }
    },
    props:['phone','type'],
    methods:{
        getCode(){
            $http({
                method:'post',
                url:config.api.getCode,
                data:{
                    phone:this.phone,
                    type:this.type,
                }
            }).then(res=>{
                this.$toast('验证码发送成功');
                this.isSending = true;
                this.startCountdown();
            })
        },
        startCountdown(){
            this.intervalId = setInterval(()=>{
                if(this.seconds > 0){
                    this.seconds = this.seconds - 1;
                }else{
                    this.stopCountdown();
                }
            },1000);
        },
        stopCountdown(){
            if(this.intervalId){
                clearInterval(this.intervalId);
            }
            this.isSending = false;
            this.seconds = 60;
        },
    }
}
</script>

<style lang="scss" scoped>
.btn-grp{
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    &>img{
        width:32px;
        height:32px;
        margin-right: 30px;
    }
    &>div{
        font-size: 24px;
        font-family: Source Han Sans CN-Medium, Source Han Sans CN;
        font-weight: 500;
        color: #0C81F5;
    }
    .count-down{
        color:#999;
    }
}
</style>
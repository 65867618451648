<template>
    <div class="cer-mask" @click.self="closeLayer">
        <div class="card-box" ref="imageWrapper">
            <img src="@/assets/school/certificate-bg.png" alt="" class="bg">
            <div class="work-name">{{cerData.name}}</div>
            <div class="auth-name">{{cerData.owner}}</div>
            <div class="chain-time">{{cerData.launchTime}}</div>
            <div class="contract-id">{{cerData.hash}}</div>
            <div class="code">{{cerData.address}}</div>
            <div class="single-code">{{cerData.tokenId}}</div>

            <div class="btn-submit" @click="onExport">下载证书</div>
        </div>
        
    </div>
</template>

<script>
import html2canvas from 'html2canvas'

export default {
    props:['cerData'],
    methods:{
        closeLayer(){
            this.$emit("closeZs");
        },
        onExport() {
            const clientWidth = this.$refs.imageWrapper.offsetWidth * 3;
            const clientHeight = this.$refs.imageWrapper.offsetHeight * 3;
            const kh = [clientWidth, clientHeight];

            html2canvas(this.$refs.imageWrapper, { useCORS: true, logging: true }).then((canvas) => {
                const dataURL = canvas.toDataURL('image/png');
                this.download(dataURL, kh);
            });
        },
        getUrlBase64(url, kh) {
            return new Promise((resolve) => {
                let canvas = document.createElement('canvas');
                const ctx = canvas.getContext('2d');
                const img = new Image();
                img.crossOrigin = 'Anonymous'; // 允许跨域
                img.src = url;
                img.onload = () => {
                    // eslint-disable-next-line prefer-destructuring
                    canvas.height = kh[1];
                    // eslint-disable-next-line prefer-destructuring
                    canvas.width = kh[0];
                    ctx.drawImage(img, 0, 0, kh[0], kh[1]);
                    const dataURL = canvas.toDataURL('image/png');
                    canvas = null;
                    resolve(dataURL);
                };
            });
        },
        download(imgUrl, kh) {
            this.getUrlBase64(imgUrl, kh).then((base64) => {
                const link = document.createElement('a');
                link.href = base64;
                link.download = `作品证书.png`;
                link.click();
            });
        },
    }
}
</script>

<style lang="scss" scoped>
.cer-mask{
    position: fixed;
    left:0;
    top:0;
    background-color: rgba(0,0,0,0.7);
    width:100vw;
    height:100vh;
    z-index: 98;
    .card-box{
        width:663px;
        height:937px;
        // background: url("../assets/school/certificate-bg.png") no-repeat center center;
        // background-size: 100% 100%;
        position: absolute;
        top:50%;
        left:50%;
        transform: translate(-50%,-50%);
        .bg{
            width:100%;
            height:100%;
            position: absolute;
            top:0;
            left:0;
        }
        &>div{
            position: absolute;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            font-size: 24px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color:#1296DB;
            &.work-name,&.auth-name{
                width:220px;
                top:286px;
                left:130px;
                text-align: center;
            }
            &.auth-name{
                left:350px;
            }
            &.chain-time{
                top:366px;
                width:400px;
                left:130px;
                text-align: center;
            }
            &.contract-id{
                top:418px;
                width:400px;
                left:130px;
                text-align: center;
            }
            &.code{
                top:474px;
                width:400px;
                left:130px;
                text-align: center;
            }
            &.single-code{
                top:530px;
                width:400px;
                left:130px;
                text-align: center;
            }
        }
        .btn-submit{
            position: absolute;
            bottom:-100px;
            left:50%;
            transform: translateX(-50%);
            width: 416px;
            height: 60px;
            line-height: 60px;
            text-align: center;
            border-radius: 10px;
            font-size: 32px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            background-color: #1296DB;
            color:#fff;
        }
    }
    
}
</style>
<template>
  <div class="page-container">
    <vue-loadmore 
    :on-loadmore="loadMore"
    :finished="finished"
    load-offset="100">
        <div class="work-list">
            <div class="work-item" v-for="(item,index) in workList" :key="index">
                <div class="pic-box">
                    <van-image
                        class="work-img"
                        fit="cover"
                        :src="imgBaseUrl + item.cover"
                    />
                    <div class="work-status checking" v-if="item.auditStatus == 0">
                        <span>审核中</span>
                        <img src="@/assets/school/zhang.svg" alt=""/>
                    </div>
                    <div class="work-status success" v-else-if="item.auditStatus == 1">
                        <span>已通过</span>
                        <img src="@/assets/school/duigou.svg" alt="">
                    </div>
                    <div class="work-status fail" v-else>
                        <span>已驳回</span>
                        <img src="@/assets/school/cha.svg" alt=""/>
                    </div>
                </div>
                <div class="name-count">
                    <div class="name">{{item.name}}</div>
                    <div class="count">发行数量：{{item.stockTotal}}份</div>
                </div>
                <div class="price-certificate">
                    <div class="price">售价：{{item.price}}元</div>
                    <div class="certificate" @click="seeZs(index)">查看作品证书</div>
                </div>
                <div class="btn-detail" @click="jumpPage('goodsdetail',{id:item.id,type:'goods'})">查看详情</div>
            </div>
        </div>
    </vue-loadmore>
    <!-- 发布按钮 -->
    <div class="btn-release" @click="jumpPage('school-release')"><img src="@/assets/school/edit.svg" alt="" > <span>发布新的作品</span></div>

    <!-- 证书弹层 -->
    <certificate v-if="showZs" @closeZs="showZs = false" :cerData="currentData"></certificate>
  </div>
</template>

<script>
import certificate from '@/components/certificate'
import $http from '@/utils/request'
import config from '@/config'

export default {
    data(){
        return {
            finished:false,
            pageSize:10,
            current:1,//当前页数
            total:0,//总数
            showZs:false,//是否展示证书
            workList:[],
        }
    },
    components:{
        certificate
    },
    computed:{
        maxPage(){
            return Math.ceil(this.total / this.pageSize);
        }
    },
    created(){
        this.getList();
    },
    methods:{
        getList(){
            $http({
                method:'get',
                url:config.api.listMySelf,
                params:{
                    current:this.current,
                    pageSize:this.pageSize
                }
            }).then(res=>{
                this.workList = this.workList.concat(res.result.records);
                this.total = res.result.total;
            })
        },
        seeZs(index){
            this.currentData = this.workList[index];
            this.showZs = true;
        },
        loadMore(done){
            if(this.current < this.maxPage){
                this.current=this.current + 1;
                this.getList();
            }else{
                this.finished = true;
            }
            
            done();
        },
        jumpPage(name,params){
            this.$router.push({
                name,
                params
            });
        }
    },
}
</script>

<style lang="scss" scoped>
.page-container{
    padding:30px;
    padding-bottom: 200px;
    .work-list{
        .work-item{
            border:15px;
            background-color: #080607;
            margin-bottom: 40px; 
            .pic-box{
                width:690px;
                height:659px;
                position: relative;
                .work-img{
                    width:100%;
                    height:100%;
                    border-radius: 15px;
                    overflow: hidden;
                }
                .work-status{
                    position: absolute;
                    top:0;
                    right:0;
                    width:180px;
                    height:70px;
                    background: rgba(8,6,7,0.7);
                    border-radius: 0px 15px 0px 15px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-size: 28px;
                    font-family: PingFangSC-Medium, PingFang SC;
                    font-weight: 500;
                    &>img{
                        margin-left: 20px;
                        width: 20px;
                        padding:8px;
                        border-radius: 50%;
                    }
                    &.success{
                        color:#52C41A;
                        &>img{
                            background-color: #52C41A;
                        }
                    }
                    &.fail{
                        color:#FF4C4C;
                        &>img{
                            background-color: #FF4C4C;
                        }
                    }
                    &.checking{
                        color:#1296DB;
                        &>img{
                            background-color: #1296DB;
                        }
                    }
                }
            }
            .name-count{
                margin-top: 40px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding:0 30px;
                margin-bottom: 20px;
                .name{
                    width:50%;
                    flex-shrink: 0;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    font-size: 48px;
                    font-family: PingFangSC-Semibold, PingFang SC;
                    font-weight: 600;
                    color: #FFFFFF;
                }
                .count{
                    width:50%;
                    flex-shrink: 0;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    font-size: 36px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #FFFFFF;
                    text-align: right;
                }
            }
            .price-certificate{
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding:0 30px;
                margin-bottom: 30px;
                .price{
                    width:50%;
                    flex-shrink: 0;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    font-size: 36px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #FFFFFF;
                }
                .certificate{
                    width:50%;
                    flex-shrink: 0;
                    text-align: right;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    font-size: 36px;
                    font-family: PingFangSC-Medium, PingFang SC;
                    font-weight: 500;
                    color: #1296DB;
                }
            }
            .btn-detail{
                height:70px;
                background: linear-gradient(90deg, rgba(255,255,255,0) 0%, #1296DB 49%, rgba(255,255,255,0) 100%);
                text-align: center;
                line-height: 70px;
                font-size: 36px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #FFFFFF;
            }
        }
    }
    .btn-release{
        position: fixed;
        z-index: 97;
        bottom:60px;
        width:690px;
        height:100px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 36px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #FFFFFF;
        background: #1296DB;
        border-radius: 15px;
        &>img{
            margin-right: 10px;
            width:44px;
        }
    }
}
</style>
<template>
    <div class="mask" @click.self="closeShare">
        <div class="page-container">
            <div class="content-box" ref="imageWrapper">
                <div class="img-wrapper">
                    <van-image
                        class="real-pic"
                        fit="cover"
                        v-if="from == 'calendar'"
                        :src="require('../assets/share/cover-calendar.png')"
                    />
                    <van-image
                        class="real-pic"
                        fit="cover"
                        v-if="from == 'interest' || from == 'mine'"
                        :src="require('../assets/share/cover-interest.png')"
                    />
                    <template v-if="from == 'goodsdetail'">
                        <!-- <van-image
                            class="real-pic"
                            fit="contain"
                            :src="imgBaseUrl + cover"
                        /> -->
                        <img :src="imgBaseUrl + cover" alt="" class="real-pic">
                    </template>
                    <img src="@/assets/share/icon-download.png" alt="" @click="onExport" class="icon-download">
                </div>
                <div class="info-box">
                    <template v-if="from == 'goodsdetail'">
                        <div class="title">{{name}}</div>
                        <div class="sub-title">发现一款超赞的数字藏品，快来看看</div>
                    </template>
                    <template v-else>
                        <div class="title">藏品信息早知道</div>
                        <div class="sub-title">关注发售日历，不错过每一份热爱</div>
                    </template>
                    <div class="share-bottom">
                        <div class="txt-content">
                            <img src="@/assets/share/icon-logo.png" alt="" class="logo">
                            <div class="slogn">扫码入群，探索更多数字藏品</div>
                        </div>
                        <div v-if="from == 'goodsdetail' || from == 'mine' || from == 'interest'" class="qrcode" ref="qrCodeUrl"></div>
                        <img v-else src="@/assets/share/group-qrcode.png" alt="" class="qrcode">
                    </div>
                </div>
            </div>
            <div class="btn-share" v-clipboard:copy="shareAddress" v-clipboard:success="copySucc" v-clipboard:error="copyFail">复制邀请链接</div>
        </div>
    </div>
</template>

<script>
import { getItem } from '@/utils/user';
import html2canvas from 'html2canvas'
import QRCode from 'qrcodejs2'

export default {
    props:['from','cover','name','id','type'],
    computed:{
        shareAddress(){
            let code = getItem('myInvitecode');
            if(code){
                if(this.from == 'goodsdetail'){
                    return `${window.location.protocol}//${window.location.host}/goodsdetail/${this.id}/${this.type}?invitecode=${code}`
                }else if(this.from == 'mine'){
                    return `${window.location.protocol}//${window.location.host}/login?action=register&invitecode=${code}`
                }else if(this.from == 'interest'){
                    return `${window.location.protocol}//${window.location.host}/?invitecode=${code}`
                }else{
                    return `${window.location.protocol}//${window.location.host}?invitecode=${code}`
                }
            }else{
                if(this.from == 'goodsdetail'){
                    return `${window.location.protocol}//${window.location.host}/goodsdetail/${this.id}/${this.type}`
                }else if(this.from == 'mine'){
                    return `${window.location.protocol}//${window.location.host}/login?action=register`
                }else if(this.from == 'interest'){
                    return `${window.location.protocol}//${window.location.host}/`
                }else{
                    return `${window.location.protocol}//${window.location.host}/`
                }
                
            }
        }
    },
    mounted(){
        if(this.from == 'goodsdetail' || this.from == 'mine' || this.from == 'interest'){
            this.creatQrCode();
        }
    },
    methods:{
        creatQrCode() {
            var qrcode = new QRCode(this.$refs.qrCodeUrl, {
                text: this.shareAddress, // 需要转换为二维码的内容
                width: 100,
                height: 100,
                colorDark: '#000000',
                colorLight: '#ffffff',
                correctLevel: QRCode.CorrectLevel.H
            })
        },
        copySucc(){
            this.$toast('复制成功');
        },
        copyFail(){
            this.$toast('复制失败');
        },
        onExport() {
            const clientWidth = this.$refs.imageWrapper.offsetWidth * 3;
            const clientHeight = this.$refs.imageWrapper.offsetHeight * 3;
            const kh = [clientWidth, clientHeight];

            html2canvas(this.$refs.imageWrapper, { useCORS: true, logging: true }).then((canvas) => {
                const dataURL = canvas.toDataURL('image/png');
                this.download(dataURL, kh);
            });
        },
        getUrlBase64(url, kh) {
            return new Promise((resolve) => {
                let canvas = document.createElement('canvas');
                const ctx = canvas.getContext('2d');
                const img = new Image();
                img.crossOrigin = 'Anonymous'; // 允许跨域
                img.src = url;
                img.onload = () => {
                    // eslint-disable-next-line prefer-destructuring
                    canvas.height = kh[1];
                    // eslint-disable-next-line prefer-destructuring
                    canvas.width = kh[0];
                    ctx.drawImage(img, 0, 0, kh[0], kh[1]);
                    const dataURL = canvas.toDataURL('image/png');
                    canvas = null;
                    resolve(dataURL);
                };
            });
        },
        download(imgUrl, kh) {
            this.getUrlBase64(imgUrl, kh).then((base64) => {
                const link = document.createElement('a');
                link.href = base64;
                link.download = `NFTNET数字藏品.png`;
                link.click();
            });
        },
        closeShare(){
            this.$emit("closeShare");
        }
    }
}
</script>

<style lang="scss">
.qrcode{
    width:100px;
    flex-shrink: 0;
    img{
        width:100px!important;
        height:100px!important;
    }
    canvas{
        width:100px!important;
        height:100px!important;
    }
}
</style>

<style lang="scss" scoped>
.mask{
    background-color: rgba(0,0,0,0.6);
    position: fixed;
    z-index: 100;
    width:100vw;
    height:100vh;
    top:0;
    left:0;
    .page-container{
        position: absolute;
        width:100%;
        left:0;
        top:50%;
        transform: translateY(-50%);
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        .content-box{
            width:500px;
            // border-radius: 20px;
            overflow: hidden;
            .img-wrapper{
                height:468px;
                background-color: rgba(0,0,0,0.9);
                position: relative;
                display: flex;
                justify-content: center;
                align-items: center;
                .real-pic{
                    max-width:100%;
                    max-height:100%;
                }
                .icon-download{
                    width:64px;
                    position: absolute;
                    top:20px;
                    right:15px;
                }
            }
            .info-box{
                padding:20px 16px;
                background-color: #000;
                .title{
                    font-size: 22px;
                    font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                    font-weight: 400;
                    color: #FFFFFF;
                    margin-bottom: 10px;
                }
                .sub-title{
                    font-size: 22px;
                    font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                    font-weight: 400;
                    color: #999999;
                    margin-bottom: 26px;
                }
                .share-bottom{
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    .txt-content{
                        width:100%;
                        .logo{
                            width:168px;
                            margin-bottom: 10px;
                        }
                        .slogn{
                            font-size: 22px;
                            font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                            font-weight: 400;
                            color: #40A9FF;
                        }
                    }
                    .qrcode{
                        width:100px;
                        flex-shrink: 0;
                    }
                }
                
            }
        }
        .btn-share{
            margin-top: 130px;
            width: 661px;
            height: 80px;
            line-height: 80px;
            text-align: center;
            background: linear-gradient(90deg, #0C81F5 0%, #2B99FB 60%, #40A9FF 100%);
            border-radius: 40px;
            font-size: 30px;
            font-family: Source Han Sans CN-Regular, Source Han Sans CN;
            font-weight: 400;
            color: #FFFFFF;
        }
    }
}
</style>
<template>
    <div class="page-container">
        <van-image
            class="banner"
            fit="cover"
            :src="imgBaseUrl + pageData.cover"
        />
        <div class="infobox">
            <div class="name-pic">
                <van-image
                    class="pic"
                    fit="cover"
                    :src="imgBaseUrl + pageData.avatar"
                />
                <div class="name-box">
                    <div>{{pageData.title}}</div>
                    <div>{{pageData.name}}</div>
                </div>
            </div>
            <div class="introduce" v-html="pageData.description"></div>
        </div>
        <div class="title">系列藏品</div>
        <div class="filters">全部</div>
        <div class="goods-list">
            <div class="goods-item" v-for="item in pageData.works" :key="item.id" @click="jumpPage('goodsdetail',{id:item.id,type:'goods'})">
                <div class="img-box">
                    <van-image
                        class="img-box-bg"
                        fit="cover"
                        :src="imgBaseUrl + item.avatar"
                    />
                    <div class="img-box-wrapper">
                        <img :src="imgBaseUrl + item.avatar" alt=""/>
                    </div>
                </div>
                <div class="goods-info">
                    <div class="author-line">
                        <div class="author-info">
                            <img :src="imgBaseUrl + pageData.avatar" alt="">
                            <span>{{pageData.name}}</span>
                        </div>
                        <div class="status-box" v-if="item.status == 1">
                            <img src="@/assets/icon-hot.png" alt="">
                            <span>热销中</span>
                        </div>
                        <div class="sale-out" v-if="item.status == 3">已售罄</div>
                        <div class="sale-out" v-if="item.status == 2">定时上线</div>
                        <div class="sale-out" v-if="item.status == 5">预约</div>
                    </div>
                    <div class="goods-name">{{item.name}}</div>
                    <div class="price-line">
                        <div class="price-box">
                            <span>当前售价</span>
                            <span>¥ {{item.price}}</span>
                        </div>
                        <div class="fav-box">
                            <span>{{item.likeNum}}</span>
                            <img src="@/assets/icon-fav-light.png" @click.stop="unstar(item.id,1,item)" v-if="item.stared" alt="">
                            <img src="@/assets/icon-fav.png" @click.stop="star(item.id,1,item)" v-else alt="">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import $http from '@/utils/request'
import config from '@/config'

export default {
    data(){
        return {
            pageData:{
                works:[]
            }
        }
    },
    computed:{
        id(){
            return this.$route.params.id;
        }
    },
    created(){
        this.getData();
    },
    methods:{
        //点亮小心心
        star(id,type,item){
            $http({
                method:'get',
                url:config.api.makeStar,
                params:{
                    id,
                    type //type 1 表示藏品的小心心 2 表示藏品组的小心心  3 表示艺术家的关注
                }
            }).then(res=>{
                this.$toast('操作成功');
                item.stared = true;
                item.likeNum = item.likeNum + 1;
                this.pageData = this.pageData;
            })
        },
        //去掉小心心
        unstar(id,type,item){
            $http({
                method:'get',
                url:config.api.unFollow,
                params:{
                    id,
                    type
                }
            }).then(res=>{
                this.$toast('操作成功');
                item.stared = false;
                item.likeNum = item.likeNum - 1;
                this.pageData = this.pageData;
            })
        },
        jumpPage(name,params){
            this.$router.push({
                name,
                params
            });
        },
        getData(){
            $http({
                method:'get',
                url:config.api.pavilionDetail,
                params:{
                    id:this.id,
                }
            }).then(res=>{
                this.pageData = res.result;
            })
        }
    }
}
</script>

<style lang="scss">
.introduce{
    padding:26px 10px;
    border-radius: 18px;
    border: 2px solid #0C81F5;
    font-size: 26px;
    font-family: Source Han Sans CN-Regular, Source Han Sans CN;
    font-weight: 400;
    color: #999999;
    line-height: 40px;
    span{
        font-size: 26px!important;
        font-family: Source Han Sans CN-Regular, Source Han Sans CN!important;
        font-weight: 400!important;
        color: #999999!important;
        line-height: 40px!important;
    }
}
</style>

<style lang="scss" scoped>
.page-container{
    padding:30px;
    .banner{
        width:100%;
        height:430px;
    }
    .infobox{
        padding:40px 20px;
        border-radius: 16px;
        background-color: #000;
        position: relative;
        top:-20px;
        .name-pic{
            display: flex;
            justify-content: flex-start;
            align-items: center;
            margin-bottom: 40px;
            .pic{
                width:128px;
                height: 128px;
                border-radius: 50%;
                overflow: hidden;
                margin-right: 30px;
                flex-shrink: 0;
            }
            .name-box{
                &>div{
                    &:first-child{
                        font-size: 36px;
                        font-family: Source Han Sans CN-Bold, Source Han Sans CN;
                        font-weight: bold;
                        color: #FFFFFF;
                        margin-bottom: 20px;
                    }
                    &:last-child{
                        font-size: 26px;
                        font-family: Source Han Sans CN-Bold, Source Han Sans CN;
                        font-weight: bold;
                        color: #999999;
                    }
                }
            }
        }
        .introduce{
            padding:26px 10px;
            border-radius: 18px;
            border: 2px solid #0C81F5;
            font-size: 26px;
            font-family: Source Han Sans CN-Regular, Source Han Sans CN;
            font-weight: 400;
            color: #999999;
            line-height: 40px;
        }
    }
    .title{
        padding:30px 0;
        font-size: 36px;
        font-family: Source Han Sans CN-Regular, Source Han Sans CN;
        font-weight: 400;
        color: #FFFFFF;
        border-bottom: 1px solid #707070;
    }
    .filters{
        padding:30px 0;
        font-size: 24px;
        font-family: Source Han Sans CN-Regular, Source Han Sans CN;
        font-weight: 400;
        color: #40A9FF;
    }
    .goods-list{
        .goods-item{
            background-color: #080607;
            border-radius: 16px;
            overflow: hidden;
            margin-bottom: 30px;
            .img-box{
                position: relative;
                height:542px;
                border-radius: 16px;
                overflow: hidden;
                .img-box-bg{
                    position: absolute;
                    left:0;
                    right:0;
                    top:0;
                    bottom:0;
                    // background: url('../assets/tmp-cover.png');
                    // background-size: cover;
                    opacity: 0.5;
                    z-index: 1;
                }
                .img-box-wrapper{
                    width:100%;
                    height:542px;
                    padding:26px;
                    box-sizing: border-box;
                    position: absolute;
                    top:0;
                    left:0;
                    z-index: 2;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    img{
                        display: block;
                        max-height: 100%;
                    }
                }
            }
            .goods-info{
                padding:30px;
                padding-bottom: 0;
                .author-line{
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    margin-bottom: 28px;
                    .author-info{
                        display: flex;
                        justify-content: start;
                        align-items: center;
                        img{
                            margin-right: 30px;
                            width:64px;
                            border-radius: 50%;
                        }
                        span{
                            font-size: 22px;
                            font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                            font-weight: 400;
                            color: #999999;
                        }
                    }
                    .status-box{
                        display: flex;
                        justify-content: end;
                        align-items: center;
                        img{
                            margin-right: 20px;
                            width:28px;
                        }
                        span{
                            font-size: 22px;
                            font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                            font-weight: 400;
                            color: #999999;
                        }
                    }
                    .sale-out{
                        width: 92px;
                        height: 40px;
                        line-height: 40px;
                        text-align: center;
                        background: #999999;
                        font-size: 22px;
                        font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                        font-weight: 400;
                        color: #FFFFFF;
                    }
                }
                .goods-name{
                    font-size: 30px;
                    font-family: Source Han Sans CN-Medium, Source Han Sans CN;
                    font-weight: 500;
                    color: #FFFFFF;
                    padding-bottom: 24px;
                    border-bottom: 1px solid #707070;
                }
                .price-line{
                    padding:18px 0;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    .price-box{
                        display: flex;
                        justify-content: start;
                        align-items: center;
                        span{
                            &:first-child{
                                font-size: 22px;
                                font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                                font-weight: 400;
                                color: #999999;
                                margin-right: 20px;
                            }
                            &:last-child{
                                font-size: 30px;
                                font-family: Source Han Sans CN-Medium, Source Han Sans CN;
                                font-weight: 500;
                                color: #FFFFFF;
                            }
                        }
                    }
                    .fav-box{
                        display: flex;
                        justify-content: end;
                        align-items: center;
                        span{
                            margin-right: 10px;
                            font-size: 22px;
                            font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                            font-weight: 400;
                            color: #999999;
                        }
                        img{
                            width:48px;
                        }
                    }
                }
            }
        }
    }
}
</style>
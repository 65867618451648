<template>
    <div class="page-container">
        <div class="form-item">
            <div class="form-label">姓名</div>
            <div class="input-box">
                <input type="text" v-model="name" placeholder="请输入姓名" />
            </div>
        </div>
        <div class="form-item">
            <div class="form-label">手机号</div>
            <div class="input-box">
                <input type="text" v-model="mobile" placeholder="请输入手机号" />
            </div>
        </div>
        <div class="form-item" @click="show = true">
            <div class="form-label">地区</div>
            <div class="input-box zone-box">
                <input type="text" v-if="selectedAddressArr" :value="selectedAddressArr[0].name + selectedAddressArr[1].name + selectedAddressArr[2].name" disabled placeholder="请输入地区" />
                <input type="text" v-else disabled placeholder="请输入地区" />
                <img src="@/assets/mine/icon-zone-arrow.png" alt="">
            </div>
        </div>
        <div class="form-item">
            <div class="form-label">详细地址</div>
            <div class="input-box">
                <input type="text" v-model="address" placeholder="请输入详细地址" />
            </div>
        </div>
        <div class="set-default">
            <span>设为默认收货地址</span>
            <van-switch v-model="isDefault" size="26" />
        </div>

        <div class="btn-grp" v-if="id">
            <div class="goback" @click="makeDelete">删除 </div>
            <div class="btn-save" @click="confirmUpdate">保存</div>
        </div>
        <div class="btn-add" @click="confirmAdd" v-else>新增地址</div>


        <!-- 省市联动 -->
        <van-popup v-model="show" position="bottom" round :style="{ height: '45%' }">
            <van-area :area-list="areaList" :value="areaCode" @confirm="confirmSs" @cancel="cancelSs" :columns-placeholder="['请选择', '请选择', '请选择']" />
        </van-popup>
    </div>
</template>

<script>
import { areaList } from '@vant/area-data';

import $http from '@/utils/request'
import config from '@/config'

export default {
    data(){
        return {
            show:false,
            areaList,
            name:'',
            mobile:'',
            address:'',
            type:1 ,//1 常用,2 非常用
            isDefault:false,
            areaCode:'',
            selectedAddressArr:null,
        }
    },
    created(){
        if(this.id){
            this.getAddress();
        }
    },
    computed:{
        id(){
            return this.$route.params.id ? this.$route.params.id : null;
        }
    },
    methods:{
        makeDelete(){
            $http({
                method:'post',
                url:config.api.updateAddress,
                data:{
                    id:this.id,
                    deleted:1
                }
            }).then(res=>{
                this.$toast("删除成功");
                this.goBack();
            })
        },
        confirmUpdate(){
            $http({
                method:'post',
                url:config.api.updateAddress,
                data:{
                    id:this.id,
                    name:this.name,
                    mobile:this.mobile,
                    province:this.selectedAddressArr[0].name,
                    city:this.selectedAddressArr[1].name,
                    county:this.selectedAddressArr[2].name,
                    address:this.address,
                    type:this.isDefault ? 1 : 2
                }
            }).then(res=>{
                this.$toast("修改成功");
                this.goBack();
            })
        },
        goBack(){
            this.$router.go(-1);
        },
        getAddress(){
            $http({
                method:'get',
                url:config.api.getAddressInfo,
                params:{
                    id:this.id
                }
            }).then(res=>{
                this.name = res.result.name;
                this.mobile = res.result.mobile;
                this.address = res.result.address;
                this.type = res.result.type;
                this.isDefault = this.type == 1 ? true : false;
                let tmpAddArr=[];
                for(let i in this.areaList.province_list){
                    console.log(i);
                    if(this.areaList.province_list[i] == res.result.province){
                        tmpAddArr.push({code:i,name:res.result.province});
                        break;
                    }
                }
                for(let i in this.areaList.city_list){
                    if(this.areaList.city_list[i] == res.result.city){
                        tmpAddArr.push({code:i,name:res.result.city});
                        break;
                    }
                }
                for(let i in this.areaList.county_list){
                    if(this.areaList.county_list[i] == res.result.county){
                        tmpAddArr.push({code:i,name:res.result.county});
                        break;
                    }
                }
                this.selectedAddressArr = tmpAddArr;
                this.areaCode = this.selectedAddressArr[2].code;
            })
        },
        confirmSs(result){
            this.selectedAddressArr = result;
            this.areaCode = result[2].code
            this.show = false;
        },
        cancelSs(){
            this.show = false;
        },
        confirmAdd(){
            $http({
                method:'post',
                url:config.api.addAddress,
                data:{
                    name:this.name,
                    mobile:this.mobile,
                    province:this.selectedAddressArr[0].name,
                    city:this.selectedAddressArr[1].name,
                    county:this.selectedAddressArr[2].name,
                    address:this.address,
                    type:this.isDefault ? 1 : 2
                }
            }).then(res=>{
                this.$toast("添加成功");
                this.goBack();
            })
        }
    }
}
</script>

<style lang="scss" scoped>
::v-deep .van-picker{
    background:#333;
}
::v-deep .van-picker__mask{
    background: none;
}
::v-deep .van-picker-column__item{
    color:#fff;
}
::v-deep .van-picker__confirm{
    color:#40A9FF;
}
.page-container{
    padding:22px 60px;
    .form-item{
        margin-top: 44px;
        border-bottom: 1px solid #FFFFFF;
        .form-label{
            font-size: 26px;
            font-family: Source Han Sans CN-Medium, Source Han Sans CN;
            font-weight: 500;
            color: #FFFFFF;
            line-height: 22px;
        }
        .input-box{
            margin-top: 10px;
            padding:20px 0 ;
            display: flex;
            justify-content: space-between;
            align-items: center;
            & > input{
                width: 100%;
            }
            .btn-grp{
                flex-shrink: 0;
                display: flex;
                justify-content: center;
                align-items: center;
                &>img{
                    width:32px;
                    height:32px;
                    margin-right: 30px;
                }
                &>div{
                    font-size: 24px;
                    font-family: Source Han Sans CN-Medium, Source Han Sans CN;
                    font-weight: 500;
                    color: #0C81F5;
                }
            }
            &.zone-box{

                &>img{
                    flex-shrink: 0;
                    width:32px;
                    margin-right: 30px;
                }
            }
        }
    }
    .set-default{
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 80px;
        span{
            font-size: 26px;
            font-family: Source Han Sans CN-Medium, Source Han Sans CN;
            font-weight: 500;
            color: #FFFFFF;
        }
    }
    .btn-grp{
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        margin-top: 200px;
        .btn-save{
            text-align: center;
            width:300px;
            height: 79px;
            line-height: 79px;
            background: linear-gradient(90deg, #0C81F5 0%, #40A9FF 100%);
            border-radius: 40px 40px 40px 40px;
            font-size: 30px;
            font-family: Source Han Sans CN-Regular, Source Han Sans CN;
            font-weight: 400;
            color: #FFFFFF;
        }
        .goback{
            text-align: center;
            width:300px;
            height: 79px;
            line-height: 79px;
            background: linear-gradient(90deg, #FF0000 0%, #FF5555 100%);
            border-radius: 40px 40px 40px 40px;
            font-size: 30px;
            font-family: Source Han Sans CN-Regular, Source Han Sans CN;
            font-weight: 400;
            color: #FFFFFF;
        }
    }
    .btn-add{
        margin-top: 200px;
        text-align: center;
        height: 79px;
        line-height: 79px;
        background: linear-gradient(90deg, #0C81F5 0%, #40A9FF 100%);
        border-radius: 40px 40px 40px 40px;
        font-size: 30px;
        font-family: Source Han Sans CN-Regular, Source Han Sans CN;
        font-weight: 400;
        color: #FFFFFF;
    }
}
</style>
<template>
    <div class="mask">
        <div class="page-container">
            <div class="goods-name">{{goodsName}}</div>
            <div class="info-list">
                <div class="info-item">
                    <div>艺术家</div>
                    <div>{{author}}</div>
                </div>
                <div class="info-item">
                    <div>认证标识</div>
                    <div>上链确认中</div>
                </div>
                <div class="info-item">
                    <div>数量</div>
                    <div>
                        <div class="count-control">
                            <div><img src="../assets/paylayer/icon-jian.png" alt=""></div>
                            <div>{{count}}</div>
                            <div><img src="../assets/paylayer/icon-jia.png" alt=""></div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="pay-way">
                <div class="title">请选择支付方式</div>
                <div class="way-list">
                    <div class="way-item">
                        <div class="check-box">
                            <img src="../assets/paylayer/icon-checked.png" alt="" class="icon-checked">
                        </div>
                        <div class="paylogo">
                            <img src="../assets/paylayer/icon-alipay.png" alt="" class="logo">
                            <span>支付宝</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="order-info">
                <div>
                    <span>{{count}}</span>件商品，商品总金额：
                </div>
                <div>¥{{totalPrice}}</div>
            </div>
            <div class="btn-grp">
                <div class="btn-item" @click="closePayLayer">返回详情</div>
                <div class="btn-item active" @click="makePay">立即支付</div>
            </div>
        </div>
    </div>
</template>

<script>
import $http from '@/utils/request'
import config from '@/config'

export default {
    props:{
        orderId:{
            type:String,
            default:'',
            required:true
        },
        goodsName:{
            type:String,
            default:'',
            required:true
        },
        author:{
            type:String,
            default:'',
            required:true
        },
        count:{
            type:Number,
            default:1,
            required:true
        },
        price:{
            type:Number,
            default:'',
            required:true
        }
    },
    computed:{
        totalPrice(){
            return this.price * this.count;
        }
    },
    methods:{
        closePayLayer(){
            this.$emit("closePayLayer");
        },
        add(){
            this.count = this.count + 1;
        },
        subtract(){
            if(this.count > 1){
                this.count = this.count - 1;
            }
        },
        makePay(){
            // 支付宝支付
            window.location.replace(`/art/payment/alipayWap?orderId=${this.orderId}`);
            
            
            // $http({
            //     method:'get',
            //     url:`/payment/hmPay`,
            //     params:{
            //         orderId:this.orderId
            //     }
            // }).then(res=>{
            //     window.location.href=res.result;
            // })
        }
    }
}
</script>

<style lang="scss" scoped>
.mask{
    position: fixed;
    z-index: 100;
    top:0;
    left:0;
    width:100%;
    height:100%;
    background: rgba(0,0,0,0.6);
    .page-container{
        position: absolute;
        left:0;
        bottom:0;
        background-color: #222;
        width:100%;
        box-sizing: border-box;
        padding:43px 30px 73px;
        .goods-name{
            font-size: 28px;
            font-family: Source Han Sans CN-Bold, Source Han Sans CN;
            font-weight: bold;
            color: #FFFFFF;
            text-align: center;
        }
        .info-list{
            margin-top: 50px;
            .info-item{
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 43px;
                & > div{
                    font-size: 26px;
                    font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                    font-weight: 400;
                    color: #CCCCCC;
                }
                .count-control{
                    display: flex;
                    justify-content: flex-end;
                    align-items: center;
                    &>div{
                        &:first-child,&:last-child{
                            width:40px;
                            height:40px;
                            background-color: #707070;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            img{
                                width:32px;
                            }
                        }
                        &:first-child{
                            border-top-left-radius: 2px;
                            border-bottom-left-radius: 2px;
                        }
                        &:last-child{
                            border-top-right-radius: 2px;
                            border-bottom-right-radius: 2px;
                        }
                        &:nth-child(2){
                            box-sizing: border-box;
                            height:40px;
                            line-height: 40px;
                            border-top: 1px solid #707070;
                            border-bottom: 1px solid #707070;
                            padding:0 13px;
                        }
                    }
                }
            }
        }
        .pay-way{
            .title{
                font-size: 26px;
                font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                font-weight: 400;
                color: #CCCCCC;
            }
            .way-list{
                margin-top: 30px;
                .way-item{
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    .check-box{
                        width:36px;
                        height:36px;
                        background: url('../assets/paylayer/icon-circle.png') no-repeat center center;
                        background-size: 100% 100%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        margin-right: 20px;
                        img{
                            width:20px;
                        }
                    }
                    .paylogo{
                        height:70px;
                        border-radius: 8px;
                        border:1px solid #fff;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        padding:0 10px;
                        img{
                            width:64px;
                        }
                        span{
                            font-size: 26px;
                            font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                            font-weight: 400;
                            color: #FFFFFF;
                        }
                    }
                }
            }
        }
        .order-info{
            margin-top: 78px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 120px;
            &>div{
                font-size: 26px;
                font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                font-weight: 400;
                color: #CCCCCC;
                span{
                    color:#40A9FF;
                }
                &:last-child{
                    font-weight: bold;
                    color: #40A9FF;
                }
            }
        }
        .btn-grp{
            display: flex;
            justify-content: space-between;
            align-items: center;
            .btn-item{
                width:334px;
                height:80px;
                line-height: 80px;
                text-align: center;
                border-radius: 40px;
                font-size: 30px;
                font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                font-weight: 400;
                color: #999999;
                background-color: #333;
                &.active{
                    background: linear-gradient(90deg, #0C81F5 0%, #40A9FF 100%);
                    color:#fff;
                }
            }
        }
    }
}
</style>
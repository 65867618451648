<template>
    <div class="page-container">
        <div class="imgbox">
            <img :src="imgBaseUrl + pageData.cover" alt="">
        </div>
        <div class="page-bottom">
            <div class="goods-info-box section-block">
                <div class="name">{{pageData.name}}</div>
                <div class="counts">
                    <div class="fxl">
                        <div>发行量</div>
                        <div>{{pageData.stock_total}}份</div>
                    </div>
                    <div class="sy">剩余 {{pageData.stock_remain}}份</div>
                </div>
                <div class="author-line">
                    <div class="author-box">
                        <van-image
                            class="auth-pic"
                            fit="cover"
                            :src="imgBaseUrl + pageData.authorAvatar"
                        />
                        <span>{{pageData.author}}</span>
                    </div>
                    <div class="fav-box">
                        <img src="@/assets/icon-fav-light.png" @click="unstar(gid,1)" v-if="pageData.isStared" alt="">
                        <img src="@/assets/icon-fav.png" @click="star(gid,1)" v-else alt="">
                        <span>{{pageData.likeNum}}</span>
                    </div>
                </div>
            </div>
            <div class="section-block">
                <div class="block-title">
                    <img src="../assets/goodsdetail/icon-section-title.png" alt="">
                    <div class="title">集合</div>
                </div>
                <div class="jh-content">
                    <img :src="imgBaseUrl + pageData.groupCover" alt="" class="jh-cover">
                    <div class="info-box">
                        <div class="title">{{pageData.groupName}}</div>
                        <div class="introduce" v-html="pageData.groupDesc"></div>
                    </div>
                </div>
            </div>
            <div class="section-block">
                <div class="block-title">
                    <img src="../assets/goodsdetail/icon-section-title.png" alt="">
                    <div class="title">链上信息</div>
                </div>
                <div class="chain-info">
                    <div class="info-item">
                        <div class="title">合约地址</div>
                        <div class="content">
                            <span>{{pageData.contractAddress}}</span>
                            <img src="../assets/goodsdetail/icon-copy.png" alt="" class="btn-copy" v-clipboard:copy="pageData.contractAddress" v-clipboard:success="copySucc" v-clipboard:error="copyFail">
                        </div>
                    </div>
                    <div class="info-item">
                        <div class="title">合约类型</div>
                        <div class="content">ERC-1155</div>
                    </div>
                    <div class="info-item">
                        <div class="title">区块链</div>
                        <div class="content">{{pageData.chainName}}</div>
                    </div>
                </div>
            </div>
            <div class="section-block">
                <div class="tabs">
                    <div class="tab-item" :class="{active:currentTab == 'desc'}" @click="changeTab('desc')">藏品描述</div>
                    <div class="tab-item" :class="{active:currentTab == 'record'}" @click="changeTab('record')">流转中心</div>
                </div>
                <!-- 藏品描述 -->
                <div class="goods-desc" v-html="pageData.description" v-show="currentTab == 'desc'">
                    
                </div>
                <!-- 购买记录 -->
                <div class="record-list" v-show="currentTab == 'record'">
                    <div class="record-item title-item">
                        <div>用户</div>
                        <div>购买时间</div>
                        <div>费用</div>
                        <div>交易哈希</div>
                    </div>
                    <div class="record-item" v-for="item in recordList" :key="item.id">
                        <div>{{item.userName}}</div>
                        <div>{{item.date | getDate}} <br> {{item.date | getTime}}</div>
                        <div>¥{{item.price}}</div>
                        <div>{{item.hash}}</div>
                    </div>
                    <empty v-if="recordList.length == 0" />
                    <div class="record-item line-more" v-show="total > recordList.length" @click="loadAllRecord">
                        <span>展开更多</span>
                        <img src="../assets/goodsdetail/icon-line-more.png" alt="">
                    </div>
                </div>
            </div>
        </div>


        <!-- 底部悬浮条 -->
        <div class="footer-line">
            <div class="price">¥{{pageData.price}}</div>
            <div class="btn-buy" v-if="pageData.status == 1" @click="makeOrder">立即购买</div>
            <div class="sale-out" v-if="pageData.status == 3">售罄</div>
        </div>

        <!-- 支付弹层 -->
        <payLayer v-if="showPay" :orderId="orderId" :goodsName="pageData.name" @closePayLayer="showPay = false" :author="pageData.author" :count="1" :price="pageData.price" />
    </div>
</template>

<script>
import payLayer from '@/components/payLayer'
import $http from '@/utils/request'
import config from '@/config'
import dayjs from 'dayjs'
import { setItem } from '@/utils/user'

export default {
    name:'goodsdetail',
    data(){
        return {
            pageData:{},
            currentTab:'desc',
            current:1,
            pageSize:10,
            total:0,
            recordList:[],
            orderId:null,
            showPay:false,
        }
    },
    filters:{
        getDate(val){
            if(!val){
                return ''
            }else{
                return dayjs(val).format("YYYY-MM-DD");
            }
        },
        getTime(val){
            if(!val){
                return ''
            }else{
                return dayjs(val).format("HH:mm:ss");
            }
        }
    },
    components:{
        payLayer
    },
    computed:{
        gid(){
            return this.$route.params.id;
        },
        type(){
            return this.$route.params.type; 
        }
    },
    activated(){
        let code = this.$route.query ? this.$route.query.invitecode : null;
        if(code){
            setItem('shareInvitecode',code);
        }
        this.getData();
        this.getRecord();
    },
    deactivated(){
        this.pageData={};
        this.currentTab='desc';
        this.current=1;
        this.pageSize=10;
        this.total=0;
        this.recordList=[];
        this.orderId=null;
        this.showPay=false;
    },
    methods:{
        //点亮小心心
        star(id,type,item){
            $http({
                method:'get',
                url:config.api.makeStar,
                params:{
                    id,
                    type //type 1 表示藏品的小心心 2 表示藏品组的小心心  3 表示艺术家的关注
                }
            }).then(res=>{
                this.$toast('操作成功');
                this.pageData.isStared = true;
                this.pageData.likeNum = this.pageData.likeNum + 1;
            })
        },
        //去掉小心心
        unstar(id,type,item){
            $http({
                method:'get',
                url:config.api.unFollow,
                params:{
                    id,
                    type
                }
            }).then(res=>{
                this.$toast('操作成功');
                this.pageData.isStared = false;
                this.pageData.likeNum = this.pageData.likeNum - 1;
            })
        },
        makeOrder(){
            $http({
                method:'get',
                url:config.api.createOrder,
                params:{
                    workId:this.gid,
                    source:this.type == 'goods' ? 1 : 2,  //source 1 藏品 2 盲盒
                }
            }).then(res=>{
                this.orderId = res.result.orderId;
                this.showPay = true;
            })
        },
        loadAllRecord(){
            $http({
                method:'get',
                url:config.api.buyRecord,
                params:{
                    workId:this.gid,
                    current:this.current,
                    pageSize:100
                }
            }).then(res=>{
                this.recordList = res.result.records;
                this.total = res.result.total;
            })
        },
        changeTab(val){
            this.currentTab = val;
        },
        copySucc(){
            this.$toast('复制成功');
        },
        copyFail(){
            this.$toast('复制失败');
        },
        getData(){
            if(this.type == 'goods'){ //藏品详情
                $http({
                    method:'get',
                    url:config.api.goodsDetail,
                    params:{workId:this.gid}
                }).then(res=>{
                    this.pageData = res.result;
                })
            }else{ //盲盒详情
                $http({
                    method:'get',
                    url:config.api.boxDetail,
                    params:{boxId:this.gid}
                }).then(res=>{
                    this.pageData = res.result;
                })
            }
            
        },
        getRecord(){
            $http({
                method:'get',
                url:config.api.buyRecord,
                params:{
                    workId:this.gid,
                    current:this.current,
                    pageSize:this.pageSize
                }
            }).then(res=>{
                this.recordList = res.result.records;
                this.total = res.result.total;
            })
        },
    }
}
</script>
<style lang="scss">
    .goods-desc{
        color:#fff;
        img{
            width:100%;
        }
    }
    .introduce{
        span{
            font-size: 22px!important;
            font-family: Source Han Sans CN-Regular, Source Han Sans CN!important;
            font-weight: 400;
            color: #666666!important;
            line-height: 30px!important;
        }
        font-size: 22px!important;
        font-family: Source Han Sans CN-Regular, Source Han Sans CN!important;
        font-weight: 400;
        color: #666666!important;
        line-height: 30px!important;
    }
</style>
<style lang="scss" scoped>
.page-container{
    padding-bottom: 100px;
    .imgbox{
        height:1061px;
        background:url('../assets/goodsdetail/imgbox-bg.png') no-repeat center center;
        background-size: 100% 100%;
        position: relative;
        &>img{
            position: absolute;
            left:50%;
            transform: translateX(-50%);
            bottom:296px;
            max-width: 500px;
            max-height: 618px;
        }
    }
    .page-bottom{
        position: relative;
        top:-50px;
        background-color: #1F1F1F;
        padding:30px;
        .section-block{
            padding:30px;
            background-color: #000;
            border-radius: 20px;
            margin-bottom: 10px;
        }
        .goods-info-box{
            .name{
                font-size: 28px;
                font-family: Source Han Sans CN-Bold, Source Han Sans CN;
                font-weight: bold;
                color: #FFFFFF;
                text-align: center;
                margin-bottom: 50px;
            }
            .counts{
                display: flex;
                justify-content: flex-start;
                align-items: flex-start;
                margin-bottom: 50px;
                .fxl{
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin-right: 30px;
                    div{
                        height: 43px;
                        line-height: 43px;
                        box-sizing: border-box;
                        &:first-child{
                            width: 93px;
                            text-align: center;
                            background: linear-gradient(270deg, #0C81F5 0%, #40A9FF 100%);
                            border-radius: 4px 0px 0px 4px;
                            font-size: 24px;
                            font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                            font-weight: 400;
                            color: #333333;
                        }
                        &:last-child{
                            padding:0 24px;
                            border-radius: 0px 4px 4px 0px;
                            border: 1px solid #40A9FF;
                            font-size: 24px;
                            font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                            font-weight: 400;
                            color: #40A9FF;

                        }
                        
                    }
                }
                .sy{
                    height: 43px;
                    line-height: 43px;
                    box-sizing: border-box;
                    padding:0 24px;
                    border-radius: 4px;
                    border: 1px solid #40A9FF;
                    font-size: 24px;
                    font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                    font-weight: 400;
                    color: #40A9FF;
                }
            }
            .author-line{
                display: flex;
                justify-content: space-between;
                align-items: flex-start;
                .author-box{
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    .auth-pic{
                        width:64px;
                        height:64px;
                        border-radius: 50%;
                        margin-right: 30px;
                        overflow: hidden;
                    }
                    span{
                        font-size: 22px;
                        font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                        font-weight: 400;
                        color: #999999;
                    }
                }
                .fav-box{
                    text-align: center;
                    img{
                        display: block;
                        width:64px;
                        margin:0 auto;
                        margin-bottom: 10px;
                    }
                    span{
                        font-size: 24px;
                        font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                        font-weight: 400;
                        color: #999999;
                    }
                }
            }
        }
        .block-title{
            display: flex;
            justify-content: flex-start;
            align-items: center;
            margin-bottom: 30px;
            img{
                width:8px;
                margin-right: 10px;
            }
            .title{
                font-size: 26px;
                font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                font-weight: 400;
                color: #999999;
            }
        }
        .jh-content{
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            .jh-cover{
                flex-shrink: 0;
                width:215px;
                margin-right: 26px;
            }
            .info-box{
                width:100%;
                .title{
                    font-size: 26px;
                    font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                    font-weight: 400;
                    color: #FFFFFF;
                    margin-bottom: 20px;
                }
                .introduce{
                    font-size: 22px;
                    font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                    font-weight: 400;
                    color: #666666;
                    line-height: 30px;
                }
            }
        }
        .chain-info{
            .info-item{
                margin-bottom: 20px;
                .title{
                    font-size: 24px;
                    font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                    font-weight: 400;
                    color: #999999;
                    margin-bottom: 20px;
                }
                .content{
                    font-size: 24px;
                    font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                    font-weight: 400;
                    color: #FFFFFF;
                    .btn-copy{
                        float:right;
                        width:32px;
                    }
                }
            }
        }
        .tabs{
            display: flex;
            justify-content: flex-start;
            align-items: center;
            margin-bottom: 37px;
            .tab-item{
                margin-right: 30px;
                position: relative;
                font-size: 26px;
                font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                font-weight: 400;
                color: #999999;
                &.active{
                    color:#fff;
                    &::after{
                        content: '';
                        width:104px;
                        height:7px;
                        background:url('../assets/goodsdetail/icon-tab-active.png') no-repeat center center;
                        background-size: 100% 100%;
                        position: absolute;
                        left:0;
                        bottom:-17px;
                    }
                }
            }
        }
        .record-list{
            max-height: 800px;
            overflow-y: scroll;
            .record-item{
                padding:0 30px;
                border-bottom: 1px solid #707070;
                height:62px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                font-size: 20px;
                font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                font-weight: 400;
                color: #999999;
                &.title-item{
                    height:84px;
                    border-top: 1px solid #707070;
                }
                &.line-more{
                    justify-content: center;
                    border-bottom: none;
                    img{
                        width:32px;
                        margin-left: 5px;
                    }
                    span{
                        font-size: 24px;
                        font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                        font-weight: 400;
                        color: #40A9FF;
                    }
                }
                div{
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    &:first-child{
                        width: 120px;
                    }
                    &:nth-child(2){
                        width: 166px;
                    }
                    &:nth-child(3){
                        width: 140px;
                    }
                    &:last-child{
                        width: 220px;
                    }
                }
            }
        }
    }
    .footer-line{
        position: fixed;
        width:100%;
        height:100px;
        left:0;
        bottom:0;
        background-color: #282828;
        box-sizing: border-box;
        padding:0 60px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .price{
            font-size: 50px;
            font-family: Source Han Sans CN-Bold, Source Han Sans CN;
            font-weight: bold;
            color: #FFFFFF;
        }
        .btn-buy{
            background: linear-gradient(90deg, #0C81F5 0%, #40A9FF 100%);
            width:308px;
            height:64px;
            line-height: 64px;
            text-align: center;
            border-radius: 32px;
            font-size: 36px;
            font-family: Source Han Sans CN-Regular, Source Han Sans CN;
            font-weight: 400;
            color: #FFFFFF;
        }
        .sale-out{
            width: 308px;
            height: 64px;
            line-height: 64px;
            text-align: center;
            background: #333333;
            border-radius: 32px 32px 32px 32px;
            font-size: 36px;
            font-family: Source Han Sans CN-Regular, Source Han Sans CN;
            font-weight: 400;
            color: #999999;
        }
    }
}
</style>
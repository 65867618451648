<template>
    <div class="page-container">
        <div class="form-item" style="margin-top:100px;">
            <div class="form-label">账户</div>
            <div class="input-box">
                <input type="text" v-model="phone" placeholder="请输入您的账户" />
            </div>
        </div>
        <div class="form-item">
            <div class="form-label">验证码</div>
            <div class="input-box">
                <input type="text" v-model="code" placeholder="请输入验证码" />
                <send-code :phone="phone" :type="2"></send-code>
            </div>
        </div>
        <div class="form-item">
            <div class="form-label">新密码</div>
            <div class="input-box">
                <input :type="inputType" v-model="password" placeholder="6-20位密码,数字和字母组合" />
                <div class="btn-grp">
                    <img src="@/assets/login/icon-eyes.png" alt="" @click="toggleSee">
                </div>
            </div>
        </div>
        <div class="btn-login" @click="forgetPass">确定</div>
        
    </div>
</template>

<script>
import $http from '@/utils/request'
import config from '@/config'
import {removeUid} from '@/utils/user'
import sendCode from '@/components/sendCode'

export default {
    data(){
        return {
            phone:'',
            password:'',
            code:'',
            inputType:'password'
        }
    },
    components:{
        sendCode
    },
    methods:{
        toggleSee(){
            if(this.inputType == 'password'){
                this.inputType = 'text';
            }else{
                this.inputType = 'password';
            }
        },
        forgetPass(){
            $http({
                method:'post',
                url:config.api.forgetPass,
                data:{
                    phone:this.phone,
                    password:this.password,
                    code:this.code
                }
            }).then(res=>{
                this.$toast('密码修改成功，请重新登录');
                removeUid();
                this.$router.push({
                    name:'index'
                });
            })
        },
        
    }
}
</script>

<style lang="scss" scoped>
.page-container{
    padding:22px 60px;
    .form-item{
        margin-top: 44px;
        border-bottom: 1px solid #FFFFFF;
        .form-label{
            font-size: 26px;
            font-family: Source Han Sans CN-Medium, Source Han Sans CN;
            font-weight: 500;
            color: #FFFFFF;
            line-height: 22px;
        }
        .input-box{
            margin-top: 10px;
            padding:20px 0 ;
            display: flex;
            justify-content: space-between;
            align-items: center;
            & > input{
                width: 100%;
            }
            .btn-grp{
                flex-shrink: 0;
                display: flex;
                justify-content: center;
                align-items: center;
                &>img{
                    width:32px;
                    height:32px;
                    margin-right: 30px;
                }
                &>div{
                    font-size: 24px;
                    font-family: Source Han Sans CN-Medium, Source Han Sans CN;
                    font-weight: 500;
                    color: #0C81F5;
                }
            }
        }
    }
    .btn-login{
        text-align: center;
        margin-top: 200px;
        height: 79px;
        line-height: 79px;
        background: linear-gradient(90deg, #0C81F5 0%, #40A9FF 100%);
        border-radius: 40px 40px 40px 40px;
        font-size: 30px;
        font-family: Source Han Sans CN-Regular, Source Han Sans CN;
        font-weight: 400;
        color: #FFFFFF;
    }
}
</style>
<template>
    <div class="page-container">
        <vue-loadmore 
            :on-loadmore="loadMore"
            :finished="finished"
            load-offset="100">
            <div class="collect-list" v-if="collectList.length > 0">
                <div class="collect-item" v-for="item in collectList" :key="item.id" @click="changeItem(item,item.id)">
                    <div class="collect-tag">{{item.name}}</div>
                    <div class="img-box">
                        <van-image
                            class="img-box-bg"
                            fit="cover"
                            :src="imgBaseUrl + item.cover"
                        />
                        <div class="img-box-wrapper">
                            <img :src="imgBaseUrl + item.cover" alt=""/>
                        </div>
                    </div>
                    <div class="goods-info">
                        <div class="authinfo">
                            <van-image
                                class="user-pic"
                                fit="cover"
                                :src="imgBaseUrl + item.avatar"
                            />
                            <div>{{item.author}}</div>
                        </div>
                        <div class="goods-name" v-html="item.description"></div>
                    </div>
                </div>
            </div>
            <empty v-else />
        </vue-loadmore>
        
    </div>
</template>

<script>
import $http from '@/utils/request'
import config from '@/config'
import {setItem} from '@/utils/user'

export default {
    data(){
        return {
            current:1,
            pageSize:10,
            collectList:[],
            finished:false,
            total:0,
        }
    },
    created(){
        this.getData();
    },
    computed:{
        maxPage(){
            return Math.ceil(this.total / this.pageSize);
        }
    },
    methods:{
        changeItem(item,id){
            // setItem('currentCollect',JSON.stringify(item));
            this.$router.push({
                name:'collectitem',
                params:{
                    id
                },
            });
        },
        loadMore(done){
            if(this.current < this.maxPage){
                this.current=this.current + 1;
                this.getData();
            }else{
                this.finished = true;
            }
            done();
        },
        getData(){
            $http({
                method:'get',
                url:config.api.collectGroup,
                params:{
                    current:this.current,
                    pageSize:this.pageSize
                }
            }).then(res=>{
                this.collectList = this.collectList.concat(res.result.records);
                this.total = res.result.total;
            })
        }
    }
}
</script>

<style >
p{
    padding:0;
    margin:0;
}
</style>

<style lang="scss" scoped>
.page-container{
    padding:30px;
    .collect-list{
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        flex-wrap: wrap;
        .collect-item{
            border-radius: 16px;
            background-color: #2A2B2C;
            width:330px;
            margin-bottom: 30px;
            position: relative;
            overflow: hidden;
            .collect-tag{
                position: absolute;
                top:0;
                right:0;
                height: 40px;
                line-height: 40px;
                width: 150px;
                text-align: center;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                color:#fff;
                font-size: 12px;
                padding:0 10px;
                background-color: rgba(19,82,203,0.7);
                z-index: 3;
                border-bottom-left-radius: 16px;
            }
            .img-box{
                position: relative;
                height:400px;
                border-radius: 16px;
                overflow: hidden;
                .img-box-bg{
                    position: absolute;
                    left:0;
                    right:0;
                    top:0;
                    bottom:0;
                    // background: url('../assets/tmp-cover.png');
                    // background-size: cover;
                    opacity: 0.5;
                    z-index: 1;
                }
                .img-box-wrapper{
                    width:100%;
                    height:400px;
                    padding:26px;
                    box-sizing: border-box;
                    position: absolute;
                    top:0;
                    left:0;
                    z-index: 2;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    img{
                        display: block;
                        max-width: 100%;
                        max-height: 100%;
                    }
                }
            }
            .goods-info{
                padding:20px;
                .authinfo{
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    margin-bottom: 20px;
                    .user-pic{
                        width:64px;
                        height:64px;
                        border-radius: 50%;
                        overflow: hidden;
                        margin-right: 10px;
                        flex-shrink: 0;
                    }
                    div{
                        width:100%;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        font-size: 24px;
                        font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                        font-weight: 400;
                        color: #FFFFFF;
                    }
                }
                .goods-name{
                    font-size: 24px;
                    font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                    font-weight: 400;
                    color: #FFFFFF;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    
                }
            }
        }
    }
}
</style>
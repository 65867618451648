<template>
    <div class="page-container">
        <div class="content-wrapper">
            <div class="page-top">
                <img src="@/assets/share/icon-logo.png" alt="" class="logo">
                <div class="title">藏品证书信息概览</div>
            </div>
            <div class="infobox">
                <div class="sub-title">藏品名称</div>
                <div class="sub-content">{{pageData.name}}</div>
                <div class="sub-title">藏品哈希值</div>
                <div class="sub-content">
                    <span>{{pageData.hash}}</span>
                    <img src="@/assets/goodsdetail/icon-copy.png" alt="" class="btn-copy" v-clipboard:copy="pageData.hash" v-clipboard:success="copySucc" v-clipboard:error="copyFail">
                </div>
                <div class="sub-title">藏品编码</div>
                <div class="sub-content">{{pageData.tokenId}}#{{pageData.number}}</div>
                <div class="sub-title">艺术家</div>
                <div class="sub-content">{{pageData.owner}}</div>
                <div class="sub-title">收藏者</div>
                <div class="sub-content">{{pageData.purchaseName}}</div>
                <div class="sub-title">作品介绍</div>
                <div class="sub-content" v-html="pageData.description"></div>
            </div>
        </div>
    </div>
</template>

<script>
import {getItem} from '@/utils/user'
import $http from '@/utils/request'
import config from '@/config'

export default {
    data(){
        return {
            pageData:{}
        }
    },
    created(){
        // this.pageData = JSON.parse(getItem('currentCollect'));
        this.getData();
    },
    computed:{
        gid(){
            return this.$route.params.id;
        },
        pageType(){
            return this.$route.params.type;
        },
    },
    methods:{
        getData(){
            if(this.pageType == 'collect'){
                $http({
                    method:'get',
                    url:config.api.collectDetail,
                    params:{
                        txId:this.gid,
                    }
                }).then(res=>{
                    this.pageData = res.result;
                })
            }else if(this.pageType == 'airdrop'){
                $http({
                    method:'get',
                    url:config.api.airdropDetail,
                    params:{
                        airUserId:this.gid,
                    }
                }).then(res=>{
                    this.pageData = res.result;
                })
            }
        },
        copySucc(){
            this.$toast('复制成功');
        },
        copyFail(){
            this.$toast('复制失败');
        },
    }
}
</script>

<style lang="scss">
.sub-content{
    img{
        width:100%;
        display: block;
    }
}
</style>

<style lang="scss" scoped>
.page-container{
    padding:30px;
    .content-wrapper{
        padding:20px;
        border-radius: 20px 20px 20px 20px;
        opacity: 1;
        border: 1px solid #0C81F5;
        .page-top{
            border-bottom: 1px solid #0C81F5;
            .logo{
                display: block;
                width:254px;
                margin:0 auto;
                margin-bottom: 30px;
            }
            .title{
                width: 515px;
                border-top: 1px solid #0C81F5;
                padding:20px 0;
                margin:0 auto;
                text-align: center;
                font-size: 32px;
                font-family: Source Han Sans CN-Bold, Source Han Sans CN;
                font-weight: bold;
                color: #0C81F5;
            }
        }
        .infobox{
            padding:0 20px;
            .sub-title{
                margin-top: 20px;
                font-size: 26px;
                font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                font-weight: 400;
                color: #999999;
                span{
                    margin-right: 20px;
                }
            }
            .sub-content{
                font-size: 30px;
                font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                font-weight: 400;
                color: #FFFFFF;
                margin-top: 20px;
                word-break: break-all;
                line-height: 40px;
                .btn-copy{
                    margin-left: 16px;
                    width:32px;
                }
            }
        }
    }
}
</style>
<template>
    <div class="mask">
        <div class="page-container">
            <div class="head">
                <img src="@/assets/mine/icon-close.png" alt="" @click="closeGzh">
            </div>
            <div class="top-title">关注公众号进去社群查看更多活动</div>
            <img src="@/assets/mine/img-gzh-qrcode.png" alt="" class="qrcode">
            <div class="bottom-title">识别二维码或微信搜索“NFTNET造物”公众号</div>
        </div>
    </div>
</template>

<script>
export default {
    methods:{
        closeGzh(){
            this.$emit("closeGzh");
        }
    }
}
</script>

<style lang="scss" scoped>
.mask{
    background-color: rgba(0,0,0,0.6);
    position: fixed;
    z-index: 100;
    width:100vw;
    height:100vh;
    top:0;
    left:0;
    .page-container{
        padding-bottom: 70px;
        width:560px;
        position: absolute;
        top:50%;
        left:50%;
        transform: translate(-50%,-50%);
        background: #2A2B2C;
        border-radius: 20px 20px 20px 20px;
        text-align: center;
        font-size: 20px;
        font-family: Source Han Sans CN-Regular, Source Han Sans CN;
        font-weight: 400;
        color: #999999;
        .head{
            padding:20px;
            text-align: right;
            margin-bottom: 30px;
            &>img{
                width:32px;
            }
        }
        .top-title{

        }
        .qrcode{
            display: block;
            width:286px;
            margin:50px auto;
        }
        .bottom-title{

        }
    }
}
</style>
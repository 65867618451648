<template>
    <div class="page-container">
        <img :src="imgBaseUrl + pageData.cover" alt="" class="banner">
        <div class="infobox">
            <div class="goods-name">{{pageData.title}}</div>
            <div class="sub-title">藏品介绍</div>
            <div class="sub-content">{{pageData.description}}</div>
            <div class="sub-title">兑换要求</div>
            <div class="sub-content">
                <img src="@/assets/mine/icon-level.png" class="icon-level" alt="">
                {{pageData.price}}能量值  一件
                </div>
            <div class="sub-title">发行时间</div>
            <div class="sub-content">{{pageData.launchTime}}</div>
        </div>
        <div class="page-footer">
            <div>{{pageData.price}}能量值</div>
            <div class="btn-exchange" @click="exchangeNow">立即兑换</div>
        </div>
    </div>
</template>

<script>
import {getItem} from '@/utils/user'
import $http from '@/utils/request'
import config from '@/config'

export default {
    name:'exchangepage',
    data(){
        return {
            pageData:{},
        }
    },
    activated(){
        this.pageData = JSON.parse(getItem("currentExchange"));
    },
    methods:{
        exchangeNow(){
            $http({
                method:'get',
                url:config.api.exchangeNow,
                params:{
                    workId:this.pageData.id
                }
            }).then(res=>{
                this.$toast('兑换成功');
                this.$router.push({
                    name:'exchangerecord'
                });
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.page-container{
    padding-bottom: 80px;
    .banner{
        display: block;
        width:100%;
    }
    .infobox{
        padding:60px 30px;
        .goods-name{
            font-size: 36px;
            font-family: Source Han Sans CN-Regular, Source Han Sans CN;
            font-weight: 400;
            color: #FFFFFF;
            margin-bottom: 60px;
        }
        .sub-title{
            font-size: 30px;
            font-family: Source Han Sans CN-Regular, Source Han Sans CN;
            font-weight: 400;
            color: #FFFFFF;
            margin-bottom: 30px;
        }
        .sub-content{
            font-size: 30px;
            font-family: Source Han Sans CN-Regular, Source Han Sans CN;
            font-weight: 400;
            color: #999999;
            margin-bottom: 30px;
            .icon-level{
                width:32px;
                margin-right: 5px;
            }
        }
    }
    .page-footer{
        position: fixed;
        left:0;
        bottom:0;
        width:100%;
        padding:18px 30px;
        background-color: #282828;
        z-index: 99;
        display: flex;
        justify-content: space-between;
        align-items: center;
        box-sizing: border-box;
        div{
            font-size: 36px;
            font-family: Source Han Sans CN-Regular, Source Han Sans CN;
            font-weight: 400;
            color: #FFFFFF;
            &.btn-exchange{
                width: 308px;
                height: 64px;
                line-height: 64px;
                text-align: center;
                background: linear-gradient(90deg, #0C81F5 0%, #40A9FF 100%);
                border-radius: 32px 32px 32px 32px;
                opacity: 1;
                font-size: 36px;
                font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                font-weight: 400;
                color: #FFFFFF;
            }
        }
    }
}
</style>
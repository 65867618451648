<template>
    <div class="page-container">
        <div class="logo-slogn">
            <img src="@/assets/mine/interest/logo.png" class="logo" alt="">
            <img src="@/assets/mine/interest/slogn.png" class="slogn" alt="">
        </div>
        <div class="count-box">
            <div class="count-list">
                <div class="count-item">
                    <div>{{pageData.inviteNum}}人</div>
                    <div>邀请好友</div>
                </div>
                <div class="count-item">
                    <div>{{pageData.inviteBuyNum}}人</div>
                    <div>购买藏品好友</div>
                </div>
                <div class="count-item">
                    <div>{{pageData.totalGrade | formatPrice}}</div>
                    <div>奖励能量值</div>
                </div>
            </div>
            <div class="btn-detail" @click="showDetail = true">查看明细</div>
        </div>
        <div class="rule-box">
            <div class="title">活动规则</div>
            <div class="rule-item">
                <div class="rule-title">参与方式</div>
                <div class="rule-content">参与平台邀新，邀请一名有效用户，即可获得对应的能量值奖励，在积分兑换页面兑换礼品</div>
            </div>
            <div class="rule-item">
                <div class="rule-title">什么是有效用户</div>
                <div class="rule-content">邀请的用户在NFTNET平台购买藏品，完成一次购买行为，以防科技、外挂、挂刷等手段。</div>
            </div>
        </div>
        <div class="btn-share" @click="makeInvite">立即邀请</div>

        <div class="mask" v-if="showDetail"></div>
        <!-- 能量值明细 -->
        <div class="power-layer" v-show="showDetail">
            <div class="top-line">
                <span>能量值明细查看</span>
                <img src="@/assets/mine/icon-close.png" alt="" @click="showDetail = false">
            </div>
            <div class="data-title">
                <div>用户</div>
                <div>购买藏品</div>
                <div>时间</div>
                <div>能量值奖励</div>
            </div>
            
            <div class="data-list">
                <vue-loadmore 
                :on-loadmore="loadMore"
                :finished="finished"
                load-offset="100">
                    <div class="data-line" v-for="item in collectList" :key="item.id">
                        <div>{{item.fromUserName}}</div>
                        <div>{{item.workName}}</div>
                        <div>{{item.createTime | formatDate}}</div>
                        <div>+{{item.grade}}</div>
                    </div>
                </vue-loadmore>
            </div>
            
        </div>

        <!-- 分享弹层 -->
        <shareLayer v-if="showShare" from="interest" @closeShare="showShare = false"/>
    </div>
</template>

<script>
import shareLayer from '@/components/shareLayer'

import $http from '@/utils/request'
import config from '@/config'
import dayjs from 'dayjs'

export default {
    data(){
        return {
            showDetail:false,
            current:1,
            pageSize:10,
            total:0,
            collectList:[],
            pageData:{},
            finished:false,
            showShare:false,
        }
    },
    components:{
        shareLayer
    },
    created(){
        this.getData();
        this.getRecord();
    },
    computed:{
        maxPage(){
            return Math.ceil(this.total / this.pageSize);
        }
    },
    filters:{
        formatDate(val){
            if(!val){
                return ''
            }else{
                return dayjs(val).format("YYYY-MM-DD HH:mm:ss");
            }
        },
        formatPrice(val){
            if(!val){
                return 0;
            }else{
                return Number(val).toFixed(3);
            }
        },
    },
    methods:{
        makeInvite(){
            this.showShare = true;
        },
        getData(){
            $http({
                method:'get',
                url:config.api.getGrade
            }).then(res=>{
                this.pageData = res.result;
            })
        },
        getRecord(){
            $http({
                method:'get',
                url:config.api.gradeList,
                params:{
                    current:this.current,
                    pageSize:this.pageSize
                }
            }).then(res=>{
                this.collectList = this.collectList.concat(res.result.records);
                this.total = res.result.total;
            })
        },
        loadMore(done){
            if(this.current < this.maxPage){
                this.current=this.current + 1;
                this.getRecord();
            }else{
                this.finished = true;
            }
            done();
        },
    }
}
</script>

<style lang="scss" scoped>
.page-container{
    background:url('../../assets/mine/interest/interest-bg.png') no-repeat left top;
    background-size: 100% auto;
    padding:30px;
    .mask{
        position: fixed;
        top:0;
        left:0;
        z-index: 100;
        width:100vw;
        height:100vh;
        background-color: rgba(0,0,0,0.6);
    }
    .power-layer{
        position: fixed;
        height:60%;
        left:0;
        bottom:0;
        z-index: 101;
        padding:10px;
        width:100%;
        box-sizing: border-box;
        background-color: #2A2B2C;
        border-radius: 20px 20px 0 0;
        padding:30px;
        padding-top: 0;
        .top-line{
            height:88px;
            line-height: 88px;
            font-size: 30px;
            font-family: Source Han Sans CN-Regular, Source Han Sans CN;
            font-weight: 400;
            color: #FFFFFF;
            overflow: hidden;
            & > img{
                width:32px;
                float:right;
                margin-top: 28px;
            }
        }
        .data-title{
            display: flex;
            justify-content: space-between;
            align-items: center;
            height:82px;
            line-height: 82px;
            border-top: 1px solid #707070;
            &>div{
                width:25%;
                font-size: 24px;
                font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                font-weight: 400;
                color: #FFFFFF;
                &:nth-child(4){
                    text-align: right;
                }
            }
        }
        .data-list{
            height:calc(100% - 82px - 88px);
            overflow-y: auto;
            .data-line{
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding:20px 0;
                border-top: 1px solid #707070;
                &>div{
                    width:25%;
                    font-size: 24px;
                    font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                    font-weight: 400;
                    color: #FFFFFF;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    &:nth-child(4){
                        text-align: right;
                        color:#FF4D4F;
                    }
                }
            }
        }
    }
    .logo-slogn{
        margin-bottom: 92px;
        img{
            display: block;
        }
        .logo{
            width:358px;
        }
        .slogn{
            width:646px;
            margin-top: 12px;
        }
    }
    .count-box{
        border: 1px solid #0C81F5;
        box-sizing: border-box;
        padding-bottom: 40px;
        margin-bottom: 30px;
        .count-list{
            padding:0 30px;
            padding-top: 88px;
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            margin-bottom: 50px;
            .count-item{
                text-align: center;
                width:32%;
                div{
                    font-size: 36px;
                    font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                    font-weight: 400;
                    color: #FFFFFF;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    &:first-child{
                        margin-bottom: 32px;
                    }
                }
            }
        }
        .btn-detail{
            width: 212px;
            height: 52px;
            line-height: 52px;
            text-align: center;
            background: #1296DB;
            border-radius: 8px 8px 8px 8px;
            opacity: 1;
            font-size: 24px;
            font-family: Source Han Sans CN-Regular, Source Han Sans CN;
            font-weight: 400;
            color: #FFFFFF;
            margin:0 auto;
        }
    }
    .rule-box{
        border: 1px solid #0C81F5;
        box-sizing: border-box;
        padding-top: 36px;
        margin-bottom: 68px;
        .title{
            background: linear-gradient(90deg, #0C81F5 0%, #40A9FF 100%);
            width:237px;
            height:71px;
            line-height: 71px;
            text-align: center;
            border-radius: 0 16px 0 16px;
            font-size: 30px;
            font-family: Source Han Sans CN-Regular, Source Han Sans CN;
            font-weight: 400;
            color: #FFFFFF;
            margin-bottom: 33px;
        }
        .rule-item{
            padding:0 30px;
            margin-bottom: 28px;
            font-size: 24px;
            font-family: Source Han Sans CN-Regular, Source Han Sans CN;
            font-weight: 400;
            color: #FFFFFF;
            line-height: 30px;
            .rule-title{
                margin-bottom: 26px;
                &::before{
                    content:'';
                    display: inline-block;
                    width:24px;
                    height:24px;
                    border-radius: 50%;
                    background-color: #fff;
                    margin-right: 10px;
                    vertical-align: middle;
                }
            }
            .rule-content{
                
            }
        }
    }
    .btn-share{
        width: 661px;
        height: 79px;
        line-height: 79px;
        text-align: center;
        background: linear-gradient(90deg, #0C81F5 0%, #40A9FF 100%);
        border-radius: 40px 40px 40px 40px;
        opacity: 1;
        font-size: 30px;
        font-family: Source Han Sans CN-Regular, Source Han Sans CN;
        font-weight: 400;
        color: #FFFFFF;
        margin:0 auto;
    }
}
</style>
<template>
  <div class="page-container">
    <div class="banner">
        <img src="@/assets/school/applypage-banner.png" alt="">
    </div>
    <van-form>
        <van-field v-model="formData.track" label="赛道" placeholder="请输入内容(必填)" />
        <van-field v-model="formData.colleges" label="所在院校" placeholder="请输入所在院校(必填)" />
        <van-field v-model="formData.profession" label="专业名称" placeholder="请输入所学专业(必填)" />
        <van-field v-model="formData.workname" label="作品名称" placeholder="请输入作品名称(不超过50字)(必填)" />
        <van-field v-model="formData.username" label="参赛人姓名" placeholder="多人参赛请用';'隔开(必填)" />
        <van-field v-model="formData.phone" label="手机" placeholder="请输入参赛手机号(必填)" />
        <van-field v-model="formData.email" label="邮箱" placeholder="输入邮箱用于接收参赛信息(必填)" />
        <van-field v-model="formData.idcard" label="身份证号码" placeholder="请输入身份证号码(必填)" />
        <van-field v-model="formData.instructor" label="指导老师姓名" placeholder="如无指导老师可填写'无'" />
        <van-field v-model="formData.instructorMobile" label="指导老师手机" placeholder="非必填" />
        <van-field v-model="formData.instructorEmail" label="指导老师邮箱" placeholder="非必填" />
        <van-field v-model="formData.otherMobile" label="学生备用电话" placeholder="非必填" />
        <van-field
        readonly
        clickable
        name="picker"
        :value="formData.name"
        label="选择作品证书"
        placeholder="查看作品证书"
        right-icon="arrow-down"
        @click="showPicker = true"
        />

    </van-form>

    <div class="btn-submit actived" @click="confirmSubmit">提交申请</div>

    <van-popup v-model="showPicker" position="bottom">
        <van-picker
            show-toolbar
            :columns="columns"
            value-key="name"
            @confirm="onConfirm"
            @cancel="showPicker = false"
        />
    </van-popup>
  </div>
</template>

<script>
import $http from '@/utils/request'
import config from '@/config'

export default {
    data(){
        return {
            value:null,
            columns: [],
            showPicker: false,
            formData:{},
        }
    },
    created(){
        // 判断有没有作品
        $http({
            method:'get',
            url:config.api.listMySelf,
            params:{
                current:1,
                pageSize:100
            }
        }).then(res=>{
            this.columns = res.result.records;
            if(this.columns && this.columns.length > 0){

            }else{
                this.$dialog.alert({
                    title: '提示',
                    message: '您还未有作品，请去上传作品',
                    confirmButtonText:'去上传'
                }).then(() => {
                    this.$router.push({
                        name:'school-perartist'
                    });
                });
            }
        })
    },
    methods: {
        onConfirm(value,index) {
            // certificate
            this.formData.name = value.name;
            this.formData.workId = value.id;
            this.showPicker = false;
        },
        confirmSubmit(){
            if(!this.formData.workId){
                this.$toast("请选择证书!");
                return;
            }
            $http({
                method:'post',
                url:config.api.nftPersonal,
                data:this.formData
            }).then(res=>{
                this.$toast("提交成功！");
                setTimeout(()=>{
                    this.$router.push({
                        // name:'school-applyart'
                        name:'index'
                    });
                },1000);
            })
        }
    },
}
</script>

<style lang="scss" scoped>
::v-deep .van-cell{
    background-color: #080607;
}
::v-deep .van-field__label{
    color:#fff;
}
::v-deep .van-radio__label{
    color:#fff;
}
::v-deep .van-field__control{
    color:#fff;
}
.page-container{
    padding-bottom: 50px;
    background-color: #080607;
    .banner{
        &>img{
            display: block;
            width:100%;
            margin-bottom: 50px;
        }
    }
    .btn-submit{
        margin:0 auto;
        margin-top: 80px;
        width: 416px;
        height: 60px;
        line-height: 60px;
        text-align: center;
        background: #333333;
        border-radius: 10px;
        font-size: 32px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #999999;
        &.actived{
            background-color: #1296DB;
            color:#fff;
        }
    }
}
</style>
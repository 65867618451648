<template>
    <div class="page-container">
        <div class="noaddress" v-if="collectList.length == 0">
            <img src="@/assets/mine/icon-no-address.png" alt="" class="icon-noaddress">
            <div class="no-txt">你还没有收货 地址哦~</div>
        </div>
        <vue-loadmore 
            :on-loadmore="loadMore"
            :finished="finished"
            v-else
            load-offset="100">
            <div class="address-list">
                <div class="address-item" v-for="item in collectList" :key="item.id">
                    <div class="address-info">
                        <div class="info-top">
                            <span>{{item.name}}</span>
                            <span>{{item.mobile}}</span>
                        </div>
                        <div class="info-bottom">
                            {{`${item.province} ${item.city} ${item.county} ${item.address}`}}
                        </div>
                    </div>
                    <img src="@/assets/mine/icon-edit.png" @click="jumpEdit(item.id)" alt="">
                </div>
            </div>
        </vue-loadmore>
        <div class="btn-login" @click="jumpPage('addressdetail')">新增地址</div>
    </div>
</template>

<script>
import $http from '@/utils/request'
import config from '@/config'

export default {
    data(){
        return {
            current:1,
            pageSize:10,
            total:0,
            collectList:[],
            finished:false,
        }
    },
    created(){
        this.getRecord();
    },
    computed:{
        maxPage(){
            return Math.ceil(this.total / this.pageSize);
        }
    },
    methods:{
        getRecord(){
            $http({
                method:'get',
                url:config.api.addressList,
                params:{
                    current:this.current,
                    pageSize:this.pageSize
                }
            }).then(res=>{
                this.collectList = this.collectList.concat(res.result.records);
                this.total = res.result.total;
            })
        },
        loadMore(done){
            if(this.current < this.maxPage){
                this.current=this.current + 1;
                this.getRecord();
            }else{
                this.finished = true;
            }
            done();
        },
        jumpEdit(id){
            this.$router.push({
                name:'addressdetail',
                params:{
                    id
                }
            });
        },
        jumpPage(name){
            this.$router.push({
                name
            });
        },
    }
}
</script>

<style lang="scss" scoped>
.page-container{
    padding:30px;
    
    .noaddress{
        padding-top: 320px;
        .icon-noaddress{
            width:128px;
            display: block;
            margin:0 auto;
            margin-bottom: 200px;
        }
        .no-txt{
            font-size: 24px;
            font-family: Source Han Sans CN-Regular, Source Han Sans CN;
            font-weight: 400;
            color: #999999;
            margin-bottom: 150px;
            text-align: center;
        }
    }
    .address-list{
        .address-item{
            background: #333333;
            border-radius: 16px 16px 16px 16px;
            margin-bottom: 10px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding:26px;
            font-size: 26px;
            font-family: Source Han Sans CN-Regular, Source Han Sans CN;
            font-weight: 400;
            color: #FFFFFF;
            .address-info{
                width:100%;
                .info-top{
                    margin-bottom: 28px;
                    span{
                        margin-right: 30px;
                    }
                }
                .info-bottom{}
            }
            &>img{
                width:64px;
                margin-left: 10px;
            }
        }
    }
    .btn-login{
        text-align: center;
        margin-top: 200px;
        height: 79px;
        line-height: 79px;
        background: linear-gradient(90deg, #0C81F5 0%, #40A9FF 100%);
        border-radius: 40px 40px 40px 40px;
        font-size: 30px;
        font-family: Source Han Sans CN-Regular, Source Han Sans CN;
        font-weight: 400;
        color: #FFFFFF;
    }
}
</style>
<template>
    <div class="page-container">
        <div class="banner-box">
            <img src="@/assets/school/per-banner.png" alt="" class="banner">
            <div class="btn-submit" v-if="isArt == 1" @click="jumpPage('school-works')">管理我的作品</div>
            <div class="btn-submit" v-else @click="jumpPage('school-applyart')">申请成为艺术家</div> 
        </div>
        <vue-loadmore 
            :on-loadmore="loadMore"
            :finished="finished"
            load-offset="100">
            <div class="artist-item" v-for="(item,index) in artList" :key="item.id" @click="jumpPage('artdetail',item.id,index)">
                <div class="top">
                    <div class="iconname">
                        <van-image
                            class="auth-pic"
                            fit="cover"
                            :src="imgBaseUrl + item.avatar"
                        />
                        <span>{{item.name}}</span>
                    </div>
                    <div class="btn-follow followed" @click.stop="unstar(item.id,3,item)" v-if="item.isStared">
                        <img src="@/assets/icon-followed.png" alt="">
                        <span>已关注</span>
                    </div>
                    <div class="btn-follow" @click.stop="star(item.id,3,item)" v-else>
                        <img src="@/assets/icon-follow.png" alt="">
                        <span>关注</span>
                    </div>
                </div>
                <div class="desc">{{item.description}}</div>
                <van-image
                    class="goods-pic"
                    fit="cover"
                    v-if="item.works[0]"
                    :src="imgBaseUrl + item.works[0].avatar"
                />
            </div>
        </vue-loadmore>
    </div>
</template>

<script>
import $http from '@/utils/request'
import config from '@/config'
import {setItem} from '@/utils/user'

export default {
    data(){
        return {
            finished:false,
            current:1,
            pageSize:10,
            total:0,
            artList:[],
            isArt:null,//1 是艺术家,0 不是艺术家
        }
    },
    created(){
        $http({
            method:'get',
            url:config.api.getArtInfo
        }).then(res=>{
            this.isArt = res.result.isArt;
            this.getData();
        })
    },
    computed:{
        maxPage(){
            return Math.ceil(this.total / this.pageSize);
        }
    },
    methods:{
        //点亮小心心
        star(id,type,item){
            $http({
                method:'get',
                url:config.api.makeStar,
                params:{
                    id,
                    type //type 1 表示藏品的小心心 2 表示藏品组的小心心  3 表示艺术家的关注
                }
            }).then(res=>{
                this.$toast('操作成功');
                item.isStared = true;

            })
        },
        //去掉小心心
        unstar(id,type,item){
            $http({
                method:'get',
                url:config.api.unFollow,
                params:{
                    id,
                    type
                }
            }).then(res=>{
                this.$toast('操作成功');
                item.isStared = false;
                
            })
        },
        jumpPage(name,id,index){
            if(index){
                let target = this.artList[index];
                setItem('targetArt',JSON.stringify(target));
            }
            
            this.$router.push({
                name,
                params:{
                    id
                }
            });
        },
        loadMore(done){
            if(this.current < this.maxPage){
                this.current=this.current + 1;
                this.getData();
            }else{
                this.finished = true;
            }
            
            
            done();
        },
        getData(){
            $http({
                method:'get',
                url:config.api.artList,
                params:{
                    current:this.current,
                    pageSize:this.pageSize,
                    type:2
                }
            }).then(res=>{
                this.artList = this.artList.concat(res.result.records);
                this.total = res.result.total;
            })
        }
    }
}
</script>

<style lang="scss" scoped>
::v-deep .vuejs-refresh-head{
    display: none;
}
.page-container{
    padding:30px;
    .banner-box{
        height:350px;
        border-radius: 15px;
        margin-bottom: 20px;
        overflow: hidden;
        position: relative;
        .banner{
            width:100%;
            height:100%;
        }
        .btn-submit{
            position: absolute;
            bottom:50px;
            left:50%;
            transform: translateX(-50%);
            width: 416px;
            height: 60px;
            line-height: 60px;
            text-align: center;
            border-radius: 10px;
            font-size: 32px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            background-color: #1296DB;
            color:#fff;
        }
    }
    .artist-item{
        margin-bottom: 30px;
        border-radius: 16px;
        background-color: #080607;
        padding:30px;
        .top{
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 30px;
            .iconname{
                display: flex;
                justify-content: flex-start;
                align-items: center;
                .auth-pic{
                    width:128px;
                    height:128px;
                    border-radius: 50%;
                    margin-right: 20px;
                    overflow: hidden;
                    flex-shrink: 0;
                }
                span{
                    font-size: 30px;
                    font-family: Source Han Sans CN-Bold, Source Han Sans CN;
                    font-weight: bold;
                    color: #FFFFFF;
                }
            }
            .btn-follow{
                background: linear-gradient(90deg, #0C81F5 0%, #40A9FF 100%);
                width:152px;
                height:55px;
                border-radius: 28px;
                display: flex;
                justify-content: center;
                align-items: center;
                &.followed{
                    background: #333;
                    color:#999;
                }
                img{
                    width:32px;
                    margin-right: 10px;
                }
                span{
                    font-size: 24px;
                    font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                    font-weight: 400;
                    color: #FFFFFF;
                }
            }
        }
        .desc{
            font-size: 26px;
            font-family: Source Han Sans CN-Regular, Source Han Sans CN;
            font-weight: 400;
            color: #999999;
            line-height: 36px;
            margin-bottom: 30px;
        }
        .goods-pic{
            width:100%;
            height:430px;
        }
        .nameline{
            display: flex;
            justify-content: space-between;
            align-items: center;
            .name{
                width:100%;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                font-size: 26px;
                font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                font-weight: 400;
                color: #FFFFFF;
            }
            .fav-box{
                display: flex;
                justify-content: end;
                align-items: center;
                img{
                    width:48px;
                    margin-right: 10px;
                }
                span{  
                    font-size: 22px;
                    font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                    font-weight: 400;
                    color: #fff;
                }
            }
        }
    }
}
</style>
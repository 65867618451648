<template>
    <div class="page-container">
        <vue-loadmore 
            :on-loadmore="loadMore"
            :finished="finished"
            load-offset="100">
            <div class="follow-list" v-if="collectList.length > 0">
                <div class="follow-item" v-for="(item,index) in collectList" :key="item.id">
                    <div class="picbox">
                        <van-image
                            class="user-pic"
                            fit="cover"
                            :src="imgBaseUrl + item.avatar"
                        />
                        <img src="@/assets/mine/icon-king.png" class="icon-king" alt="">
                    </div>
                    <div class="username">{{item.name}}</div>
                    <div class="dataline">
                        <div>
                            <div>{{item.fansNum}}</div>
                            <div>粉丝</div>
                        </div>
                        <div>
                            <div>{{item.likesNum}}</div>
                            <div>关注</div>
                        </div>
                    </div>
                    <div class="btn-cancel" @click="unFollow(item.id,index)">取消关注</div>
                </div>
            </div>
            <empty v-else />
        </vue-loadmore>
    </div>
</template>

<script>
import $http from '@/utils/request'
import config from '@/config'

export default {
    data(){
        return {
            current:1,
            pageSize:10,
            collectList:[],
            finished:false,
            total:0,
        }
    },
    created(){
        this.getData();
    },
    computed:{
        maxPage(){
            return Math.ceil(this.total / this.pageSize);
        }
    },
    methods:{
        unFollow(id,index){
            $http({
                method:'get',
                url:config.api.unFollow,
                params:{
                    id,
                    type:3
                }
            }).then(res=>{
                this.$toast("操作成功");
                this.collectList.splice(index,1);
            })
        },
        loadMore(done){
            if(this.current < this.maxPage){
                this.current=this.current + 1;
                this.getData();
            }else{
                this.finished = true;
            }
            done();
        },
        getData(){
            $http({
                method:'get',
                url:config.api.myFollow,
                params:{
                    current:this.current,
                    pageSize:this.pageSize
                }
            }).then(res=>{
                this.collectList = this.collectList.concat(res.result.records);
                this.total = res.result.total;
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.page-container{
    padding:30px;
    .follow-list{
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        flex-wrap: wrap;
        .follow-item{
            background-color: #2A2B2C;
            border-radius: 16px;
            padding:0 40px;
            padding-top: 55px;
            padding-bottom: 22px;
            width:330px;
            box-sizing: border-box;
            margin-bottom: 30px;
            .picbox{
                width:128px;
                height:128px;
                margin:0 auto;
                position: relative;
                margin-bottom: 30px;
                .user-pic{
                    width:128px;
                    height:128px;
                    display: block;
                    margin-right: 30px;
                    flex-shrink: 0;
                    border-radius: 50%;
                    overflow: hidden;
                }
                .icon-king{
                    width:48px;
                    position: absolute;
                    bottom:-10px;
                    right:-8px;
                }
            }
            .username{
                font-size: 30px;
                font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                font-weight: 400;
                color: #FFFFFF;
                text-align: center;
                line-height: 36px;
                height:72px;
                overflow: hidden;
                margin-bottom: 45px;
            }
            .dataline{
                display: flex;
                justify-content: space-between;
                align-items: flex-start;
                margin-bottom: 37px;
                &>div{
                    text-align: center;
                    width:120px;
                    font-size: 24px;
                    font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                    font-weight: 400;
                    color: #FFFFFF;
                    &>div{
                        &:first-child{
                            margin-bottom: 17px;
                        }
                    }
                    
                }
            }
            .btn-cancel{
                width: 233px;
                height: 48px;
                line-height: 48px;
                text-align: center;
                background: #666666;
                border-radius: 24px 24px 24px 24px;
                opacity: 1;
                font-size: 24px;
                font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                font-weight: 400;
                color: #333333;
                margin:0 auto;
            }
        }
    }
    
}
</style>